/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from 'react';
import $ from 'jquery';
import Cartqty from '../../components/cartqty';

// eslint-disable-next-line react/prefer-stateless-function
class Header extends Component {
  render() {
    return (
      <div>
        <header>
          <div className="nav-bar">
            {/* <img src="assets/svg/bar.svg" className="img-fluid" alt="" /> */}
          </div>
          <a href="/" className="brand-logo">
            <img src="assets/images/logo.png" className="img-fluid main-logo" alt="" />
            <img src="assets/images/logo-white.png" className="img-fluid white-logo" alt="" />
          </a>
          <div className="header-option">
            <ul>
              <li>
                <a href="/shop"><i className="iconly-Search icli" /></a>
              </li>
              <li>
                <a href="/notification/home"><i className="iconly-Notification icli" /></a>
              </li>
              <li>
                <a href="/wishlist/home"><i className="iconly-Heart icli" /></a>
              </li>
              <Cartqty page={'home'} />
            </ul>
            
          </div>
        </header>

        {/* <div className="header-sidebar">
          <a href="#" className="user-panel">
            <img src="assets/images/user/1.png" className="img-fluid user-img" alt="" />
            <span>Hello, Paige Turner</span>
            <i className="iconly-Arrow-Right-2 icli" />
          </a>
          <div className="sidebar-content">
            <ul className="link-section">
              <li>
                <a href="#">
                  <i className="iconly-Home icli" />
                  <div className="content">
                    <h4>Home</h4>
                    <h6>Offers, Top Deals, Top Brands</h6>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div> */}
      </div>
    );
  }
}

export default Header;
