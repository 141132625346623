/* eslint-disable react/prop-types */
import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import axios from 'axios';
import API from '../../../services';
// import swal from 'sweetalert';

// eslint-disable-next-line react/prefer-stateless-function
export class Subcategory extends Component {
  constructor(props) {
    super(props);
    // eslint-disable-next-line react/no-unused-state
    this.state = { data: [], load: false };
  }

  componentDidMount() {
    // this.token_otentikasi();
    //  console.log(API.url);
  }

  render() {
    // console.log('Subcategory menu : ',this.props.data);
    let comp;
    // eslint-disable-next-line react/destructuring-assignment
    if (this.props.data !== null) {
      comp = this.props.data.map((dynamicData, index) => (
        <Fragment key={index}>
          <li>
            {/* <a href="inner-category.html" className="category-box"> */}
            <Link className="category-box" to={`category/${dynamicData.permalink}`}>
              {dynamicData.name}
            </Link>
            {/* </a> */}
          </li>
        </Fragment>
      ));
    }

    return (
      <>
        <section className="px-15 category-menu" style={{ margin: '5px', padding: '10px' }}>
          <div className="accordion px-15">
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#one">
                  All Category
                </button>
              </h2>
              <div id="one" className="accordion-collapse collapse">
                <div className="accordion-body">
                  <ul>
                    {comp}
                    {/* <li><a href="shop.html">maxi dresses</a></li>
                    <li><a href="shop.html">skirts</a></li> */}
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </section>

      </>
    );
  }
}

export default Subcategory;
