import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import API from '../../services';
import db from '../../services/db';
// import swal from 'sweetalert';
import { useParams } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import style from '../../../public/assets/css/style.css';
import Coupon from './comp/coupon';
import Shipping from './comp/shipping';
import Shippingrj from './comp/shippingrj';
import Orderdetails from './comp/orderdetails';
import Services from './comp/services';
import Bottompanel from './comp/bottompanel';
import Payment from './comp/payment';
import Meta from '../components/meta';

// import ActionType from '../../redux/reducer/globalActionType';

class Cart extends Component {

    constructor(props){
      super(props);
      this.state = { 
        data:[], droppoint:null, load:false, property:null,
        datalength:0, loggedin:true, userid:0,
        voucher:"", bruto:0, discount:0, discountpercent:0, cost:0, costpercentage:0, payment:"",
        shipping:0, netto:0, address:null, coordinate:null, emptycart:false, cash:0, pickup:0, phone:0,
        checkout:false, transid:null, orderplaced:false, orderid:null,error:null, totweight:0, couriername:null
      }
      this.offset = 0;
      this.limit = 100;
      this.outlet;
      this.droppoint = [];
      this.items = new Array();
      this.totweight=0;
      // this.order="desc";
    }

    incrementByOne = (element) => { 

      // return this.droppoint.includes(element);
      console.log(element);
      // if (this.droppoint.includes(element) === false){
      //   this.droppoint.push(element);
      // }
      // return this.droppoint;
    }
  

    componentDidMount(){
      this.token_otentikasi();
    }

    token_otentikasi = async() => { 
      const stts = await API.otentikasi();
      if (stts === false){ this.setState({ loggedin:false }) }
      else if(stts === true) {
        const decode = await API.decode();
        const property = await API.property();
        // console.log("Hasil property : ",property.shipping_integration);
        let phone;
        if (localStorage.getItem('phone') !== null){
          phone = localStorage.getItem('phone');
        }else{ phone = decode.phone; }
        this.setState({ userid:decode.userid,phone:phone,property:property},() => { this.load_data_trans(); this.set_voucher(); });
      }
   }

   set_voucher = async() =>{
    if (localStorage.getItem('voucher') !== null){
      axios.get(API.url+'vdiscount/get/'+localStorage.getItem('voucher'),{ headers: {'X-Auth-Token': localStorage.getItem('token')}})
      .then((response) => { 
        let voucher = response.data.content;
        let percentage = parseFloat(voucher.percentage)/100;
        // this.setState({ voucher: voucher.code,load:false, discount:percentage*this.state.bruto });
        this.setState({voucher: voucher.code,load:false, discountpercent:percentage},() => {

          this.calculate_total();
      });
            
      })
      .catch((err) => { 
        // console.log("Error wishlist : ", err.response.statusText+' - '+err.response.status);
        //  API.otentikasi_acl('product'); API.post_error_db(this.constructor.name.toLowerCase(),err.response.statusText,err.response.status);
       }); 
    }
 }

  set_cost = (percentage,cost,cash,uid) =>{
    // console.log('Percentage : ',percentage);
    // console.log('Cash : ',cash);
    this.setState({costpercentage:parseFloat(percentage/100),cost:cost, cash:cash, payment:uid},() => {
      this.calculate_total();
    });
  }

  set_pickup = (val) =>{
    if (val === 1){
      this.setState({pickup:1,shipping:0},() => {
        this.calculate_total();
      });
    }else{
      this.setState({pickup:0},() => {
        this.load_data_trans();
      });
    }
  }

   calculate_total = () => {
     let discount = parseInt(this.state.discountpercent*this.state.bruto);
     let netto = parseInt(this.state.bruto-discount+this.state.shipping);
     let cost = parseInt(netto*this.state.costpercentage);
     cost = parseInt(cost+this.state.cost);
    //  console.log("Cost : ",cost);
     this.setState({netto:parseInt(netto+cost),discount:discount,cost:cost});
   }

   load_data_trans = async() => {
    this.setState({datatrans:[],error:null,totweight:0},() => {
      this.load_trans();
   });
   }


   load_trans = async() => {

      // alert('Load data trans');
      // this.setState({datatrans:[],error:null,totweight:0});
      let total = 0; let totalqty = 0; this.totweight=0;
      let result = await db.posts.where('user').equalsIgnoreCase(this.state.userid).toArray();
      if (result.length === 0){ this.setState({ emptycart: true });
      }else{ this.setState({ data: result }); }
      
      let droppoint=[];
      result.map((dynamicData,index) => {

        // totweight
        this.totweight = parseInt(this.totweight+dynamicData.weighttot);

        total = parseInt(total+dynamicData.total);
        if (droppoint.includes(dynamicData.outletid) === false){
          droppoint.push(dynamicData.outletid);
        } 
      })
      this.setState({droppoint:droppoint.toString(),bruto: total, totweight:this.totweight},() => {
         this.calculate_shipping();
      });
    };

    set_shipping_cost = async(service,value) =>{
      if (service === null && value === 0){
        this.setState({shipping:value,couriername: service},() => {
          this.calculate_shipping();
        });
      }else{
        this.setState({shipping:value,couriername: service},() => {
          this.calculate_total();
        });
      }
    }

    // calculate shipping borzo
    calculate_shipping = async() =>{
      let nilai = {coordinate: this.state.coordinate, droppoint:this.state.droppoint, amount:this.state.bruto, address:this.state.address};
      // console.log("Nilai calculate : ",nilai);
      axios.post(API.url+'pos/calculate_delivery',nilai,{ headers: {'X-Auth-Token': localStorage.getItem('token')}})
        .then((response) => {
            // console.log('Hasil calculate shipping : ',response.data.content.shipping);
            // console.log('Hasil calculate distance : ',response.data.content.distance);
            // console.log('Hasil droppoint distance : ',response.data.content.droppoint_distance);
            // console.log('Hasil customer distance : ',response.data.content.cust_distance);
            this.setState({shipping:response.data.content.shipping},() => {
                this.calculate_total();
            });
        })
        .catch((error) => { 
          console.log("Error calculate shipping : ",error.response.data.error);
          // console.log("Error response status : ",error.response.status);
          this.setState({error:error.response.data.error},() => {
              if (error.response.status === 403){
                toast.error(error.response.data.error,
                {position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 2000,
                hideProgressBar: false});
                this.setState({ netto:0 });
              }else{
                location.reload();
              }
          });

        }); 
    }

    remove = async(event) =>{
      // console.log("Event ID : ",event);
      db.posts.delete(parseInt(event)).then(async()=>{ this.load_data_trans();})
    }

    wishlist= async(event,id)=>{
      this.setState({ load:true });
        axios.get(API.url+'product/wishlist/'+event,{ headers: {'X-Auth-Token': localStorage.getItem('token')}})
        .then((response) => { 
            // this.setState({ data: response.data.content.result,load:false });
            // console.log(response.data.content);
            this.remove(id);
            toast.success("Item berhasil terdaftar di wishlist",
              {position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 2000,
              hideProgressBar: false});
              
        })
        .catch((err) => { 
          let mess = null;
          if (err.response.status === 401){mess = 'Silahkan login untuk wishlist menu'}
          toast.error(mess,
           {position: toast.POSITION.BOTTOM_CENTER,
           autoClose: 2000,
           hideProgressBar: false});
          
          // console.log("Error wishlist : ", err.response.statusText+' - '+err.response.status);
          //  API.otentikasi_acl('product'); API.post_error_db(this.constructor.name.toLowerCase(),err.response.statusText,err.response.status);
         }); 
    }

    add_qty = async(id,qty) => { 
      localStorage.removeItem('voucher');
      this.setState({discountpercent:0,voucher:""});
      let result = await db.posts.where('id').equals(id).toArray();
      // console.log('Hasil result : ',result);
      let total= parseInt(result[0].price)*parseInt(qty+1);
      let weihgttotal= parseInt(result[0].weight)*parseInt(qty+1);
      // let postdata = {qty: qty, tax:0.1*parseInt(total), taxval:0.1, total:total}
      let postdata = {id:result[0].id, outletid: result[0].outletid, outlet: result[0].outlet, sku: result[0].sku, pid: result[0].pid, image:result[0].image, name: result[0].name.toUpperCase(), qty: parseInt(qty+1), user:result[0].user, price:result[0].price, discount:0, tax:0.1*parseInt(total), taxval:0.1, total:total, attributes:result[0].attributes, weighttot:weihgttotal, weight:result[0].weight}
      // console.log(postdata);
      db.posts.put(postdata).then(async()=>{ 
        /*API.SwalTimer('item succesfull updated',data[0].sku,'success',900); */ 
        // toast.success('Item '+result[0].sku+' berhasil diupdate ke keranjang',
        // {position: toast.POSITION.BOTTOM_CENTER,
        // autoClose: 2000,
        // hideProgressBar: false});
        this.load_data_trans();
      })
              
    }

    min_qty = async(id,qty) => { 
      localStorage.removeItem('voucher');
      this.setState({discountpercent:0,voucher:""});
      let result = await db.posts.where('id').equals(id).toArray();
      if (qty > 1){
        // console.log('Hasil result : ',result);
        let total= parseInt(result[0].price)*parseInt(qty-1);
        let weihgttotal= parseInt(result[0].weight)*parseInt(qty-1);
        // let postdata = {qty: qty, tax:0.1*parseInt(total), taxval:0.1, total:total}
        let postdata = {id:result[0].id, outletid: result[0].outletid, outlet: result[0].outlet, sku: result[0].sku, pid: result[0].pid, image:result[0].image, name: result[0].name.toUpperCase(), qty: parseInt(qty-1), user:result[0].user, price:result[0].price, discount:0, tax:0.1*parseInt(total), taxval:0.1, total:total, attributes:result[0].attributes,weighttot:weihgttotal, weight:result[0].weight}
        // console.log(postdata);
                db.posts.put(postdata).then(async()=>{ 
                  
                  /*API.SwalTimer('item succesfull updated',data[0].sku,'success',900); */ 
                  // toast.success('Item '+result[0].sku+' berhasil diupdate ke keranjang',
                  // {position: toast.POSITION.BOTTOM_CENTER,
                  // autoClose: 2000,
                  // hideProgressBar: false});
                });
      }else{
        db.posts.delete(result[0].id).then(async()=>{ })
      }
      this.load_data_trans();
    }

    set_add = async(add,coordinate) => { 
      this.setState({ coordinate:coordinate, address:add });
      // console.log('Hasil dari set add : ',add);
      // console.log('Hasil dari set add coor : ',coordinate);
    }

    remove_voucher = async(add,coordinate) => { 
      localStorage.removeItem('voucher');
      this.setState({voucher: "",discountpercent:0},() => {
        this.load_data_trans();
     });
    }

    checkout = async() => { 
      this.setState({ load:true});
      let result = await db.posts.where('user').equalsIgnoreCase(this.state.userid).toArray();
      let valueToPush = new Array();
      let objects={};
      
      result.map((dynamicData,index) => {
        objects[index] = {
          sku:dynamicData.sku,
          name:dynamicData.name,
          qty:dynamicData.qty,
          price:dynamicData.price,
          discount:dynamicData.discount,
          tax:0,
        };
      })

      if (this.state.error === null){
          let nilai = {voucher: this.state.voucher, droppoint:this.state.droppoint, payment:this.state.payment, ship_address:this.state.address, ship_phone:this.state.phone, pickup:this.state.pickup, cash:this.state.cash, xpedition:this.state.couriername, shipping_amount:this.state.shipping, total_weight:this.state.totweight, items:objects};
          // console.log('Nilai Checkout : ',nilai);
          axios.post(API.url+'pos/add_multiple',nilai,{ headers: {'X-Auth-Token': localStorage.getItem('token')}})
          .then((response) => {
            let deletes = db.posts.where('user').equalsIgnoreCase(this.state.userid).delete();
            // console.log("Respon Content : ",response.data.content);

              if (this.state.cash === '0'){
                // window.location.href = response.data.content.toString();
                this.setState({checkout:true,transid:response.data.content.transid});
                // console.log(response.data.content);
              }else if(this.state.cash === '1'){
                // console.log('Hasil add post : ',response.data.content);
                this.setState({orderplaced:true,orderid:response.data.content.orderid});
              }
          })
          .catch((error) => {
            // console.log(error.response.statusText);
            this.setState({ load:false});
            toast.error(error.response.data.error,
              {position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 2000,
              hideProgressBar: false});
          }); 
      }else{
          this.setState({ load:false});
          toast.error(this.state.error,
            {position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000,
            hideProgressBar: false});
      }

    }


  render() {
    // console.log("Hasil Droppoint : ",this.state.droppoint);
    // console.log("State address : ",this.state.address);
    // console.log("State coordinate : ",this.state.coordinate);

    // console.log("State bruto : ",this.state.bruto);
    // console.log("State discount : ",this.state.discount);
    // console.log("State shipping : ",this.state.shipping);
    // console.log("State couriername : ",this.state.couriername);
    // console.log("State netto : ",this.state.netto);


    // console.log("State payment : ",this.state.payment);
    // console.log('State Pickup : ',this.state.pickup);

    // console.log('State Phone : ',this.state.phone);
    // console.log('Orderid : ',this.state.orderid);

    // console.log('Property : ',this.state.property);

    // console.log('Totweight render : ',this.state.totweight);


    let comp; let ship;

    if (this.state.property !== null){
      if (this.state.property.shipping_integration === '1' && this.state.droppoint !== null && this.state.totweight !== 0){
        ship = <Shippingrj droppointdata={this.state.droppoint} userid={this.state.userid} totweight={this.state.totweight} setshippingcost={this.set_shipping_cost} />;
        // console.log('shipping 0');
      }
    }

    if (this.state.loggedin === false){ return <Redirect to='/login'></Redirect>}
    if (this.state.emptycart === true){ return <Redirect to='/emptycart'></Redirect>}
    if (this.state.load === false){ comp =  <Bottompanel checkout={this.checkout} total={this.state.netto}/>;  }
    if (this.state.checkout === true){ return <Redirect to={`/checkout/${this.state.transid}`}></Redirect>}
    if (this.state.orderplaced === true){ return <Redirect to={`/orderplaced/${this.state.orderid}`}></Redirect>}
    
    let back;
    if (this.props.match.params.page === 'home'){back = '/';}else{ back = `/${this.props.match.params.page}`; }

    return (
      <>
       <Meta/>
      {/* header start */}
      <header>
          <div className="back-links">
            <a href={back}>
              <i className="iconly-Arrow-Left icli" />
              <div className="content">
                <h2>Shopping Cart</h2>
                <h6>Step 1 of 3</h6>
              </div>
            </a>
          </div>
          <div className="header-option">
            <ul>
              <li>
                <a href="/wishlist/cart/shop"><i className="iconly-Heart icli" /></a>
              </li>
            </ul>
          </div>
        </header>
        {/* header end */}

{/* cart items start */}
<section className="cart-section pt-0 top-space">

{
        this.state.data.map((dynamicData, index) => (
          <Fragment key={index}>

          <div className="divider" />
        <div className="cart-box px-15">
          <a href="product.html" className="cart-img">
            <img src={dynamicData.image} className="img-fluid" alt="" />
          </a>
          <div className="cart-content">
            <a href="product.html">
              <h4>{API.capitalize(dynamicData.name)}</h4>
            </a>
            <h5 className="content-color">{dynamicData.attributes}</h5>
            <div className="price">
              <h4>{API.formatNumber(dynamicData.total)}</h4>
              <h4> {dynamicData.outlet} </h4>
            </div>
            <div className="select-size-sec">
              <a href="#" data-bs-toggle="offcanvas" data-bs-target="#selectQty" className="opion">
                <h4>Qty: </h4>
                <div className="qty-counter">
                <div className="input-group">
                  <button type="button" className="btn quantity-left-minus" data-type="minus" data-field onClick={() => this.min_qty(dynamicData.id,dynamicData.qty)}>
                    <img src="/assets/svg/minus-square.svg" className="img-fluid" alt="" />
                  </button>
                  <input type="text" name="quantity" className="form-control form-theme qty-input input-number" value={dynamicData.qty} />
                  <button type="button" className="btn quantity-right-plus" data-type="plus" data-field onClick={() => this.add_qty(dynamicData.id,dynamicData.qty)}>
                    <img src="/assets/svg/plus-square.svg" className="img-fluid" alt="" />
                  </button>
                </div>
              </div>
              </a>
              {/* <a href="#" data-bs-toggle="offcanvas" data-bs-target="#selectSize" className="opion">
                <h6>Size: S</h6><i className="iconly-Arrow-Down-2 icli" />
              </a> */}
            </div>
            <div className="cart-option">
              <h5 onClick={() => this.wishlist(dynamicData.pid,dynamicData.id)}><i className="iconly-Heart icli" /> Move
                to
                wishlist</h5>
              <span className="divider-cls">|</span>
              <h5 onClick={() => this.remove(dynamicData.id)}><i className="iconly-Delete icli" />
                Remove
              </h5>
            </div>
          </div>
        </div>
        {/* <div className="divider" /> */}

          </Fragment>
        ))

}
      </section>
      {/* cart items end */}

        {/* shipping section */}
        <div className="divider"></div>
          <Shipping setadd={this.set_add} setpickup={this.set_pickup} />
          {ship}
        {/* shipping end */}

        {/* coupon section */}
        <Coupon data={this.state.voucher} removevoucher={this.remove_voucher}/>
        {/* coupon end */}

        {/* coupon section */}
         <Payment setcost={this.set_cost} />
        {/* coupon end */}


        {/* order details start */}
        <Orderdetails 
           bruto={this.state.bruto} 
           discount={this.state.discount} 
           cost={this.state.cost} 
           netto={this.state.netto} 
           shipping={this.state.shipping}
           address={this.state.address}
           coordinate={this.state.coordinate}
        />
        {/* order details end */}

        {/* service section start */}
        <Services/>
        {/* service section end */}

        {/* panel space start */}
        <section className="panel-space" />
        {/* panel space end */}

        {/* bottom panel start */}
        {/* <Bottompanel checkout={this.checkout} total={this.state.netto}/> */}
        {comp}
        {/* bottom panel end */}

      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Cart;
