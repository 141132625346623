import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
// import swal from 'sweetalert';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import API from '../../../services';
import Rating from '../../components/rating';

class Prodesc extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    // console.log("Isi data props : ",this.props.dataprops);
    return (
      <>
        <div className="product-detail-box px-15 pt-2">
          <div className="main-detail">
            <h2 className="text-capitalize">{API.capitalize(this.props.dataprops.name)}</h2>
            <h6 className="content-color">
              {this.props.dataprops.shortdesc}
            </h6>
            <div className="rating-section">
            <Rating data={this.props.dataprops.rating} />
              {/* <ul className="ratings">
                <li><i className="iconly-Star icbo" /></li>
                <li><i className="iconly-Star icbo" /></li>
                <li><i className="iconly-Star icbo" /></li>
                <li><i className="iconly-Star icbo" /></li>
                <li><i className="iconly-Star icbo empty" /></li>
              </ul> */}
              {/* <h6 className="content-color">(20 ratings)</h6> */}
            </div>
            <div className="price">
              <h4>
                {API.formatNumber(this.props.price)}
                <span className="separator"> | </span>
                <span>{this.props.dataprops.period}</span>
              </h4>
            </div>
            <h6 className="text-green">inclusive of all taxes</h6>
          </div>
        </div>
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Prodesc;
