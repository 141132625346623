import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import API from '../../services';
import db from '../../services/db';
// import swal from 'sweetalert';
import { useParams } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import successbg from '../../assets/images/check.png';
import failedbg from '../../assets/images/failed.png';
import Shipping from './comp/shipping';
// import style from '../../../public/assets/css/style.css';
// import ActionType from '../../redux/reducer/globalActionType';
import Meta from '../components/meta';

class Orderplaced extends Component {

    constructor(props){
      super(props);
      this.state = { data:null, item:[], load:false, loggedin:true, page404:false, redeem:0, payment:null,shipping:null,integration:'0' }
      this.offset = 0;
      this.limit = 100;
      this.outlet;
      // this.order="desc";

    }

    componentDidMount(){
      this.token_otentikasi();
    }

    product_details = async(pid) => { 
      const product = await API.product(73);
      return product;
    }

    token_otentikasi = async() => { 
      const stts = await API.otentikasi();
      if (stts === false){ this.setState({ loggedin:false }) }
      else if(stts === true) {
        // const decode = await API.decode();
        const property = await API.property();
        // console.log(property);
        this.setState({
          integration:property.shipping_integration
        },() => {
          this.load_trans();
        });
        // console.log(await API.product_details(73,'name'));
      }
    }

    load_trans = async() => {
      // console.log(this.props.match.params.orderid);
      axios.get(API.url+'pos/get_trans/'+this.props.match.params.orderid,{ headers: {'X-Auth-Token': localStorage.getItem('token')}})
      .then((response) => {
          // console.log(response.data.content); 
          let result = response.data.content.details;
          let items = response.data.content.items;
          let shipping = response.data.content.shipping;
          this.setState({
            redeem:result.redeem,data:result,item:items,shipping:shipping
          },() => {
              this.get_payment(result.payment_type);
          });
          
      })
      .catch((error) => { 
        // this.setState({ page404:true })
        // console.log("Error : ", error.response.data.error);
      }); 
        // axios
    }

    get_payment = async(val) => {
      axios.get(API.url+'payment/get/'+val,{ headers: {'X-Auth-Token': localStorage.getItem('token')}})
      .then((response) => {
          // console.log("Payment : ",response.data.content); 
          this.setState({ payment:API.capitalize(response.data.content.name) });
          // let result = response.data.content;
          
      })
      .catch((error) => { 
        // this.setState({ page404:true })
        // console.log("Error : ", error.response.data.error);
      }); 
    }


  render() {
    let title; let titledesc; let titlenote; let logo; let shipstts; let address; let phone;
    let total=0; let discount=0; let shipping=0; let cost=0; let amount=0;

    let orderdetail; let track; let shipcomp;
    if (this.state.data !== null){
      address = this.state.data.receiver_address;
      phone = this.state.data.receiver_phone;
      total = this.state.data.total;
      discount = this.state.data.discount;
      shipping = this.state.data.shipping;
      cost = this.state.data.cost;
      amount = this.state.data.amount;
    }
    // console.log("Hasil Data Integration : ",this.state.integration);

    // if (this.state.integration === '0'){ shipcomp = <Shipping orderid={this.props.match.params.orderid} dataprops={this.state.shipping}/>; }
    // else{ shipcomp=<Shippingrj orderid={this.props.match.params.orderid} dataprops={this.state.shipping}/>; }
    
    if (this.state.loggedin === false){ return <Redirect to='/login'></Redirect>}
    if (this.state.page404 === true){ return <Redirect to='/'></Redirect>}
    if (this.state.redeem === '0'){
       title = "Pesanan Gagal!"; 
       logo = failedbg;
    }else if(this.state.redeem === '1'){
       title = "Pesanan Berhasil!"; 
       titledesc="Pembayaran berhasil diproses dan Pesanan Anda sedang dalam proses."; 
       titlenote="Tanda terima email termasuk detail tentang pesanan Anda telah dikirim ke ID email Anda.";
       logo = successbg; 

       orderdetail = <section className="px-15">
                    <h2 className="page-title"> Detail Pesanan : </h2>
                    <div className="details">
                      <ul>
                        <li className="mb-3 d-block">
                          <h4 className="fw-bold mb-1"> No Pesanan adalah #: {this.props.match.params.orderid}</h4>
                          <h4 className="content-color"> {titlenote} </h4>
                        </li>
                        <li className="mb-3 d-block">
                          <h4 className="fw-bold mb-1"> Pesanan ini akan dikirim ke: </h4>
                          <h4 className="content-color"> {address} </h4> 
                          <h4 className="content-color"> {phone} </h4>
                        </li>
                        <li className="d-block">
                          <h4 className="fw-bold mb-1"> Jenis Pembayaran </h4>
                          <h4 className="content-color"> {this.state.payment} </h4> <br/>
                        </li>
                        {/* {shipcomp} */}
                        <Shipping orderid={this.props.match.params.orderid} dataprops={this.state.shipping} integration={this.state.integration}/>
                        {/* <li className="d-block">
                          <h4 className="fw-bold mb-1"> Status Pengiriman </h4>
                          <h4 className="content-color"> {this.state.payment} </h4>
                        </li> */}
                      </ul>
                    </div>
                  </section>;
        // track = <a href="order-tracking.html" className="title-color">Lacak Pesanan</a>;
    }

    return (
      <>
      <Meta/>
        {/* header start */}
        <header>
          <div className="back-links">
            <a href="/orderhistory">
              <i className="iconly-Arrow-Left icli" />
              <div className="content">
                <h2> History Transaksi</h2>
              </div>
            </a>
          </div>
        </header>
        {/* header end */}

        {/* order success section start */}
        <section className="order-success-section px-15 top-space">
          <div>
            <div className="">
              <img src={logo} className="img-fluid" alt="" />
            </div>
            <h1>{title}</h1>
            <h2>{titledesc}</h2>
            {/* <h2>Payment is successfully processed and your Order is on the way.</h2> */}
          </div>
        </section>
        {/* order success section end */}

        {/* order details section start */}
        {orderdetail}
        {/* <section className="px-15">
          <h2 className="page-title"> Detail Pesanan : </h2>
          <div className="details">
            <ul>
              <li className="mb-3 d-block">
                <h4 className="fw-bold mb-1"> No Pesanan adalah #: {this.props.match.params.orderid}</h4>
                <h4 className="content-color"> {titlenote} </h4>
              </li>
              <li className="mb-3 d-block">
                <h4 className="fw-bold mb-1"> Pesanan ini akan dikirim ke: </h4>
                <h4 className="content-color"> {address} </h4> 
                <h4 className="content-color"> {phone} </h4>
              </li>
              <li className="d-block">
                <h4 className="fw-bold mb-1"> Jenis Pembayaran </h4>
                <h4 className="content-color"> {this.state.payment} </h4>
              </li>
            </ul>
          </div>
        </section>
        <div className="divider" /> */}
        <div className="divider" />
        {/* order details section end */}

        {/* expected delivery section start */}
        <section className="px-15 pt-0">
          <h2 className="page-title"> Ringkasan Pesanan </h2>
          <div className="product-section">
            <div className="row gy-3">

{
  this.state.item.map((dynamicData, index) => (
        <Fragment key={index}>
        <div className="col-12">
                <div className="product-inline">
                  <a href="product.html">
                    <img src={dynamicData.image} alt="" className="img-fluid bg-img" />
                  </a>
                  <div className="product-inline-content">
                    <div>
                      <a>
                        <h5>{dynamicData.sku}</h5>
                        <h4>{API.capitalize(dynamicData.name)}</h4>
                      </a>
                      <h5 className="content-color">  Qty: {dynamicData.qty},{dynamicData.attribute}</h5>
                      <div className="price">
                        <h4>  {API.formatNumber(dynamicData.price)} </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
        </Fragment>
      ))
}

            </div>
          </div>
        </section>
        {/* expected delivery section end */}
        {/* order details start */}
        <section className="px-15">
          <div className="order-details">
            <ul>
              <li>
                <h4> Total Keranjang <span>{API.formatNumber(total)}</span></h4>
              </li>
              <li>
                <h4> Discount <span className="text-green">-{API.formatNumber(discount)}</span></h4>
              </li>
              <li>
                <h4> Pengiriman <span>{API.formatNumber(shipping)}</span></h4>
              </li>
              <li>
                <h4> Biaya Layanan <span>{API.formatNumber(cost)}</span></h4>
              </li>
            </ul>
            <div className="total-amount">
              <h4>Total <span>{API.formatNumber(amount)}</span></h4>
            </div>
          </div>
        </section>
        {/* order details end */}
        {/* panel space start */}
        <section className="panel-space" />
        {/* panel space end */}
        {/* bottom panel start */}
        <div className="delivery-cart cart-bottom">
          <div>
            <div className="left-content">
              {track}
            </div>
            <a href="/shop" className="btn btn-solid"> Lanjut Belanja </a>
          </div>
        </div>
        {/* bottom panel end */}

      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Orderplaced;
