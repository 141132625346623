import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import axios from 'axios';
import API from '../../services';
import db from '../../services/db';
import Productheader from './comp/productheader';
import Slider from './comp/slider';
import Prodesc from './comp/prodesc';
import Proattribute from './comp/proattribute';
import Provoucher from './comp/provoucher';
import Prodetails from './comp/prodetails';
import Proreview from './comp/proreview';
import Prooutlet from './comp/prooutlet';
// import Procekdelivery from './comp/procekdelivery';
import Relatedproduct from './comp/relatedproduct';
import Footer from './comp/footer';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Meta from '../components/meta';
// import Category from './comp/category';
// import style from '../../../public/assets/css/style.css';

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [], comments: [], outletid:null, outlet:null, load: false, images:[], qty:1, price:0, loggedin:true, userid:0, attributes:""
    };
    this.images=[];
    this.limit = 8;
    this.offset = 0;
  }

  componentDidMount() {
    // this.load_product();
    // console.log(this.props.match.params.sku);
    // this.props.history.goBack();
     localStorage.removeItem('voucher');
     this.token_otentikasi();
     this.load_product();
  }

  token_otentikasi = async() => { 
    const stts = await API.otentikasi();
    if (stts === false){ this.setState({ loggedin:false }) }
    else if(stts === true) {
      const decode = await API.decode();
      // console.log("Hasil decode : ",decode);
      this.setState({ userid: decode.userid}); 
    }
 }

  add_qty = async() => {
    this.setState({ qty:this.state.qty+1 });
  }

  min_qty = async() => {
    if (this.state.qty > 1 ){
      this.setState({ qty:this.state.qty-1 });
    }
  }

  set_outlet = async(e,name) => {
      this.setState({ outletid:e,outlet:name });
      // console.log(name);
  }

  load_comments = async(pid) => {
    this.setState({ load:false });
    let nilai = {product_id: pid, cust_id: "", limit:this.limit, offset:this.offset};
    axios.post(API.url+'procomment',nilai)
      .then((response) => {
          // console.log('Hasil procomment : ',response.data.content.result);
          this.setState({ comments: response.data.content.result, load:true });
      })
      .catch((error) => { 
          // error.response.status
          // API.post_error_db(this.constructor.name.toLowerCase(),error.response.data.error,error.response.status);
          // swal(error.response.data.error, "", "error");
          // console.log(error);
      }); 
  }


   load_product = async() => {
    this.setState({ load:false });
    // console.log(nilai);
    axios.get(API.url+'product/get/'+this.props.match.params.sku+'/1',{ headers: {'X-Auth-Token': localStorage.getItem('token')}})
      .then((response) => {
          this.images.push(response.data.content.url1);
          this.images.push(response.data.content.url2);
          this.images.push(response.data.content.url3);
          this.images.push(response.data.content.url4);
          this.images.push(response.data.content.url5);
          this.setState({ data: response.data.content, images:this.images, price:response.data.content.price, load:true });
          this.load_comments(response.data.content.id);
      })
      .catch((error) => { 
          // error.response.status
          // API.post_error_db(this.constructor.name.toLowerCase(),error.response.data.error,error.response.status);
          // swal(error.response.data.error, "", "error");
          // console.log(error);
      }); 
  }

   goBack = () => { 
     this.props.history.goBack();
   }

   set_price = (price) => { 
     this.setState({ price:price });
   }

   set_attr = (attr) => { 
    this.setState({ attributes:attr });
    // console.log('Attr : ',attr);
   }

   wishlist = async event =>{
       this.setState({ load:true });
        axios.get(API.url+'product/wishlist/'+event,{ headers: {'X-Auth-Token': localStorage.getItem('token')}})
        .then((response) => { 
            // this.setState({ data: response.data.content.result,load:false });
            // console.log(response.data.content);
            this.load_product();
        })
        .catch((err) => { 
          let mess = null;
          if (err.response.status === 401){mess = 'Silahkan login untuk wishlist menu'}
          toast.error(mess,
           {position: toast.POSITION.BOTTOM_CENTER,
           autoClose: 2000,
           hideProgressBar: false});
          
          // console.log("Error wishlist : ", err.response.statusText+' - '+err.response.status);
          //  API.otentikasi_acl('product'); API.post_error_db(this.constructor.name.toLowerCase(),err.response.statusText,err.response.status);
         }); 
    }

    add_cart = async event =>{
      if (this.state.loggedin == true){

        this.setState({ load:true });
        axios.get(API.url+'product/cek_restricted/'+event,{ headers: {'X-Auth-Token': localStorage.getItem('token')}})
        .then((response) => { 
            // this.setState({ data: response.data.content.result,load:false });
            // console.log("Hasil response : ",response.data.content);
            this.post_data();
        })
        .catch((error) => { 
          toast.error("Item tidak tersedia untuk saat ini",
            {position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000,
            hideProgressBar: false});
          }); 

      }else{
        toast.error('Silahkan login untuk menambahkan ke keranjang',
          {position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 2000,
          hideProgressBar: false});
      }
   }

   cek_sku = async(sku) =>{
    // let result = await db.posts.where('sku').equals(sku).and(value => value.user === this.state.userid).toArray();
    let result = await db.posts.where('sku').equals(this.state.data.sku)
                         .and(value => value.attributes === this.state.attributes)
                         .and(value => value.user === this.state.userid).toArray();
    if (result.length === 0){ return true; }else{ return false; }
   }

   post_data = async() => {
    // console.log(data[0].id)
    // let total = 0, tax = 0;

    let valid = await this.cek_sku(this.state.data.sku);

    if (this.state.price !== 0 && this.state.outletid !== null){
        
        // add data to database
        if (valid === true){
            let total = parseInt(this.state.price)*parseInt(this.state.qty);
            let totalweight = parseInt(this.state.data.weight)*parseInt(this.state.qty);
            let postdata = {sku: this.state.data.sku, outletid: this.state.outletid, outlet: this.state.outlet, pid: this.state.data.id, image:this.state.data.image, name: this.state.data.name.toUpperCase(), qty: this.state.qty, user:this.state.userid, price:this.state.price, discount:0, tax:0.1*parseInt(total), taxval:0.1, total:total, attributes:this.state.attributes, weight:this.state.data.weight, weighttot:totalweight}
            db.posts.add(postdata).then(async()=>{
                // API.SwalTimer('item succesfull added',data[0].sku,'success',900);
                toast.success('Item berhasil ditambah ke keranjang',
                {position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 2000,
                hideProgressBar: false});
            })
            // console.log("Post data : ",postdata);
        }else{
            let qty=0; let totweight=0;
            // let result = await db.posts.where('sku').equals(this.state.data.sku).and(value => value.user === this.state.userid, value => value.attributes === this.state.attributes).toArray();
            let result = await db.posts.where('sku').equals(this.state.data.sku)
                         .and(value => value.attributes === this.state.attributes)
                         .and(value => value.user === this.state.userid).toArray();

            qty = parseInt(this.state.qty)+parseInt(result[0].qty);
            totweight = parseInt(qty)*parseInt(this.state.data.weight);

            // alert(qty);

              let total = parseInt(this.state.price)*parseInt(qty);
              let postdata = {id:result[0].id, sku: this.state.data.sku, outletid: this.state.outletid, outlet: this.state.outlet, pid: this.state.data.id, image:this.state.data.image, name: this.state.data.name.toUpperCase(), qty: qty, user:this.state.userid, price:this.state.price, discount:0, tax:0.1*parseInt(total), taxval:0.1, total:total, weight:weight, weighttot:totweight, attributes:this.state.attributes}
              db.posts.put(postdata).then(async()=>{ 
                /*API.SwalTimer('item succesfull updated',data[0].sku,'success',900); */ 
                toast.success('Item '+this.state.data.sku+' berhasil diupdate ke keranjang',
                {position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 2000,
                hideProgressBar: false});
              })
        }

        this.setState({ price:this.state.data.price, attributes:"", qty:1 });
    }
    else if(this.state.price === 0){ 
      // API.SwalTimer('Invalid Total',data[0].sku,'error',900);
      toast.error('Invalid Total',this.state.data.sku,
      {position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 2000,
      hideProgressBar: false});
    }
    else if(this.state.outletid === null){ 
      // API.SwalTimer('Invalid Total',data[0].sku,'error',900);
      toast.error('Silahkan Pilih Outlet',this.state.data.sku,
      {position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 2000,
      hideProgressBar: false});
    } 
}

  render() {
    // console.log("Isi data state : ",this.state.data.weight);
    return (
      <>
       <Meta/>
        {/* <button onClick={() => this.goBack()}> Back </button> */}
        <Productheader title={this.state.data.name} link={this.goBack} />
        <section className="product-page-section top-space pt-0 border">
          <Slider data={this.state.images} />
          <Prodesc dataprops={this.state.data} price={this.state.price} />
          <Proattribute 
              dataprops={this.state.data}
              qty={this.state.qty}
              addqty={this.add_qty}
              minqty={this.min_qty}
              price={this.state.price}
              setprice={this.set_price}
              setattr={this.set_attr}
          />

          <Prooutlet 
            dataprops={this.state.data} 
            setoutlet={this.set_outlet} 
          />

          {/* <Provoucher /> */}
          <Prodetails dataprops={this.state.data}/>
          {/* <Procekdelivery /> */}
          <Proreview dataprops={this.state.comments} />
        </section>
        <Relatedproduct />
        {/* panel space start */}
        <section className="panel-space" />
        {/* panel space end */}
        <Footer dataprops={this.state.data} action={this.wishlist} actioncart={this.add_cart} />

      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Product;
