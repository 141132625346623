import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import API from '../../services';
// import swal from 'sweetalert';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Meta from '../components/meta';
import OneSignal from 'react-onesignal';

// import ActionType from '../../redux/reducer/globalActionType';

class Login extends Component {

    constructor(props){
      super(props);
      this.state = { loggedin:false, load:false }
      localStorage.removeItem('token'); localStorage.removeItem('phone'); localStorage.removeItem('os-user');
    }

    componentDidMount(){
       // this.token_otentikasi();
      //  console.log(API.url);
    }

    handleInput=(e)=>{
      if (e.key === 'Enter'){ this.login(); }
    }

    login = async() =>{
        
      let user = document.getElementById("username").value;
      let pass = document.getElementById("password").value;
      let device = localStorage.getItem('os-user');
      let nilai = {username: user,password: pass,device:device};

      if (user !== "" && pass !== ""){
          this.setState({ load:false })
          axios.post(API.url+'customer/login',nilai)
            .then((response) => {
                // console.log(response.data.content.token); 
                localStorage.setItem('token', response.data.content.token);
                this.setState({ loggedin:true })
                // alert('login berhasil');
            })
            .catch((error) => { 
                // error.response.status
                // API.post_error_db(this.constructor.name.toLowerCase(),error.response.data.error,error.response.status);
                
                toast.error(error.response.data.error,
                {position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 2000,
                hideProgressBar: false});

                // console.log("INi error : ",error.response.data.error);
                this.setState({ load:true })
                $("#username").focus();
            }); 

      }else{ toast.error('username & password required',
                {position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 2000,
                hideProgressBar: false});
             $("#username").focus(); 
          }
    }

  render() {

     if (this.state.loggedin === true){ return <Redirect to='/'></Redirect>}

    return (
      <>
       <Meta/>
        {/* top design start */}
        <img src="assets/images/design.svg" className="img-fluid design-top" alt="" />
        {/* top design end */}
        {/* top bar start */}
        <div className="topbar-section">
          <a href="index.html"><img src="/assets/images/logo.png" className="img-fluid main-logo" alt="" />
            <img src="/assets/images/logo-white.png" className="img-fluid white-logo" alt="" /></a>
          <a className="skip-cls" href="/">SKIP</a>
        </div>
        {/* top bar end */}
        {/* login section start */}
        <section className="form-section px-15 top-space section-b-space">
          <h1>Hey, <br />Login Now</h1>
          <form>
            <div className="form-floating mb-4">
              <input type="text" className="form-control" id="username" onKeyUp={this.handleInput} placeholder="Email" />
              <label htmlFor="one">Email / Phone</label>
            </div>
            <div className="form-floating mb-2">
              <input type="password" className="form-control" id="password" onKeyUp={this.handleInput} placeholder="password" />
              <label htmlFor="two">Password</label>
            </div>
            <div className="text-end mb-4">
              <a href="/forgot" className="theme-color">Forgot Password ?</a>
            </div>
            <a onClick={this.login} className="btn btn-solid w-100">Sign in</a>
          </form>
          {/* <div className="or-divider">
            <span>Or sign in with</span>
          </div> */}
          {/* <div className="social-auth">
            <ul>
              <li>
                <a href="#"><img src="/assets/images/social/google.png" className="img-fluid" alt="" /></a>
              </li>
              <li>
                <a href="#"><img src="/assets/images/social/fb.png" className="img-fluid" alt="" /></a>
              </li>
              <li className="apple-icon">
                <a href="#"><img src="/assets/images/social/apple.png" className="img-fluid" alt="" /></a>
              </li>
            </ul>
          </div> */}
          <div className="bottom-detail text-center mt-3">
            <h4 className="content-color">If you are new, <a className="title-color text-decoration-underline" href="/register">Create Now</a></h4>
          </div>
        </section>
        {/* login section end */}
        {/* panel space start */}
        <section className="panel-space" />
        {/* panel space end */}
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Login;
