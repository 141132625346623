import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import API from '../../services';
// import swal from 'sweetalert';
import { useParams } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import bell_on from '../../assets/images/bellon.png';
import bell_off from '../../assets/images/belloff.png';
import Compimage from './comp/compimage';
import Meta from '../components/meta';

class Notification extends Component {

    constructor(props){
      super(props);
      this.state = { 
        data:[], load:false, loggedin:true,
        datalength:0, loadmore:false, userid:null, notifurl:null
       }
      this.offset = 0;
      this.limit = 30;
    }

    componentDidMount(){
      this.token_otentikasi();
    }

    token_otentikasi = async() => { 
      const stts = await API.otentikasi();
      if (stts === false){ this.setState({ loggedin:false }) }
      else if(stts === true) {
        const decode = await API.decode();
        this.load_trans();
      }
    }

    load_trans = async() => {
      // console.log(this.props.match.params.orderid);
      let nilai = {type: "", campaign:"", read:"", limit:this.limit, offset:this.offset};
      axios.post(API.url+'customer/notif/',nilai,{ headers: {'X-Auth-Token': localStorage.getItem('token')}})
      .then((response) => {
          // console.log("Hasil data load trans : ",response.data.content); 
          this.setState({
            data:response.data.content
          },() => {
              // this.get_payment(result.payment_type);
              // this.load_hide_button();
          });
          
      })
      .catch((error) => { 
        // this.setState({ page404:true })
        // console.log("Error : ", error.response.data.error);
      }); 
        // axios
    }

  render() {    
    // console.log("isi pid : ", this.state.pid);
    let comp;
    if (this.state.loggedin === false){ return <Redirect to='/login'></Redirect>}

    let back;
    if (this.props.match.params.page === 'home'){back = '/';}
    // else if (this.props.match.params.page === 'shop'){back = '/shop';}
    else{ back = `/${this.props.match.params.page}/home`; }

    if (this.state.data !== null){
      comp = this.state.data.map((dynamicData, index) => (

        <Fragment key={index}>
        <Link to={`/notifdetail/${dynamicData.id}`}>
         <div className="item order">
            <div className="media">
            <Compimage dataprops={dynamicData}/>
              {/* <img src={bell_on} className="img-fluid notificon" alt="" /> */}
              <div className="media-body notititle">
                <h4>{dynamicData.subject}</h4>
                <h6 className="content-color">{dynamicData.created}</h6>
              </div>
            </div>
          </div>
        </Link>
        </Fragment>
      ));
    }
    
    return (
      <>
      <Meta/>
         {/* header start */}
         <header>
          <div className="back-links">
            <a href={back}>
              <i className="iconly-Arrow-Left icli" />
              <div className="content">
                <h2>notification</h2>
              </div>
            </a>
          </div>
        </header>
        {/* header end */}
        {/* tab section start */}
        <section className="pt-0 tab-section section-b-space">
          <div className="title-section px-15">
            {/* <h2>Find your Style</h2>
            <h3>Super Summer Sale</h3> */}
          </div>
          <div className="px-15">

            <div id="ourHolder" className="filter-content">
            <div className="divider"></div>

             {/* start looping */}
              {/* <div className="item order">
                <div className="media">
                  <img src={bell_on} className="img-fluid notificon" alt="" />
                  <div className="media-body notititle">
                    <h4>Exclusive Brand Day Sale!! HURRY, LIMITED period offer!</h4>
                    <h6 className="content-color">10 July, 2021</h6>
                  </div>
                </div>
              </div> */}
              {comp}

             {/* start looping */}

            </div>
          </div>
        </section>
        {/* tab section end */}
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Notification;
