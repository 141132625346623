import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
// import swal from 'sweetalert';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import API from '../../../services';

class Proattribute extends Component {
  constructor(props) {
    super(props);
    this.state = { load: false, pricesize:0, pricecolor:0, size:"", color:"" };
  }

  componentDidMount() {}

   calculatesize = async (event,value) =>{
      this.setState({ pricesize: event, size:value }, () => this.calculate());
      //  let price = parseInt(this.props.dataprops.price+event);
      //  console.log('size amount : ',price);
      //  this.props.setprice(price);
    }

     calculatecolor = async (event,value) =>{
      //  console.log('size amount : ',event);
      this.setState({ pricecolor: event, color:value }, () => this.calculate());
      //  let price = parseInt(this.props.dataprops.price+event);
      //  console.log('size amount : ',price);
      //  this.props.setprice(price);
    }

    calculate = () =>{
      //  console.log('size amount : ',event);
       let price = parseInt(this.props.dataprops.price+this.state.pricesize+this.state.pricecolor);
      //  console.log('size amount : ',price);
       this.props.setprice(price);
       this.props.setattr(this.state.size+" - "+this.state.color);
    }

    

  render() {
    let comp; let title; let compcolor; let titlecolor;
    if (this.props.dataprops.attribute !== undefined && this.props.dataprops.attribute.size !== undefined) {
      // console.log('berisi');
      title = <h4 className="size-title"> Select Size: </h4>;
      comp = this.props.dataprops.attribute.size.map((dynamicData, index) => (
        <li key={index} onClick={() => this.calculatesize(dynamicData.amount,dynamicData.value)}><a>{dynamicData.value}</a></li>
      ));
    }

     if (this.props.dataprops.attribute !== undefined && this.props.dataprops.attribute.color !== undefined) {
      // console.log('berisi');
      titlecolor = <h4 className="size-title"> Select Color: </h4>;
      compcolor = this.props.dataprops.attribute.color.map((dynamicData, index) => (
        <li key={index} onClick={() => this.calculatecolor(dynamicData.amount,dynamicData.value)}><a>{dynamicData.value}</a></li>
      ));
    }

    return (
      <>
        <div className="divider" />
        <div className="product-detail-box px-15">
          <div className="size-detail">
            {title}
            <ul className="size-select">
              {comp}
            </ul>
          </div>
          <div className="size-detail">
            {titlecolor}
            <ul className="size-select">
              {compcolor}
            </ul>
          </div>
          <div className="size-detail">
            <h4 className="size-title">Quantity:</h4>
            <div className="qty-counter">
              <div className="input-group">
                <button type="button" className="btn quantity-left-minus" data-type="minus" data-field onClick={() => this.props.minqty()}>
                  <img src="/assets/svg/minus-square.svg" className="img-fluid" alt="" />
                </button>
                <input type="text" name="quantity" className="form-control form-theme qty-input input-number" value={this.props.qty} />
                <button type="button" className="btn quantity-right-plus" data-type="plus" data-field onClick={() => this.props.addqty()}>
                  <img src="/assets/svg/plus-square.svg" className="img-fluid" alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Proattribute;
