import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import API from '../../services';
import db from '../../services/db';
// import swal from 'sweetalert';
import { useParams } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import qs from 'qs';
import Meta from '../components/meta';

class Voucherdetail extends Component {

    constructor(props){
      super(props);
      this.state = { data:[], load:false, back:false, loggedin:true}
    }

    componentDidMount(){
      this.token_otentikasi();
      // console.log('Code : ',this.props.match.params.code);
    }

    token_otentikasi = async() => { 
      const stts = await API.otentikasi();
      if (stts === false){ this.setState({ loggedin:false }) }
      else if(stts === true) {
        // const decode = await API.decode();
       this.load_voucher();
      }
   }

   load_voucher = async() => {
      this.setState({ load:false });
      axios.get(API.url+'vdiscount/get/'+this.props.match.params.code+"/1",{ headers: { 'X-Auth-Token': localStorage.getItem('token') } })
        .then((response) => {
            this.setState({ data: response.data.content,load:true });
            // console.log(response.data.content);
        })
        .catch((error) => { 
            // error.response.status
            // API.post_error_db(this.constructor.name.toLowerCase(),error.response.data.error,error.response.status);
            // swal(error.response.data.error, "", "error");
            // console.log(error);
        }); 
    }

    apply = async() => {
      this.setState({ back: true });
     }

  render() {
    if (this.state.loggedin === false){ return <Redirect to='/login'></Redirect>}
    if (this.state.back === true){ return <Redirect to={`/voucher/cart`}></Redirect>}

    let str;
    if (this.state.data.text !== undefined) {
       str = this.state.data.text.replace('\\"', '"');
    }

    let back;
    if (this.props.match.params.page === 'home'){back = '/';}else{ back = `/${this.props.match.params.page}/home`; }
    
    return (
      <>
      <Meta/>
      <h1> Voucher Detail </h1>
      {/* header start */}
      <header>
          <div className="back-links">
            <a href={back}>
              <i className="iconly-Arrow-Left icli" />
              <div className="content">
                <h2>{this.props.match.params.code}</h2>
              </div>
            </a>
          </div>
        </header>
        {/* header end */}
        {/* section start */}
        <section className="px-15 top-space pt-0 about-section">
          {/* <h2 className="fw-bold mb-2">Introduction</h2> */}
          <div className="help-img">
            <img src={this.state.data.image} className="img-fluid rounded-1 mb-3 w-100" alt="" />
          </div>
          <div dangerouslySetInnerHTML={{__html: str}} />
          {/* <h4 className="mb-2">Multikart is premier fashion destination for the latest trends and hottest styles.</h4> */}
        </section>
        <br/>
        <div className="loadmorebtnorderlist" onClick={() => this.apply()}>
          <p> USE VOUCHER </p>
        </div>
        {/* section end */}
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Voucherdetail;
