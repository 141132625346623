import React, { Component, Fragment } from 'react';

export default class Rating extends Component {
  componentDidMount() {
    // console.log('Rating : ', this.props.data);
  }

  render() {
    const total = 5;
    const rate = parseInt(this.props.data);
    const rest = total - rate;

    const items = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < rate; i++) {
      items.push(<li key={i}><i className="iconly-Star icbo" /></li>);
    }
    const blankitems = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < rest; i++) {
      blankitems.push(<li key={i}><i className="iconly-Star icbo empty" /></li>);
    }

    return (
      <ul className="ratings">
        {items}
        {blankitems}
      </ul>
    );
  }
}
