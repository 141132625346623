import React, { Component, Fragment } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import API from '../../services';
import heart from './heart.png';

toast.configure();

export default class Wishlist extends Component {
  componentDidMount() {
    // console.log('Rating : ', this.props.data);
  }

   wishlist = async event =>{
    //  console.log("token : ",localStorage.getItem('token'));
       this.setState({ load:true });
        axios.get(API.url+'product/wishlist/'+event, { headers: {'X-Auth-Token': localStorage.getItem('token')}})
        .then((response) => { 
            // this.setState({ data: response.data.content.result,load:false });
            // console.log("Hasil response : ",response.data);
            this.props.load_data();
        })
        .catch((err) => { 
          let mess = null;
          if (err.response.status === 401){mess = 'Silahkan login untuk wishlist menu'}
          else{ mess = "Failured to wishlist"; }
          toast.error(mess,
           {position: toast.POSITION.BOTTOM_CENTER,
           autoClose: 2000,
           hideProgressBar: false});
          
          // console.log("Error wishlist : ", err.response.statusText+' - '+err.response.status);
          //  API.otentikasi_acl('product'); API.post_error_db(this.constructor.name.toLowerCase(),err.response.statusText,err.response.status);
         }); 
    }

  render() {
    // const id = parseInt(this.props.data);
    // console.log(this.props.status);
    let comp;
    if (this.props.status === 0){
      comp = <i className="iconly-Heart icli" />;
    }else{ comp =  <img src={heart} alt="wishlistheart" style={{width: '15px', height: '15px', margin:'2px'}} />; }

    return (
      <Fragment>
       <div  className="wishlist-btn" onClick={() => this.wishlist(this.props.data)}>
        {comp}
        {/* <img src={heart} alt="wishlistheart" style={{width: '15px', height: '15px', margin:'2px'}} /> */}
        {/* <i className="iconly-Heart icli" /> */}
      </div>
      </Fragment>
    );
  }
}
