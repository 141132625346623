import React, { Component, Fragment } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import API from '../../services';
import { Helmet } from 'react-helmet';

toast.configure();

export default class Meta extends Component {


  constructor(props){
    super(props);
    this.state = { data:[] }
  }

  componentDidMount() {
    this.get_property();
  }

  get_property = async event =>{
    //  console.log("token : ",localStorage.getItem('token'));
       this.setState({ load:true });
        axios.get(API.url+'configuration/', { headers: {'X-Auth-Token': localStorage.getItem('token')}})
        .then((response) => { 
            this.setState({ data: response.data.content });
            // console.log("Hasil response : ",response.data.content);
        })
        .catch((err) => { 
         }); 
    }

  render() {
    let sitename; let desc; let keyword; let name;
    if (this.state.data !== undefined){
      sitename = this.state.data.site_name;
      desc = this.state.data.meta_description;
      keyword = this.state.data.meta_keyword;
      name = this.state.data.name;
    }
    // console.log('Property adalah : ',data.name);
    return (
      // <h1>Meta</h1>
      <Helmet>
        <title> { `${ sitename }` } </title>
        <meta name="description" content={ `${ desc }` }/>
        <meta name="keywords" content={ `${ keyword }` }/>
        <meta name="author" content={ `${ name }` }/>
      </Helmet>
    );
  }
}
