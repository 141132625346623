import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
// import swal from 'sweetalert';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import API from '../../../services';
import heart from '../../components/heart.png';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = { type: 0, load: false, droppoint: [] };
    this.drop = [];
  }

  componentDidMount() {
    // this.load_droppoint();
  }

  render() {
    // console.log("Isi data props : ",this.props.dataprops);

    let comp;
    if (this.props.dataprops.wishlist === 0){
      comp = <i className="iconly-Heart icli" />;
    }else{ comp = <img src={heart} alt="wishlistheart" style={{width: '15px', height: '15px', margin:'3px 10px 3px 3px'}} />; }

    return (
      <>
        {/* fixed panel start */}
        <div className="fixed-panel">
          <div className="row">
            <div className="col-6">
              <a onClick={() => this.props.action(this.props.dataprops.id)}>
                {comp}
                WISHLIST
              </a>
            </div>
            <div className="col-6">
              <a onClick={() => this.props.actioncart(this.props.dataprops.id)} className="theme-color">
                <i className="iconly-Buy icli" />
                ADD TO BAG
              </a>
            </div>
          </div>
        </div>
        {/* fixed panel end */}
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Footer;
