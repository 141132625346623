/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
// import swal from 'sweetalert';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import API from '../../../services';
import 'react-toastify/dist/ReactToastify.css';
import Ordertracking from './ordertracking';

class Shipping extends Component {
  constructor(props) {
    super(props);
    this.state = { data: null };
  }

  componentDidMount() {
    // console.log("Data props shipping : ",this.props.dataprops);
    // console.log("Orderid : ",this.props.orderid);
  }

  render() {

    // console.log('Integration ; ',this.props.integration);
    // console.log("Data props shipping : ",this.props.dataprops);

    let shipping; let status; let tracking; let ordertracking;

    if (this.props.integration === '1' && this.props.dataprops != null){ordertracking = <Ordertracking data={this.props.dataprops}/>;}

    status = this.props.dataprops;
    if (status === null) {
      shipping = (
        <li className="d-block">
          <h4 className="fw-bold mb-1"> Status Pengiriman </h4>
          <h4 className="content-color"> Pesanan sedang diproses </h4>
        </li>
      );
    }else{
      if (status.track_url !== null){
         tracking = <h4 className="fw-bold mb-1"> <p className="content-color"> 
                    <a className="theme-color" href={`/tracking/${this.props.orderid}`}> Lacak Posisi Kurir </a> </p> </h4>; }
      shipping = (
        <li className="d-block">
          <h4 className="fw-bold mb-1"> Status Pengiriman : <p className="content-color"> {status.status} &nbsp; {status.received} </p> </h4>
          <h4 className="fw-bold mb-1"> Courier : <p className="content-color"> {status.courier} </p> </h4>
          <h4 className="fw-bold mb-1"> Distance : <p className="content-color"> {status.distance} km </p> </h4>
          {tracking}
        </li>
      );
    }
    return (
      <>
       {shipping}
       {ordertracking}
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Shipping;
