import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import API from '../../services';
// import swal from 'sweetalert';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Meta from '../components/meta';

// import ActionType from '../../redux/reducer/globalActionType';

class Verify extends Component {

    constructor(props){
      super(props);
      this.state = {data:[], loggedin:false, displayotp:false, displayotplink:false, load:false }
      localStorage.removeItem('token');
    }

    componentDidMount(){
       this.get_customer();
      //  console.log(API.url);
    }

     get_customer = async() => {
          axios.get(API.url+'customer/get_by_id/'+this.props.match.params.custid)
            .then((response) => {
                this.setState({
                    data:response.data.content  
                },() => {
                   this.req_otp();
                });
            })
            .catch((error) => { 
              toast.error(error.response.data.error,
                {position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 2000,
                hideProgressBar: false});
                this.setState({ loggedin:true })
                // error.response.status
                // API.post_error_db(this.constructor.name.toLowerCase(),error.response.data.error,error.response.status);
            }); 
     }

    handleInput=(e)=>{
      if (e.key === 'Enter'){ this.login(); }
    }

    counter_link = async() =>{
       setTimeout(
         () => this.setState({ displayotplink: true }), 
         30000
       );
    }

    req_otp = async() => {
      let user = this.state.data.email;
      let nilai = {username: user};

      if (user !== ""){
          this.setState({ load:false, displayotplink:false })
          axios.post(API.url+'customer/req_otp',nilai)
            .then((response) => {
                // console.log(response.data.content); 
                this.setState({ displayotp:true })
                this.counter_link();

                 toast.success(response.data.content,
                {position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 2000,
                hideProgressBar: false});
                // alert('login berhasil');
            })
            .catch((error) => { 
                // error.response.status
                // API.post_error_db(this.constructor.name.toLowerCase(),error.response.data.error,error.response.status);
                
                 toast.error(error.response.data.error,
                {position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 2000,
                hideProgressBar: false});

                // console.log("INi error : ",error.response.data.error);
                this.setState({ load:true })
                $("#username").focus();
            }); 

      }else{ toast.error('username required',
                {position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 2000,
                hideProgressBar: false});
             $("#username").focus(); 
          }
    }

     verify = async() => {
       let otp = document.getElementById("otp").value;
        axios.get(API.url+'customer/verify/'+this.props.match.params.custid+"/"+otp)
          .then((response) => {
              // console.log(response.data.content); 
               toast.success("Your account has been verified",
                {position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 2000,
                hideProgressBar: false});
                setTimeout(
                  () => this.setState({ loggedin: true }), 
                  5000
                );
              
          })
          .catch((error) => { 
            toast.error(error.response.data.error,
                {position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 2000,
                hideProgressBar: false});
          }); 
     }

  render() {

     let otplink;
     if (this.state.displayotplink === true){
        otplink = <h4> Not received otp code? <br/> <a onClick={this.req_otp} style={{color: 'red'}}> Request again. </a> </h4>;
     }

    if (this.state.loggedin === true){ return <Redirect to='/login'></Redirect>}

    return (
      <>
        <Meta/>
        {/* top design start */}
        <img src="assets/images/design.svg" className="img-fluid design-top" alt="" />
        {/* top design end */}
        {/* top bar start */}
        <div className="topbar-section">
          <a href="index.html"><img src="/assets/images/logo.png" className="img-fluid main-logo" alt="" />
            <img src="/assets/images/logo-white.png" className="img-fluid white-logo" alt="" /></a>
             {/* <a className="skip-cls" href="/">SKIP</a> */}
        </div>
        {/* top bar end */}
        {/* login section start */}
        <section className="form-section px-15 top-space section-b-space">
          <h1> Hey, Verify Your Account </h1>
          <form>
            <div className="form-floating mb-2">
              <input type="number" className="form-control" id="otp" placeholder="otp" />
              <label htmlFor="two"> OTP </label>
            </div>
            <a onClick={this.verify} className="btn btn-solid w-100"> Verify </a>
          </form>
        
          <div className="bottom-detail text-center mt-3">
             <h4 className="content-color">Back to &nbsp;
                <a className="title-color text-decoration-underline" href="/login">Sign In</a>
             </h4>
             {otplink}
          </div>
        </section>
        {/* login section end */}
        {/* panel space start */}
        <section className="panel-space" />
        {/* panel space end */}
      </>
    );
  }
}

export default Verify;
