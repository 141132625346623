import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import API from '../../services';
import db from '../../services/db';
// import swal from 'sweetalert';
import { useParams } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import style from '../../../public/assets/css/style.css';
import Meta from '../components/meta';


// import ActionType from '../../redux/reducer/globalActionType';

class Wishlist extends Component {

    constructor(props){
      super(props);
      this.state = { data:[], load:false, datalength:0, loggedin:true }
      this.offset = 0;
      this.limit = 100;
      this.outlet;
      // this.order="desc";
    }

    componentDidMount(){
      this.token_otentikasi();
    }

    token_otentikasi = async() => { 
      const stts = await API.otentikasi();
      if (stts === false){ this.setState({ loggedin:false }) }else{
        this.load_product();
      }
   }


    load_product = async() => {
      this.setState({ load:false });
      let nilai = {limit:this.limit, offset:this.offset};
      // console.log(nilai);
      axios.post(API.url+'customer/whislist',nilai,{ headers: { 'X-Auth-Token': localStorage.getItem('token') } })
        .then((response) => {
            this.setState({ data: response.data.content.result, datalength:response.data.content.record, load:true });
            // console.log(response.data.content.result);
        })
        .catch((error) => { 
            // error.response.status
            // API.post_error_db(this.constructor.name.toLowerCase(),error.response.data.error,error.response.status);
            // swal(error.response.data.error, "", "error");
            // console.log(error);
        }); 
    }

    remove = async event =>{
      // console.log(event);
      this.setState({ load:true });
      axios.get(API.url+'product/wishlist/'+event, { headers: {'X-Auth-Token': localStorage.getItem('token')}})
       .then((response) => { 
           // this.setState({ data: response.data.content.result,load:false });
           // console.log(response.data.content);
           this.load_product();
       })
       .catch((err) => { 
         let mess = null;
         if (err.response.status === 401){mess = 'Silahkan login untuk wishlist menu'}
         toast.error(mess,
          {position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 2000,
          hideProgressBar: false});
         
         // console.log("Error wishlist : ", err.response.statusText+' - '+err.response.status);
         //  API.otentikasi_acl('product'); API.post_error_db(this.constructor.name.toLowerCase(),err.response.statusText,err.response.status);
        }); 
   }

  render() {
    let comp;
    if (this.state.loggedin === false){ return <Redirect to='/login'></Redirect>}

    let back;
    if (this.props.match.params.page === 'home'){back = '/';}
    else if (this.props.match.params.page === 'shop'){back = '/shop';}
    else{ back = `/${this.props.match.params.page}/home`; }

    if (this.state.datalength > 0){
      comp = this.state.data.map((dynamicData, index) => (
        <Fragment key={index}>

        <div className="cart-box px-15">
          <a href={`/product/${dynamicData.sku}`} className="cart-img">
            <img src={dynamicData.image} className="img-fluid" alt={dynamicData.name} />
          </a>
          <div className="cart-content">
            <Link to={`/product/${dynamicData.sku}`}>
              <h4>{API.capitalize(dynamicData.name)}</h4>
            </Link>
            {/* <h5 className="content-color">by Mango</h5> */}
            <div className="price">
              <h4>
                {API.formatNumber(dynamicData.price)}
                /
                <span>{dynamicData.period}</span>
              </h4>
            </div>
            <div className="cart-option">
              {/* <a id={dynamicData.id} onClick={() => this.add_cart(dynamicData.product_id)}>
                <h5 data-bs-toggle="offcanvas" data-bs-target="#removecart"><i className="iconly-Buy icli" /> Add to
                  Cart</h5>
              </a>
              <span className="divider-cls">|</span> */}
              <a id={dynamicData.id} onClick={() => this.remove(dynamicData.product_id)}>
              <h5 data-bs-toggle="offcanvas" ><i className="iconly-Delete icli" />
                Remove
              </h5>
              </a>
            </div>
          </div>
        </div>
        <div className="divider" />

        </Fragment>
      )) ;

    }
    // console.log("data length :",this.state.data );
    return (
      <>
      <Meta/>
       {/* header start */}
       <header>
          <div className="back-links">
            <a href={back}>
              <i className="iconly-Arrow-Left icli" />
              <div className="content">
                <h2>Your Wishlist ({this.state.datalength})</h2>
              </div>
            </a>
          </div>
          <div className="header-option">
            <ul>
              <li>
                <a href="/cart/shop"><i className="iconly-Buy icli" /></a>
              </li>
            </ul>
          </div>
        </header>
        {/* header end */}
        {/* cart items start */}
        <section className="cart-section pt-0 top-space section-b-space">

        {comp}

          {/* start looping */}
          {/* <div className="cart-box px-15">
            <a href="product.html" className="cart-img">
              <img src="assets/images/products/1.jpg" className="img-fluid" alt="" />
            </a>
            <div className="cart-content">
              <a href="product.html">
                <h4>Pink Hoodie t-shirt full </h4>
              </a>
              <h5 className="content-color">by Mango</h5>
              <div className="price">
                <h4>$32.00 <del>$35.00</del><span>20%</span></h4>
              </div>
              <div className="cart-option">
                <h5 data-bs-toggle="offcanvas" data-bs-target="#removecart"><i className="iconly-Buy icli" /> Add to
                  Cart</h5>
                <span className="divider-cls">|</span>
                <h5 data-bs-toggle="offcanvas" data-bs-target="#removecart"><i className="iconly-Delete icli" />
                  Remove
                </h5>
              </div>
            </div>
          </div>
          <div className="divider" /> */}
          {/* start looping */}
          
  
        </section>
        {/* cart items end */}
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Wishlist;
