/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import Cartqty from '../../components/cartqty';

// eslint-disable-next-line react/prefer-stateless-function
class Shopheader extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    // console.log('Shop header : ', this.props.title);
  }

  render() {
    return (
      <div>
        <header>
          <div className="back-links">
            <Link to={this.props.link}>
              <i className="iconly-Arrow-Left icli" />
              <div className="content">
                <h2>{this.props.title}</h2>
                <h6>
                  {this.props.count} &nbsp;
                   Products
                </h6>
              </div>
            </Link>
          </div>
          <div className="header-option">
            <ul>
              <li>
                <a href="/wishlist/home"><i className="iconly-Heart icli" /></a>
              </li>
              <Cartqty page={'home'}/>
            </ul>
          </div>
        </header>
        <br />
      </div>
    );
  }
}

export default Shopheader;
