import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import axios from 'axios';
import API from '../../services';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Meta from '../components/meta';

class Notifdetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
    this.limit = 8;
    this.offset = 0;
  }

  componentDidMount() {
    // this.load_product();
    // console.log("Parameter : ",this.props.match.params.id);
    // this.props.history.goBack();
     this.token_otentikasi();
  }

  token_otentikasi = async() => { 
    const stts = await API.otentikasi();
    if (stts === false){ this.setState({ loggedin:false }) }
    else if(stts === true) {
      const decode = await API.decode();
      this.load_notif();
      // console.log("Hasil decode : ",decode);
    }
 }


   load_notif = async() => {
    this.setState({ load:false });
    // console.log(nilai);
    axios.get(API.url+'customer/notif_detail/'+this.props.match.params.id,{ headers: {'X-Auth-Token': localStorage.getItem('token')}})
      .then((response) => {
          // console.log("Hasil response : ",response.data.content);
          this.setState({ data: response.data.content, load:true });
      })
      .catch((error) => { 
          // error.response.status
          // API.post_error_db(this.constructor.name.toLowerCase(),error.response.data.error,error.response.status);
          // swal(error.response.data.error, "", "error");
          // console.log(error);
      }); 
  }

   goBack = () => { 
     this.props.history.goBack();
   }

  render() {
    let str;
    if (this.state.data.content !== undefined) {
       str = this.state.data.content.replace('\\"', '"');
    }
    // console.log("Isi data state : ",this.state.data);
    return (
      <>
      <Meta/>
       {/* header start */}
       <header>
          <div className="back-links">
            <a href="/notification/home">
              <i className="iconly-Arrow-Left icli" />
              <div className="content">
                <h2> Notif Detail </h2>
              </div>
            </a>
          </div>
        </header>
        {/* header end */}
        {/* slider start */}
        <section className="product-page-section top-space pt-0">

          <div className="product-detail-box px-15 pt-2">
            <div className="main-detail">
              <h2 className="text-capitalize">{this.state.data.subject}</h2>
              <h6 className="content-color">{this.state.data.created}</h6>
            </div>
          </div>

          <div className="divider" />
          <div className="product-detail-box px-15">
            <div dangerouslySetInnerHTML={{__html: str}} />
          </div>
          <div className="divider" />
         
        </section>
        {/* slider end */}

      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Notifdetail;
