import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import API from '../../services';
import db from '../../services/db';
// import swal from 'sweetalert';
import { useParams } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import style from '../../../public/assets/css/style.css';
// import ActionType from '../../redux/reducer/globalActionType';
import Meta from '../components/meta';

class Tracking extends Component {

    constructor(props){
      super(props);
      this.state = { data:null, load:false, loggedin:true,page404:false,orderid:null }
      let timeoutId;
    }

    componentDidMount(){
      this.token_otentikasi();
      // console.log("Orderid : ",this.props.match.params.orderid);
    }

    token_otentikasi = async() => { 
      const stts = await API.otentikasi();
      if (stts === false){ this.setState({ loggedin:false }) }
      else if(stts === true) {
          this.load_trans();
      }
    }

    load_trans = async() => {
      // console.log('ini load trans');
      // console.log(this.props.match.params.transid);
      axios.get(API.url+'pos/get_trans/'+this.props.match.params.orderid,{ headers: {'X-Auth-Token': localStorage.getItem('token')}})
      .then((response) => {
          // console.log(response.data.content.shipping); 
          let result = response.data.content.shipping; 
          this.setState({ data:result });
      })
      .catch((error) => { 
        this.setState({ page404:true })
        // console.log("Error : ", error.response.data.error);
      }); 
        // axios
    }

  render() {
    let track;
    // console.log("Hasil Data Tracking : ",this.state.data);
    if (this.state.data !== null){
      track = <iframe src={this.state.data.track_url} frameBorder={0} style={{overflow: 'auto', height: '100%', width: '100%'}} height="100%" width="100%" />;
    }

    if (this.state.loggedin === false){ return <Redirect to='/login'></Redirect>}
    if (this.state.page404 === true){ return <Redirect to={`/orderhistory`}></Redirect>}
    
    return (
      <>
        <Meta/>
        {/* header start */}
         <header>
          <div className="back-links">
            <a href={`/orderplaced/${this.props.match.params.orderid}`}>
              <i className="iconly-Arrow-Left icli" />
              <div className="content">
                <h2> Detail Pesanan</h2>
              </div><br/>
            </a>
          </div>
        </header>
        {/* header end */}

        <section className="px-15"> <br/>
        <div className="iframe-container">
        {/* {`https://checkout-staging.xendit.co/web/${this.props.match.params.transid}`} */}
         {track}
        </div>
        
        </section>

      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Tracking;
