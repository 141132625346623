/* eslint-disable react/prop-types */
import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
// import swal from 'sweetalert';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import API from '../../../services';
import 'react-toastify/dist/ReactToastify.css';

class Ordertracking extends Component {
  constructor(props) {
    super(props);
    this.state = { data: null };
  }

  componentDidMount() {
    // console.log('Data props ordertracking : ', this.props.data);
    // console.log("Orderid : ",this.props.orderid);
  }

  render() {
    // console.log('Data props ordertracking : ', this.props.data);
    let comp;
    if (this.props.data.manifest !== null){
      comp =  this.props.data.manifest.map((dynamicData, index) => (
        <Fragment key={index}>
        <div className="order-track-step">
          <div className="order-track-status">
            <span className="order-track-status-dot">
              <img src="/assets/svg/check.svg" className="img-fluid" alt="" />
            </span>
            <span className="order-track-status-line" />
          </div>
          <div className="order-track-text">
            <p className="order-track-text-stat"> {dynamicData.manifest_code} <br/> {dynamicData.manifest_description} </p>
            <span className="order-track-text-sub"> {dynamicData.manifest_date} - {dynamicData.manifest_time} </span>
          </div>
        </div>
        </Fragment>
      ));
    }
    return (
      <>
        {/* order tracking start */}
        <div className="divider"></div>
        <h2> Order Tracking </h2>
        <div className="order-track px-15">
          {comp}

          {/* <div className="order-track-step">
            <div className="order-track-status">
              <span className="order-track-status-dot">
                <img src="/assets/svg/check.svg" className="img-fluid" alt="" />
              </span>
              <span className="order-track-status-line" />
            </div>
            <div className="order-track-text">
              <p className="order-track-text-stat"> In Transit</p>
              <span className="order-track-text-sub">10.00 am, 21/05/2020</span>
            </div>
          </div> */}

        </div>
        {/* order tracking end */}

      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Ordertracking;
