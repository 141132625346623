/* eslint-disable react/prefer-stateless-function */
import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios';
import { useParams } from 'react-router-dom';
import API from '../../../services';
import Rating from '../../components/rating';
import Wishlist from '../../components/wishlist';
import customcss from '../../../assets/css/custom.css';

export class Prolist extends Component {
  constructor(props) {
    super(props);
    // eslint-disable-next-line react/no-unused-state
    this.state = { data: [], load: false };
  }

  componentDidMount() {
    // this.token_otentikasi();
    //  console.log(API.url);
  }

  render() {
    let comp;
    if (this.props.loadmore === true) {
      comp = (
        <div className="loadmorebtn" onClick={() => this.props.actionlodemore()}>
          {' '}
          <p> Tampilkan Lebih Banyak </p>
          {' '}
        </div>
      );
    }
    return (
      <section className="px-15 lg-t-space">
        <div className="row gy-3 gx-3">
          {
        this.props.data.map((dynamicData, index) => (
          <Fragment key={index}>

            <div className="col-6">
              <div className="product-box ratio_square">
                <div className="img-part">
                  <Link to={`/product/${dynamicData.sku}`}>
                    <img src={dynamicData.image} alt="" className="img-fluid bg-img" />
                  </Link>
                  {/* wishlist */}
                  <Wishlist data={dynamicData.id} status={dynamicData.wishlist} load_data={this.props.load_data} />
                  {/* wishlist */}
                  {/* <label>new</label> */}
                </div>
                <div className="product-content">
                  <Rating data={dynamicData.rating} />
                   <Link to={`/product/${dynamicData.sku}`}>
                    <h4>{API.capitalize(dynamicData.name)}</h4>
                   </Link>
                  <div className="price">
                    <h4>
                      {API.formatNumber(dynamicData.price)}
                      /
                      <span>{dynamicData.period}</span>
                    </h4>
                  </div>
                </div>
              </div>
            </div>

          </Fragment>
        ))

}
          {/* load more */}
          {comp}

          {/* box container */}
          {/* <div className="col-6">
            <div className="product-box ratio_square">
              <div className="img-part">
                <a href="product.html"><img src="assets/images/products/1.jpg" alt="" className="img-fluid bg-img" /></a>
                <div className="wishlist-btn">
                  <i className="iconly-Heart icli" />
                  <i className="iconly-Heart icbo" />
                  <div className="effect-group">
                    <span className="effect" />
                    <span className="effect" />
                    <span className="effect" />
                    <span className="effect" />
                    <span className="effect" />
                  </div>
                </div>
                <label>new</label>
              </div>
              <div className="product-content">
                <ul className="ratings">
                  <li><i className="iconly-Star icbo" /></li>
                  <li><i className="iconly-Star icbo" /></li>
                  <li><i className="iconly-Star icbo" /></li>
                  <li><i className="iconly-Star icbo" /></li>
                  <li><i className="iconly-Star icbo empty" /></li>
                </ul>
                <a href="product.html">
                  <h4>Blue Denim Jacket</h4>
                </a>
                <div className="price">
                  <h4>
                    $32.00
                    {' '}
                    <del>$35.00</del>
                    <span>20%</span>
                  </h4>
                </div>
              </div>
            </div>
          </div> */}
          {/* box container */}

        </div>
      </section>
    );
  }
}

export default Prolist;
