/* eslint-disable react/prop-types */
import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
// import swal from 'sweetalert';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import API from '../../../services';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Default extends Component {
  constructor(props) {
    super(props);
    this.state = { type: 0, load: false, droppoint: [] };
    this.drop = [];
  }

  componentDidMount() {

  }

  setdefault = async(event) => {
     axios.get(API.url+'customer/default_address/'+event,{ headers: {'X-Auth-Token': localStorage.getItem('token')}})
     .then((response) => {
        //  let result = response.data.content;
         toast.success("Alamat utama berhasil di terapkan",
          {position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 2000,
          hideProgressBar: false});
          this.props.loaddata();
         // this.props.setcity(parseFloat(result.cost),parseInt(result.add_cost),result.cash,uid);
     })
     .catch((error) => { 
     }); 
   }

  render() {
    // console.log("Props data : ",this.props.data);
    let comp;
    if (this.props.data.default === '1'){ comp = <h6 className="label">ALAMAT UTAMA</h6>; }
    else{ comp = <h6 onClick={() => this.setdefault(this.props.data.id)} className="label"> SET ALAMAT UTAMA</h6>; }
    return (
      <>
      {comp}
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Default;
