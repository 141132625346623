import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import axios from 'axios';
import API from '../../../services';
import Wishlist from '../../components/wishlist';

// eslint-disable-next-line react/prefer-stateless-function
export class Deals extends Component {

  constructor(props) {
    super(props);
    // eslint-disable-next-line react/no-unused-state
    this.state = { data: [], load: false };
  }

  componentDidMount() {
    // this.token_otentikasi();
    //  console.log(API.url);
    this.load_product();
  }

  testing = () => {
    alert('Hello testing');
  }

  load_product = () => {
        this.setState({ load:false });
        axios.post(API.url+'product',{category: "",publish: "", recommend:"1", bestseller:"", latest:"", economic:"", limit:"3", offset:"0"},{ headers: { 'X-Auth-Token': localStorage.getItem('token') } })
          .then((response) => {
              this.setState({ data: response.data.content.result,load:true });
              // console.log(response.data.content.result);
          })
          .catch((error) => { 
              // error.response.status
              // API.post_error_db(this.constructor.name.toLowerCase(),error.response.data.error,error.response.status);
              // swal(error.response.data.error, "", "error");
              // console.log(error);
          }); 
        
  }

  render() {
    return (
      <div>
        <br />
        <section className="deals-section px-15 pt-0">
          <div className="title-part">
            <h2> Rekomendasi Produk Hari Ini </h2>
            <Link to="/shop"> See All </Link>
            {/* <a href="shop.html">See All</a> */}
          </div>
          <div className="product-section">
            <div className="row gy-3">

{
        this.state.data.map((dynamicData,index) => {
            return (
            <Fragment key={index}>
  <div className="col-12">
                <div className="product-inline">
                  <Link to={`/product/${dynamicData.sku}`}>
                  {/* <a href="product.html"> */}
                    <img src={dynamicData.image} className="img-fluid" alt="" />
                  {/* </a> */}
                  </Link>
                  {/* <Link to={`/product/${dynamicData.sku}`}> */}
                  <div className="product-inline-content">
                    <div>
                      <Link to={`/product/${dynamicData.sku}`}>
                        <h4>{API.capitalize(dynamicData.name)}</h4>
                      </Link>
                      <h5>{dynamicData.category}</h5>
                      <div className="price">
                        <h4> {API.formatNumber(dynamicData.price)}
                        {/* <del>$35.00</del> */}
                        </h4>
                        <span style={{color: '#FF4C3B'}}>{dynamicData.period}</span>
                      </div>
                    </div>
                  </div>
                  {/* wishlist */}
                  <Wishlist data={dynamicData.id} status={dynamicData.wishlist} load_data={this.load_product} />
                  {/* wishlist */}
                </div>
              </div>

            </Fragment>                
            );
        })

}

              {/* <div className="col-12">
                <div className="product-inline">
                  <a href="product.html">
                    <img src="assets/images/products/1.jpg" className="img-fluid" alt="" />
                  </a>
                  <div className="product-inline-content">
                    <div>
                      <a href="product.html">
                        <h4>Pink Hoodie t-shirt full </h4>
                      </a>
                      <h5>by Mango</h5>
                      <div className="price">
                        <h4>$32.00 <del>$35.00</del><span>20%</span></h4>
                      </div>
                    </div>
                  </div>
                  <div className="wishlist-btn">
                    <i className="iconly-Heart icli" />
                    <i className="iconly-Heart icbo" />
                    <div className="effect-group">
                      <span className="effect" />
                      <span className="effect" />
                      <span className="effect" />
                      <span className="effect" />
                      <span className="effect" />
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div className="col-12">
                <div className="product-inline">
                  <a href="product.html">
                    <img src="assets/images/products/2.jpg" className="img-fluid" alt="" />
                  </a>
                  <div className="product-inline-content">
                    <div>
                      <a href="product.html">
                        <h4>Men Blue Denim Jacket</h4>
                      </a>
                      <h5>by Zara</h5>
                      <div className="price">
                        <h4>$32.00<span>SAVE 20%</span></h4>
                      </div>
                    </div>
                  </div>
                  <div className="wishlist-btn">
                    <i className="iconly-Heart icli" />
                    <i className="iconly-Heart icbo" />
                    <div className="effect-group">
                      <span className="effect" />
                      <span className="effect" />
                      <span className="effect" />
                      <span className="effect" />
                      <span className="effect" />
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div className="col-12">
                <div className="product-inline">
                  <a href="product.html">
                    <img src="assets/images/products/3.jpg" className="img-fluid" alt="" />
                  </a>
                  <div className="product-inline-content">
                    <div>
                      <a href="product.html">
                        <h4>Pink Hoodie t-shirt full </h4>
                      </a>
                      <h5>by H&amp;M</h5>
                      <div className="price">
                        <h4>$32.00 <del>$35.00</del><span>20%</span></h4>
                      </div>
                    </div>
                  </div>
                  <div className="wishlist-btn">
                    <i className="iconly-Heart icli" />
                    <i className="iconly-Heart icbo" />
                    <div className="effect-group">
                      <span className="effect" />
                      <span className="effect" />
                      <span className="effect" />
                      <span className="effect" />
                      <span className="effect" />
                    </div>
                  </div>
                </div>
              </div> */}


            </div>
          </div>
        </section>
        <div className="divider" />
      </div>
    );
  }
}

export default Deals;
