import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import API from '../../services';
import db from '../../services/db';
// import swal from 'sweetalert';
import { useParams } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Searching from './comp/searching';
import qs from 'qs';
import Meta from '../components/meta';
import Cityrj from './comp/cityrj';

class Newaddress extends Component {

    constructor(props){
      super(props);
      this.state = { data:[], load:false, back:false, loggedin:true, address:null, coor:null,
                     provid:null, cityid:null, districtid:null 
                   }
    }

    componentDidMount(){
      this.token_otentikasi();
    }

    token_otentikasi = async() => { 
      const stts = await API.otentikasi();
      if (stts === false){ this.setState({ loggedin:false }) }
      else if(stts === true) {
        // const decode = await API.decode();
      //  this.load_address();
      }
   }

   setadd = event => {
      this.setState({address:event});
      // alert(event);
   }

   setcor = event => {
     this.setState({coor:event});
    // alert(event);
   }

   add = async() =>{
    
    let error = null;
    
    let name = document.querySelector('input[name="name"]:checked').value;
    let phone = document.getElementById("phone").value;

    if (this.state.address === null){ error = "Alamat wajib diisi"; }
    if (this.state.coor === null){ error = "Coordinate tidak boleh kosong"; }
    if (this.state.provid === null){ error = "Provinsi diperlukan"; }
    if (this.state.cityid === null){ error = "Kota/Kabupaten wajib diisi"; }
    if (this.state.districtid === null){ error = "Kecamatan wajib diisi"; }
    if (phone === ""){ error = "No telepon wajib diisi"; }


    if (error === null){

      let requestBody = {tname: name,taddress: this.state.address, tcoordinate:this.state.coor, tphone: phone, cprov:this.state.provid, ccity:this.state.cityid, cdistrict:this.state.districtid }
      // console.log(requestBody);
      
      this.setState({ load:false })
      axios.post(API.url+'customer/add_address',qs.stringify(requestBody),{ headers: {'X-Auth-Token': localStorage.getItem('token')}})
        .then((response) => {
            // console.log(response.data.content); 
            toast.success("Alamat Berhasil Ditambah",
                {position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 2000,
                hideProgressBar: false});
            this.setState({back:true});
        })
        .catch((error) => { 
          // console.log("Error : ", error.response.data.error);
          toast.error(error.response.data.error,
                {position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 2000,
                hideProgressBar: false});
        }); 
          // axios
    }else{
      toast.error(error,
        {position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 2000,
        hideProgressBar: false});
    }
  }

  setcity = async(provid,cityid,districtid) =>{
    // console.log('Prov : ',provid);
    // console.log('City : ',cityid);
    // console.log('District : ',districtid);
    this.setState({provid:provid,cityid:cityid,districtid:districtid});
  }

  render() {
    if (this.state.loggedin === false){ return <Redirect to='/login'></Redirect>}
    if (this.state.back === true){ return <Redirect to='/caddress/cart'></Redirect>}
    
    return (
      <>
      <Meta/>
       {/* header start */}
       <header>
        <div className="back-links">
          <a href="/caddress/cart">
            <i className="iconly-Arrow-Left icli" />
            <div className="content">
              <h2>Add a new address </h2>
            </div>
          </a>
        </div>
      </header>
      {/* header end */}
      
       <div className="divider"></div>
       {/* address details start */}
       <section className="top-space pt-2">
          <div className="address-form-section px-15">

            <Cityrj setcity={this.setcity}/>

            <div className="form-floating mb-4">
              <Searching setadd={this.setadd} setcor={this.setcor} />
              <label htmlFor="floatingSelect"> Set Alamat </label>
            </div>
            <div className="form-floating mb-4">
            <h4>{this.state.address}</h4>
            <h4>{this.state.coor}</h4>
              {/* <textarea className="form-control" id="address" placeholder="Address" style={{height: '90px'}} defaultValue={this.state.address}> </textarea> */}
              {/* <label htmlFor="address">Address</label> */}
            </div>

            <div className="form-floating mb-4">
              <input type="number" className="form-control" id="phone" placeholder="Phone" />
              <label htmlFor="one">Phone</label>
            </div>
          </div> 

        <div className="divider" />
        <div className="type-address px-15">
          <h2 className="page-title">Type of address</h2>
          <form className="d-flex">
            <div className="me-3 d-flex align-items-center mb-2">
              <input className="radio_animated" type="radio" name="name" id="type" defaultValue="rumah" />
              <label htmlFor="Home">Rumah</label>
            </div>
            <div className="me-3 d-flex align-items-center mb-2">
              <input className="radio_animated" type="radio" name="name" id="type" defaultValue="kantor" />
              <label htmlFor="office">Kantor</label>
            </div>
            <div className="d-flex align-items-center mb-2">
              <input className="radio_animated" type="radio" name="name" id="type" defaultValue="lainnya" />
              <label htmlFor="others">Lainnya</label>
            </div>
          </form>
        </div>

        </section>


        {/* address details end */}
        {/* panel space start */}
        <section className="panel-space" />
        {/* panel space end */}
        {/* bottom panel start */}
        <div className="cart-bottom row m-0">
          <div>
            <div className="left-content col-5">
              <a href="/caddress" className="title-color">CANCEL</a>
            </div>
            <a onClick={this.add} className="btn btn-solid col-7 text-uppercase">Add Address</a>
          </div>
        </div>
        {/* bottom panel end */}


      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Newaddress;
