import React, { Component } from 'react';

export class Bottomtab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      home: '', product: '', cart: '', profile: '', wishlist: '',
    };
  }

  componentDidMount() {
    // console.log(this.props.active);
    if (this.props.active === 'home'){this.setState({ home:"active" })}
    else if(this.props.active === 'profile'){ this.setState({ profile:"active" }) }
  }

  render() {
    return (
      <div className="bottom-panel">
        <ul>
          <li className={this.state.home}>
            <a href="/">
              <div className="icon">
                <i className="iconly-Home icli" />
                <i className="iconly-Home icbo" />
              </div>
              <span>home</span>
            </a>
          </li>
           <li className={this.state.product}>
            <a href="/shop">
              <div className="icon">
                <i className="iconly-Category icli" />
                <i className="iconly-Category icbo" />
              </div>
              <span>product</span>
            </a>
          </li>
           <li className={this.state.cart}>
            <a href="/cart/home">
              <div className="icon">
                <i className="iconly-Buy icli" />
                <i className="iconly-Buy icbo" />
              </div>
              <span>cart</span>
            </a>
          </li>
           <li className={this.state.voucher}>
            <a href="/voucher/home">
            <div className="icon">
                <i className="iconly-Discount icli" />
                <i className="iconly-Discount icbo" />
            </div>
              <span>Voucher</span>
            </a>
          </li>
          <li className={this.state.profile}>
            <a href="/profile/home">
              <div className="icon">
                <i className="iconly-Profile icli" />
                <i className="iconly-Profile icbo" />
              </div>
              <span>profile</span>
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

export default Bottomtab;
