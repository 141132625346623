import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import API from '../../services';
import db from '../../services/db';
// import swal from 'sweetalert';
import { useParams } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import style from '../../../public/assets/css/style.css';
import Meta from '../components/meta';
import Default from './comp/default.jsx';

// import ActionType from '../../redux/reducer/globalActionType';

class Caddress extends Component {

    constructor(props){
      super(props);
      this.state = { data:[], load:false, loggedin:true, setcoor:false }
      this.offset = 0;
      this.limit = 100;
      this.outlet;
      // this.order="desc";
      localStorage.removeItem('coordinate'); localStorage.removeItem('phone'); localStorage.removeItem('addressid');
    }

    componentDidMount(){
      this.token_otentikasi();
    }

    token_otentikasi = async() => { 
      const stts = await API.otentikasi();
      if (stts === false){ this.setState({ loggedin:false }) }
      else if(stts === true) {
        // const decode = await API.decode();
        this.load_address();
      }
   }

   load_address = async() => {
    this.setState({ load:false });
    axios.get(API.url+'customer/get_address',{ headers: {'X-Auth-Token': localStorage.getItem('token')}})
      .then((response) => {
          this.setState({ data: response.data.content.result,load:true });
          // console.log(response.data.content.result);
      })
      .catch((error) => { }); 
   }

   goBack = () => { 
    this.props.history.goBack();
   }

   remove = async(event) => {
    //  console.log(event);
    this.setState({ load:false });
    axios.get(API.url+'customer/remove_address/'+event,{ headers: {'X-Auth-Token': localStorage.getItem('token')}})
      .then((response) => {
          this.load_address();
          toast.success("Alamat berhasil dihapus",
            {position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000,
            hideProgressBar: false});
          // alert('berhasil d hapus');
      })
      .catch((error) => { }); 
   }

   setcoor = async(event,phone,id) => {
    //  console.log("ID Alamat Customer : ",id);
     localStorage.setItem('addressid', id);
     localStorage.setItem('coordinate', event);
     localStorage.setItem('phone', phone);
     setTimeout(this.setState({ setcoor:true }), 2000);
   }


  render() {
    // console.log("Hasil Data : ",this.state.data);

    let back;
    if (this.props.match.params.page === 'home'){back = '/';}
    // else if (this.props.match.params.page === 'shop'){back = '/shop';}
    else{ back = `/${this.props.match.params.page}/home`; }

    let comp; let def;
    if (this.state.data !== null){
      comp =  this.state.data.map((dynamicData, index) => (
        <Fragment key={index}>
        <li>
          <div className="check-box">
            <div className="form-check d-flex ps-0">
              <input onClick={() => this.setcoor(dynamicData.coordinate,dynamicData.phone,dynamicData.id)} className="radio_animated" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
              <label className="form-check-label" htmlFor="flexRadioDefault1" />
              <div>
                <h4 className="name">{API.capitalize(dynamicData.name)}</h4>
                <div className="addess">
                  <h4>{dynamicData.address}</h4>
                </div>
                <h4>Phone No: {dynamicData.phone}</h4>
                {/* <h6 className="label">ALAMAT UTAMA</h6> */}
                <Default loaddata={this.load_address} data={dynamicData}/>
              </div>
            </div>
          </div>
          <div className="buttons l-15">
            <a onClick={() => this.remove(dynamicData.id)}>HAPUS</a>
            {/* <a href="#">edit</a> */}
          </div>
          </li>
  
        </Fragment>
      ));
    }

    if (this.state.loggedin === false){ return <Redirect to='/login'></Redirect>}
    if (this.state.setcoor === true){ return <Redirect to='/cart/shop'></Redirect>}
    
    return (
      <>
      <Meta/>
{/* header start */}
<header>
        <div className="back-links">
          {/* <a onClick={() => this.goBack()}> */}
          <a href={back}>
            <i className="iconly-Arrow-Left icli" />
            <div className="content">
              <h2>saved address</h2>
            </div>
          </a>
        </div>
      </header>
      {/* header end */}
      {/* delivery option section start */}
      <section className="top-space px-15 pt-0">
        <div className="delivery-option-section">
          <ul>
          {comp}
          </ul>
          <a href="/new-address" className="btn btn-outline text-capitalize w-100 mt-3"> Tambah Alamat Baru </a>
          <a href="/cart/shop" className="btn btn-outline text-capitalize w-100 mt-3"> Gunakan Alamat Sekarang </a>
        </div>
      </section>
      {/* delivery option section end */}

      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Caddress;
