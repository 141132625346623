import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
// import swal from 'sweetalert';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import API from '../../../services';

class Bottompanel extends Component {
  constructor(props) {
    super(props);
    this.state = { type: 0, load: false, droppoint: [] };
    this.drop = [];
  }

  componentDidMount() {

  }

  render() {
    return (
      <>
       <div className="cart-bottom">
          <div>
            <div className="left-content">
              <h1>{API.formatNumber(this.props.total)}</h1>
              {/* <a href="#order-details" className="theme-color">View details</a> */}
            </div>
            <a onClick={() => this.props.checkout()} className="btn btn-solid">Place Order</a>
          </div>
        </div>
        
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Bottompanel;
