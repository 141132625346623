import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
// import swal from 'sweetalert';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import API from '../../../services';

class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = { data:[],load: false };
    this.drop = [];
  }

  componentDidMount() {
    this.load_payment();
  }

  load_payment = async() => {
    this.setState({ load:false });
    axios.get(API.url+'payment',{ headers: {'X-Auth-Token': localStorage.getItem('token')}})
      .then((response) => {
          this.setState({ data: response.data.content.result,load:true });
          // console.log(response.data.content.result);
      })
      .catch((error) => { }); 
   }

   set_payment = async(event) => {
    if (event.target.value !== '0'){
      let uid = parseInt(event.target.value);
      axios.get(API.url+'payment/get/'+uid,{ headers: {'X-Auth-Token': localStorage.getItem('token')}})
      .then((response) => {
          let result = response.data.content;
          // console.log(result);
          this.props.setcost(parseFloat(result.cost),parseInt(result.add_cost),result.cash,uid);
          // this.setState({ address: result.code+" - "+result.address+" - "+result.phone });
          // this.props.setoutlet(uid,result.code);
      })
      .catch((error) => { 
      }); 
    }else{ this.setState({ address: null });  this.props.setoutlet(null,null);} 
  }

  render() {
    let comp;
    // console.log(this.props.dataprops.droppoint.length);
    if (this.state.data !== undefined) {
      comp = this.state.data.map((dynamicData, index) => (
        <Fragment key={index}>
          <option value={dynamicData.id}> {dynamicData.name} </option>
        </Fragment>
      ));
    }
    return (
      <>
        {/* coupon section */}
        <section className="px-15 pt-0">
          <h2 className="title">Jenis Pembayaran:</h2>
          <div className="coupon-section">
          <select onChange={this.set_payment} className="form-control">
              <option value={0}> -- Pilih Jenis Pembayaran -- </option>
              {comp}
            </select>
          </div>
          <h4>
          </h4>
        </section>
       
        <div className="divider" />
        {/* coupon end */}
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Payment;
