import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
// import swal from 'sweetalert';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import API from '../../../services';

class Coupon extends Component {
  constructor(props) {
    super(props);
    this.state = { type: 0, load: false, droppoint: [] };
    this.drop = [];
  }

  componentDidMount() {

  }

  render() {
    let comp;
    if (localStorage.getItem('voucher') !== null){
      comp = <a className="theme-color" onClick={() => this.props.removevoucher()}> Hapus Voucher </a>;
    }

    return (
      <>
        {/* coupon section */}
        <section className="px-15 pt-0">
          <h2 className="title">Coupons:</h2>
          <div className="coupon-section">
            <i className="iconly-Discount icli icon-discount" />
            <input className="form-control form-theme" placeholder="Apply Coupons" value={this.props.data} />
            <a href="/voucher/cart">
              <i className="iconly-Arrow-Right-2 icli icon-right" />
            </a>
          </div>
          <h4>
          {comp}
          </h4>
        </section>
        <div className="divider" />
        {/* coupon end */}
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Coupon;
