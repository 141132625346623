import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
// import swal from 'sweetalert';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import API from '../../../services';

class Prooutlet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: null,
      data:null
    };
  }

  componentDidMount() {
    this.load_droppoint();
  }

  load_droppoint = async(event) => {
      
      axios.get(API.url+'droppoint/',{ headers: {'X-Auth-Token': localStorage.getItem('token')}})
      .then((response) => {
          // console.log('Droppoint : ',result);
          this.setState({ data:response.data.content.result});
      })
      .catch((error) => { 
      }); 
  }

  set_add = async(event) => {
    if (event.target.value !== '0'){
      let uid = parseInt(event.target.value);
      axios.get(API.url+'droppoint/get/'+uid,{ headers: {'X-Auth-Token': localStorage.getItem('token')}})
      .then((response) => {
          let result = response.data.content;
          // this.setState({ address: result.code+" - "+result.address+" - "+result.phone });
          // this.props.setoutlet(uid,result.code);

          this.setState({ address: result.code+" - "+result.address+" - "+result.phone }, function() {
           this.props.setoutlet(this.state.address);
          });

      })
      .catch((error) => { 
      }); 
    }else{ this.setState({ address: null });  this.props.setoutlet(null,null);} 
  }

  render() {
    let comp;
    // console.log(this.state.address);
    if (this.state.data !== null) {
      comp = this.state.data.map((dynamicData, index) => (
        <Fragment key={index}>
          <option value={dynamicData.id}> {dynamicData.code} </option>
        </Fragment>
      ));
    }
    return (
      <>
            <select onChange={this.set_add} id="coutlet" className="form-control">
              {comp}
            </select>
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Prooutlet;
