import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
// import swal from 'sweetalert';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
// import db from '../../services/db';
import API from '../../../services';
import 'react-toastify/dist/ReactToastify.css';
// import style from '../../../public/assets/css/style.css';
// import ActionType from '../../redux/reducer/globalActionType';

class Orderstatus extends Component {
  constructor(props) {
    super(props);
    this.state = { data: null, items: null, item: [], load: false };
  }

  componentDidMount() {
    // this.token_otentikasi();
    this.load_trans();
  }

  load_trans = async() => {
    // console.log('Data Props : ',this.props.dataprops.code);
    axios.get(API.url+'pos/get_trans/'+this.props.dataprops.code,{ headers: {'X-Auth-Token': localStorage.getItem('token')}})
    .then((response) => {
        // console.log("Hasil data load trans : ",response.data.content.items); 
        this.setState({
          items:response.data.content.items,
          data:response.data.content.details
        },() => {
            // this.get_payment(result.payment_type);
        });
        
    })
    .catch((error) => { 
      // this.setState({ page404:true })
      // console.log("Error : ", error.response.data.error);
    }); 
      // axios
  }

  render() {
    // console.log('Data Props : ',this.props.dataprops);
    let data = this.props.dataprops;
    let status= <h6> - </h6>;

    if (data.canceled !== null ){ status = <h6> Dibatalkan </h6>}
    else{
      if (data.canceled == null && data.booked == '0' && data.cust_received == null  ){ status = <h6> Diproses </h6>}
      else if (data.canceled == null && data.booked == "1" && data.cust_received == null ){ status = <h6> Dikirim </h6>}
      else if (data.canceled == null && data.cust_received != null && data.booked == "1" ){ status = <h6> Diterima </h6>} 
    }
    
    return (
      <>
        {status}
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Orderstatus;
