/* eslint-disable react/prefer-stateless-function */
import React, { Component,Fragment } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios';
import API from '../../../services';
import { Carousel } from 'react-bootstrap';

export class slider extends Component {

   constructor(props) {
    super(props);
    // eslint-disable-next-line react/no-unused-state
    this.state = { data: [], load: false };
  }

  componentDidMount() {
    // this.token_otentikasi();
    //  console.log(API.url);
    this.load_slider();
  }

   load_slider = () => {
        this.setState({ load:true });
        axios.get(API.url+'slider')
        .then((response) => { 
            this.setState({ data: response.data.content.result,load:false });
            // console.log('slider : ',response.data.content.result);
        })
        .catch((err) => { console.log("Error load category : ", err.response.statusText+' - '+err.response.status);
          //  API.otentikasi_acl('product'); API.post_error_db(this.constructor.name.toLowerCase(),err.response.statusText,err.response.status);
         }); 
        
    }

  render() {
    return (
      <div>
        <section className="pt-0 home-section ratio_55">
          <div className="home-slider slick-default theme-dots">

            <Carousel controls={false}>
{
        
        this.state.data.map((dynamicData,index) => {
            return (
<Carousel.Item key={index}>
  <div>
    <div className="slider-box">
       <a href={dynamicData.url}> <img src={dynamicData.image} className="img-fluid bg-img" alt="" /> </a>
      <div className="slider-content">
        <div>
          {/* <h1>{dynamicData.name}</h1> */}
          {/* <h6>Free Shipping Till Mid Night</h6> */}
          {/* <a href={dynamicData.url} className="btn btn-solid">SHOP NOW</a> */}
        </div>
      </div>
    </div>
  </div>
</Carousel.Item>              
            );
        })

}
            </Carousel>
          </div>
        </section>
      </div>
    );
  }
}

export default slider;
