import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
// import swal from 'sweetalert';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import API from '../../../services';

class Prooutlet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: null
    };
  }

  componentDidMount() {
    // this.load_droppoint();
  }

  set_add = async(event) => {
    if (event.target.value !== '0'){
      let uid = parseInt(event.target.value);
      axios.get(API.url+'droppoint/get/'+uid,{ headers: {'X-Auth-Token': localStorage.getItem('token')}})
      .then((response) => {
          let result = response.data.content;
          this.setState({ address: result.code+" - "+result.address+" - "+result.phone });
          this.props.setoutlet(uid,result.code);
      })
      .catch((error) => { 
      }); 
    }else{ this.setState({ address: null });  this.props.setoutlet(null,null);} 
  }

  render() {
    let comp;
    // console.log(this.props.dataprops.droppoint.length);
    if (this.props.dataprops.droppoint !== undefined) {
      comp = this.props.dataprops.droppoint.map((dynamicData, index) => (
        <Fragment key={index}>
          <option value={dynamicData.id}> {dynamicData.code} </option>
        </Fragment>
      ));
    }
    return (
      <>
        <div className="divider" />
        <div className="product-detail-box px-15">
          <div className="size-detail">
            <h3 className="size-title"> Outlet : </h3>
            <select onChange={this.set_add} className="form-control">
              <option value={0}> -- Pilih Outlet -- </option>
              {comp}
            </select>
            <br />
            <h4> {this.state.address} </h4>
          </div>
        </div>
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Prooutlet;
