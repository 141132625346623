import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import API from '../../services';
// import swal from 'sweetalert';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Meta from '../components/meta';

// import ActionType from '../../redux/reducer/globalActionType';

class Forgot extends Component {

    constructor(props){
      super(props);
      this.state = { loggedin:false, displaypass:false, displayotp:false, displayotplink:false, load:false }
      localStorage.removeItem('token');
    }

    componentDidMount(){
       // this.token_otentikasi();
      //  console.log(API.url);
    }

    handleInput=(e)=>{
      if (e.key === 'Enter'){ this.login(); }
    }

    counter_link = async() =>{
       setTimeout(
         () => this.setState({ displayotplink: true }), 
         30000
       );
    }

    req_otp = async() => {
      let user = document.getElementById("username").value;
      let nilai = {username: user};

      if (user !== ""){
          this.setState({ load:false, displayotplink:false })
          axios.post(API.url+'customer/req_otp',nilai)
            .then((response) => {
                // console.log(response.data.content); 
                this.setState({ displaypass:true, displayotp:true })
                this.counter_link();

                 toast.success(response.data.content,
                {position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 2000,
                hideProgressBar: false});
                // alert('login berhasil');
            })
            .catch((error) => { 
                // error.response.status
                // API.post_error_db(this.constructor.name.toLowerCase(),error.response.data.error,error.response.status);
                
                 toast.error(error.response.data.error,
                {position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 2000,
                hideProgressBar: false});

                // console.log("INi error : ",error.response.data.error);
                this.setState({ load:true })
                $("#username").focus();
            }); 

      }else{ toast.error('username required',
                {position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 2000,
                hideProgressBar: false});
             $("#username").focus(); 
          }
    }

    set_password = async() =>{
        
      let user = document.getElementById("username").value;
      let pass = document.getElementById("password").value;
      let otp = document.getElementById("otp").value;
      let nilai = {username: user,new_password: pass,otp:otp};

      if (user !== "" && pass !== "" && otp !== ""){
          this.setState({ load:false })
          axios.post(API.url+'customer/forgot',nilai)
            .then((response) => {
                // console.log(response.data.content); 
                toast.success(response.data.content,
                {position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 2000,
                hideProgressBar: false});

                this.setState({ loggedin:true })
                // alert('login berhasil');
            })
            .catch((error) => { 
                // error.response.status
                // API.post_error_db(this.constructor.name.toLowerCase(),error.response.data.error,error.response.status);
                
                 toast.error(error.response.data.error,
                {position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 2000,
                hideProgressBar: false});

                // console.log("INi error : ",error.response.data.error);
                this.setState({ load:true })
                $("#username").focus();
            }); 

      }else{ toast.error('username, password & otp required',
                {position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 2000,
                hideProgressBar: false});
             $("#username").focus(); 
          }
    }

  render() {

     let comp; let button; let otp; let otplink;
     if (this.state.displaypass === true){ 
       comp = <div className="form-floating mb-2">
              <input type="password" className="form-control" id="password" onKeyUp={this.handleInput} placeholder="password" />
              <label htmlFor="two"> New Password</label>
            </div>;
       button = <a onClick={this.set_password} className="btn btn-solid w-100"> Set Password </a>;
     }else{
       button = <a onClick={this.req_otp} className="btn btn-solid w-100"> Send OTP </a>;
     }

     if (this.state.displayotplink === true){
        otplink = <h4> Not received otp code? <br/> <a onClick={this.req_otp} style={{color: 'red'}}> Request again. </a> </h4>;
     }

     if (this.state.displayotp === true){
       otp =  <div className="form-floating mb-2">
              <input type="number" className="form-control" id="otp" placeholder="otp" />
              <label htmlFor="two"> OTP </label>
            </div>;
     }

    if (this.state.loggedin === true){ return <Redirect to='/login'></Redirect>}

    return (
      <>
       <Meta/>
        {/* top design start */}
        <img src="assets/images/design.svg" className="img-fluid design-top" alt="" />
        {/* top design end */}
        {/* top bar start */}
        <div className="topbar-section">
          <a href="index.html"><img src="/assets/images/logo.png" className="img-fluid main-logo" alt="" />
            <img src="/assets/images/logo-white.png" className="img-fluid white-logo" alt="" /></a>
        </div>
        {/* top bar end */}
        {/* login section start */}
        <section className="form-section px-15 top-space section-b-space">
          <h1> Forgot Password </h1>
          <form>
            <div className="form-floating mb-4">
              <input type="text" className="form-control" id="username" onKeyUp={this.handleInput} placeholder="Email" />
              <label htmlFor="one">Email / Phone</label>
            </div>
            {/* <div className="form-floating mb-2">
              <input type="password" className="form-control" id="password" onKeyUp={this.handleInput} placeholder="password" />
              <label htmlFor="two"> New Password</label>
            </div> */}
            {comp}
            {otp}
            {/* <a onClick={this.login} className="btn btn-solid w-100"> Send OTP </a> */}
            {button}
          </form>
        
          <div className="bottom-detail text-center mt-3">
             <h4 className="content-color">Back to &nbsp;
                <a className="title-color text-decoration-underline" href="/login">Sign In</a>
             </h4>
             {otplink}
          </div>
        </section>
        {/* login section end */}
        {/* panel space start */}
        <section className="panel-space" />
        {/* panel space end */}
      </>
    );
  }
}

export default Forgot;
