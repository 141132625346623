import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import API from '../../../services';
// import swal from 'sweetalert';
import { useParams } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
// import style from '../../../public/assets/css/style.css';

// import ActionType from '../../redux/reducer/globalActionType';

class Searching extends Component {

    constructor(props){
      super(props);
      this.state = { data:[], load:false, loggedin:true, foraddress:null, address: '', coor:"none" }
    }

    componentDidMount(){
      this.token_otentikasi();
    }

    token_otentikasi = async() => { 
      const stts = await API.otentikasi();
      if (stts === false){ this.setState({ loggedin:false }) }
      else if(stts === true) {
        // const decode = await API.decode();
      //  this.load_address();
      }
   }

   handleChange = address => {
      this.setState({ address });
   };
  
    handleSelect = address => {
        // console.log('Address : ',this.state.address);
        geocodeByAddress(address)
        .then(results => {
        //   console.log('results', results[0].formatted_address);
          this.get_coor(results[0].formatted_address);
        })
    };

    get_coor = async(add) => {
        // console.log('Address : ',add);
        axios.get("https://maps.googleapis.com/maps/api/geocode/json?address="+add+"&key=AIzaSyBqKPUcKxKOgOfSby8WB1lTsahkRjt0Qek")
        .then((response) => { 
            // console.log("response : ",response.data.results[0].geometry.location);
            let location = response.data.results[0].geometry.location;
            this.setState({ coor: location.lat+","+location.lng }, function() {
                this.props.setcor(this.state.coor);
            });
            this.setState({ foraddress: add }, function() {
                this.props.setadd(this.state.foraddress);
            });
            
        })
        .catch((err) => { 
          console.log("Error map : ", err);
          //  API.otentikasi_acl('product'); API.post_error_db(this.constructor.name.toLowerCase(),err.response.statusText,err.response.status);
         }); 

        //  this.props.setcor('coordinate');
        
    }


  render() {
    if (this.state.loggedin === false){ return <Redirect to='/login'></Redirect>}
    // console.log("For address : ",this.state.foraddress);
    // console.log("Coordinate : ",this.state.coor);
    
    return (
      <>
       <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: 'Search Places ...',
                className: 'location-search-input',
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Searching;
