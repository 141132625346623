import React, { Component, Fragment } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import API from '../../services';
import db from '../../services/db';

toast.configure();

export default class Cartqty extends Component {


  constructor(props){
    super(props);
    this.state = { userid:null,qty:null }
  }

  componentDidMount() {
    this.token_otentikasi();
  }

  token_otentikasi = async() => { 
    const stts = await API.otentikasi();
    if(stts === true) {
      const decode = await API.decode();
      // console.log("Hasil decode : ",decode);
      this.setState({ userid:decode.userid},() => { this.get_property();});
    }
 }

  get_property = async event =>{
    let result = await db.posts.where('user').equalsIgnoreCase(this.state.userid).toArray();
    if (result.length !== 0){ this.setState({ qty: result.length });}
  }

  render() {
    // console.log('Page Back : ',this.props.page);
    return (
      <li>
        <a href={`/cart/${this.props.page}`}><i className="iconly-Buy icli" /> <sub className="theme-color">{this.state.qty}</sub> </a>
      </li>
    );
  }
}
