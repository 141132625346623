import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import API from '../../../services';
// import swal from 'sweetalert';
import { useParams } from "react-router-dom";
import axios from 'axios';

class Filter extends Component {

    constructor(props){
      super(props);
      this.state = { type:0,load:false,droppoint:[] }
      this.drop = [];
    }

    componentDidMount(){
      this.load_droppoint();
    }

    load_droppoint = async() => {
      this.setState({ load:false });
      let nilai = {category: "",publish: "1", recommend:"", bestseller:"", latest:"", economic:"", limit:this.limit, offset:this.offset, orderby:this.state.orderby, order:this.state.order};
      axios.get(API.url+'droppoint')
        .then((response) => {
            this.setState({ droppoint: response.data.content.result });
            // this.drop = response.data.content.result;
            response.data.content.result.map((dynamicData) => {this.drop.push(parseInt(dynamicData.id));})
        })
        .catch((error) => { 
            // error.response.status
            // API.post_error_db(this.constructor.name.toLowerCase(),error.response.data.error,error.response.status);
            // swal(error.response.data.error, "", "error");
            // console.log(error);
        }); 
    }

    handleChange = event => {
        this.setState({type:event.target.value });
        // console.log("Selected : ",event.target.value);
    };

    cekcekbox=e=>{
      // console.log(e.target.value);
      const checked = e.target.checked;
        if (checked) {
          if (this.drop.includes(parseInt(e.target.value)) !== true){ this.drop.push(parseInt(e.target.value)); }
        //checked
        
        }else {
          //unchecked
          // alert('not checked');

          const index = this.drop.indexOf(parseInt(e.target.value));
          if (index > -1) {
            this.drop.splice(index, 1); // 2nd parameter means remove one item only
          }
        }
        this.drop = this.drop.sort();
        // console.log("Isi array : ",this.drop);
    }

  render() {
    return (
      <>

        {/*  filter modal start  */}
      <div className="">
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header">
              <h2>Filters</h2>
            </div>
            <div className="modal-body">
              <div className="filter-box">
                <h2 className="filter-title">Urutkan Berdasarkan :</h2>
                <div className="filter-content">
                  <select defaultValue={0} className="form-select form-control form-theme" onChange={this.handleChange}>
                    {/* <option selected>Recommended</option> */}
                    <option value={0}>Recommended</option>
                    <option value={1}>Produk Terbaru</option>
                    <option value={2}>Produk Terlaris</option>
                    <option value={3}>Harga: Tinggi ke Rendah</option>
                    <option value={4}>Harga: Rendah ke Tinggi</option>
                    <option value={5}>Rating</option>
                  </select>
                </div>
              </div> <br />

              <div className="filter-box">
                <h2 className="filter-title"> Outlet :</h2>
                <div className="filter-content">
                  
          <div className="checkbox_animated">
          {/* batas checkbox */}
  {
    this.state.droppoint.map((dynamicData,index) => {
            return (
            <div key={index} className="d-flex align-items-center mb-2">
<input type="checkbox" id={'checkbox'+dynamicData.id} defaultValue={dynamicData.id} defaultChecked onClick={(e) => {this.cekcekbox(e);}} />
{/* <input type="checkbox" id={'checkbox'+dynamicData.id} /> */}
              <label htmlFor={'checkbox'+dynamicData.id}>{dynamicData.code} - {API.capitalize(dynamicData.name)}</label>
            </div>            
            );
        })
  }
            
            {/* <div className="d-flex align-items-center mb-2">
              <input type="checkbox" name="address" id="onecheckbox2" defaultValue="male" />
              <label htmlFor="onecheckbox2">Checkbox Option 2</label>
            </div> */}

            {/* <div className="d-flex align-items-center mb-2">
              <input type="checkbox" name="address" id="onecheckbox2" defaultValue="male" />
              <label htmlFor="onecheckbox2">Checkbox Option 2</label>
            </div> */}

          {/* batas checkbox */}
          </div>
        
                </div>
              </div>

            </div>
            <div className="modal-footer">
              {/* <button className="reset-link" data-bs-dismiss="modal" onClick={() => this.props.action(this.state.type)} >RESET</button> */}
              <a className="btn btn-solid" data-bs-dismiss="modal" onClick={() => this.props.action(this.state.type,this.drop.toString())}>apply filters</a>
            </div>
          </div>
        </div>
      </div>
      {/* filter modal end */}

      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Filter;
