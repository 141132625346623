/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import Cartqty from '../../components/cartqty';
import proimg from '../../../assets/images/information.png';

// eslint-disable-next-line react/prefer-stateless-function
class Productheader extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    // console.log('Shop header : ', this.props.title);
  }

  render() {
    return (
      <div>
        <header>
          <div className="back-links">
            {/* <button onClick={() => this.props.link()}> */}
            <a onClick={() => this.props.link()}>
              <i className="iconly-Arrow-Left icli" />
              <div className="content">
                <h2>{this.props.title}</h2>
              </div>
            </a>
            {/* </button> */}
          </div>
          <div className="header-option">
            <ul>
              <li>
                <a href="/shop"><img src={proimg} className="img-fluid" alt="" /></a>
              </li>
              {/* <li>
                <a href="#"><img src="/assets/svg/share-2.svg" className="img-fluid" alt="" /></a>
              </li> */}
              <li>
                <a href="/wishlist/shop"><i className="iconly-Heart icli" /></a>
              </li>
             <Cartqty/>
            </ul>
          </div>
        </header>
        <br />
      </div>
    );
  }
}

export default Productheader;
