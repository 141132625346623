/* eslint-disable react/prefer-stateless-function */
import React, { Component,Fragment } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios';
import API from '../../../services';

export class Search extends Component {

   constructor(props) {
    super(props);
    // eslint-disable-next-line react/no-unused-state
    this.state = { data: [], load: false };
  }

  componentDidMount() {
    // this.token_otentikasi();
    //  console.log(API.url);
  }

  render() {
    return (
      <div className="search-panel top-space px-15">
        <div className="search-bar">
          <input className="form-control form-theme" placeholder="Search" onChange={this.props.action} />
          <i className="iconly-Search icli search-icon" />
          <i className="iconly-Camera icli camera-icon" />
        </div>
        <div className="filter-btn" onClick={() => this.props.toogle()}>
          <i className="iconly-Filter icli" />
        </div>
      </div>
    );
  }
}

export default Search;
