import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import API from '../../services';
import db from '../../services/db';
// import swal from 'sweetalert';
import { useParams } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Orderdetail from "./comp/orderdetail";
import Orderstatus from "./comp/orderstatus";
import qs from 'qs';
import Meta from '../components/meta';

class Orderhistory extends Component {

    constructor(props){
      super(props);
      this.state = { 
        datapast:null, item:[], load:false, loggedin:true, sku:null, salescode:null,
        datalength:0, loadmore:false, filter:false
       }
      this.offset = 0;
      this.limit = 10;
    }

    componentDidMount(){
      this.token_otentikasi();
    }

    token_otentikasi = async() => { 
      const stts = await API.otentikasi();
      if (stts === false){ this.setState({ loggedin:false }) }
      else if(stts === true) {
        // const decode = await API.decode();
        this.load_trans();
      }
    }

    load_hide_button = () =>{
      if (this.state.datapast.length < this.state.datalength){
       this.setState({ loadmore: true }); 
     }else if (this.state.datapast.length === this.state.datalength){ this.setState({ loadmore: false });  }
   }

    load_trans = async() => {
      // console.log(this.props.match.params.orderid);
      let nilai = {start: "", end:"", status:"1", cancel:"", redeem:"", booked:"", pickup:"", limit:this.limit, offset:this.offset};
      axios.post(API.url+'pos',nilai,{ headers: {'X-Auth-Token': localStorage.getItem('token')}})
      .then((response) => {
          // console.log("Hasil data load trans : ",response.data.content); 
          this.setState({
            datapast:response.data.content.result,
            datalength:response.data.content.record
          },() => {
              // this.get_payment(result.payment_type);
              this.load_hide_button();
          });
          
      })
      .catch((error) => { 
        // this.setState({ page404:true })
        // console.log("Error : ", error.response.data.error);
      }); 
        // axios
    }

    loadMoreData = async () => {
      //On click of Load More button We will call the web API again
      this.setState({ load:false });
      this.offset = this.offset + this.limit;
      let nilai = {start: "", end:"", status:"1", cancel:"", redeem:"", booked:"", pickup:"", limit:this.limit, offset:this.offset};
      
       axios.post(API.url+'pos',nilai,{ headers: {'X-Auth-Token': localStorage.getItem('token')}})
        .then(response => {
          // console.log('offset : ',this.offset);
          // console.log('data : ',response.data.content.result);
          this.setState({
            datapast: [...this.state.datapast, ...response.data.content.result],
          });
          this.load_hide_button();
        })
        .catch(error => {
          console.log('ini error : ', error);
          // Toast.show('Error fetching data..!');
        });
    };

    set_pid_comment = async(sku,code) => {
      // console.log('Hasil sku : ',sku);
      // console.log('Hasil code : ',code);
      this.setState({ sku:sku,salescode:code })
    }

    post_comment = async() => {
      let rating = document.getElementById("crating").value;
      let comment = document.getElementById("tcomments").value;
      let requestBody = {tsku: this.state.sku,tcode: this.state.salescode, tcomment:comment, trating: rating}
      // console.log('Req Body : ',requestBody);

      this.setState({ load:false })
      axios.post(API.url+'procomment/post',qs.stringify(requestBody),{ headers: {'X-Auth-Token': localStorage.getItem('token')}})
        .then((response) => {
            // console.log(response.data.content); 
            var rate= document.getElementById("crating");
            var comm= document.getElementById("tcomments");
            rate.value = "1"; comm.value="";

            toast.success("Post Comment Berhasil",
                {position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 2000,
                hideProgressBar: false});
        })
        .catch((error) => { 
          // console.log("Error : ", error.response.data.error);
          toast.error(error.response.data.error,
                {position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 2000,
                hideProgressBar: false});
        }); 
          // axios
    }

    texthandle = event => {
      this.offset = 0;
      this.setState({datapast:[],load:false, loadmore:false});
      let param = {search:event.target.value};
      // console.log(param);
    
      if (event.target.value){
          // console.log(event.target.value);
          axios.post(API.url+'pos/search',param, { headers: { 'X-Auth-Token': localStorage.getItem('token') } })
          .then((response) => { 
              if (response.data.content.result != null){
                  // console.log(response.data.content.result);
                  this.setState({datapast: response.data.content.result,load:true }); 
                  // $('#myTable').DataTable(); 
              }
          }).catch((err) => { 
            console.log("Error texthandle : ", err); 
            // API.post_error_db(this.constructor.name.toLowerCase(),err.response.statusText,err.response.status); API.otentikasi_acl('product search'); 
            }); 
      }else{ this.reset_filtering(); }
  }

  filtering = async() => {
    
    this.setState({ datapast:[] });
    let filter = document.querySelector('input[name="filter"]:checked').value;
    let time = document.querySelector('input[name="time"]:checked').value;
    // console.log("hasil time : ",time);

    let nilai = {type: filter, period:time};
    axios.post(API.url+'pos/filtering',nilai,{ headers: {'X-Auth-Token': localStorage.getItem('token')}})
    .then((response) => {
        // console.log("Hasil data load trans : ",response.data.content); 
        this.setState({
          datapast:response.data.content.result,
          datalength:response.data.content.record,
          filter:true
        },() => {
            // this.get_payment(result.payment_type);
            // this.load_hide_button();
        });
        
    })
    .catch((error) => { 
      // this.setState({ page404:true })
      // console.log("Error : ", error.response.data.error);
    }); 
      // axios
  }

  reset_filtering = async() => {
     this.setState({
          datapast:[],
          datalength:0,
          filter:false
        },() => {
          this.load_trans();
        });
  }

  render() {    
    // console.log("isi pid : ", this.state.pid);
   let comppast; let comploadmore; let titleorder;

    if (this.state.filter === false){
    }else{ titleorder = <h2 className="page-title">Filtering Transaksi</h2>; }

    if (this.state.loadmore === true) {
      comploadmore = (
        <div className="loadmorebtnorderlist" onClick={() => this.loadMoreData()}>
          <p> Tampilkan Lebih Banyak </p>
        </div>
      );
    }

    if (this.state.datapast !== null){
      comppast = this.state.datapast.map((dynamicData, index) => (

        <Fragment key={index}>
         <li>
              <div className="order-box">
              <Orderdetail 
                dataprops={dynamicData} 
                set_pid_comment={this.set_pid_comment}
              />

                <div className="delivery-status">
                  <div className="d-flex">
                   <div>
                      <h6 className="content-color">No Pesanan:</h6>
                      <h6> {dynamicData.code} </h6>
                    </div>
                    <div>
                      <h6 className="content-color">Tanggal:</h6>
                      <h6> {dynamicData.dates} </h6>
                    </div>
                    <div>
                      <h6 className="content-color">Total:</h6>
                      <h6>{API.formatNumber(dynamicData.amount)}</h6>
                    </div>
                    <div>
                      <h6 className="content-color">Status:</h6>
                      <Orderstatus dataprops={dynamicData}/>
                    </div>
                  </div> 
                </div>
              </div>
              {/* <div className="rate-section">
                <ul>
                  <li data-bs-toggle="offcanvas" data-bs-target="#offcanvasreview"><i className="iconly-Star icli" /> Rate &amp; Review Product</li>
                </ul>
              </div> */}
            </li>
        </Fragment>
      ));
    }

    if (this.state.loggedin === false){ return <Redirect to='/login'></Redirect>}
    // console.log("Data past : ",this.state.datapast);
    
    return (
      <>
      <Meta/>
       {/* header start */}
       <header>
          <div className="back-links">
            <a href="/profile">
              <i className="iconly-Arrow-Left icli" />
              <div className="content">
                <h2> History Transaksi</h2>
              </div>
            </a>
          </div>
        </header>
        {/* header end */}
        {/* search panel start */}
        <div className="search-panel top-space px-15">
          <div className="search-bar">
            <input className="form-control form-theme" placeholder="Search" onChange={this.texthandle} />
            <i className="iconly-Search icli search-icon" />
            <i className="iconly-Camera icli camera-icon" />
          </div>
          <div className="filter-btn" data-bs-toggle="offcanvas" data-bs-target="#offcanvasfilter">
            <i className="iconly-Filter icli" />
          </div>
        </div>
        {/* search panel end */}

        {/* section start */}
        <div className="divider" />
        <section className="section-b-space pt-0 px-15">
          {/* <h2 className="page-title">Past Orders</h2> */}
          {titleorder}
          <ul className="order-listing">

          {comppast} 

            {/* <li>
              <div className="order-box">
                <div className="d-flex align-items-center">
                  <img src="assets/images/products/3.jpg" className="img-fluid order-img" alt="" />
                  <div className="media-body">
                    <h4>Pink Hoodie t-shirt full </h4>
                    <h5 className="content-color my-1">Size: S, Qty: 1</h5>
                    <a className="theme-color" href="#">View Details</a>
                  </div>
                  <span className="status-label bg-theme text-white">delivered</span>
                </div>
                <div className="delivery-status">
                  <div className="d-flex">
                    <div>
                      <h6 className="content-color">Ordered:</h6>
                      <h6>26th May, 2021</h6>
                    </div>
                    <div>
                      <h6 className="content-color">Delivery Status:</h6>
                      <h6>Dispatched</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rate-section">
                <ul>
                  <li data-bs-toggle="offcanvas" data-bs-target="#offcanvasreview"><i className="iconly-Star icli" /> Rate &amp; Review Product</li>
                  <li><i className="iconly-Star icli" /> Need Help?</li>
                </ul>
              </div>
            </li> */}


            {/* <li>
              <div className="order-box">
                <div className="d-flex align-items-center">
                  <img src="assets/images/products/4.jpg" className="img-fluid order-img" alt="" />
                  <div className="media-body">
                    <h4>Pink Hoodie t-shirt full </h4>
                    <h5 className="content-color my-1">Size: S, Qty: 1</h5>
                    <a className="theme-color" href="#">View Details</a>
                  </div>
                  <span className="status-label bg-theme text-white">delivered</span>
                </div>
                <div className="delivery-status">
                  <div className="d-flex">
                    <div>
                      <h6 className="content-color">Ordered:</h6>
                      <h6>26th May, 2021</h6>
                    </div>
                    <div>
                      <h6 className="content-color">Delivery Status:</h6>
                      <h6>Dispatched</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rate-section">
                <ul>
                  <li data-bs-toggle="offcanvas" data-bs-target="#offcanvasreview"><i className="iconly-Star icli" /> Rate &amp; Review Product</li>
                  <li><i className="iconly-Star icli" /> Need Help?</li>
                </ul>
              </div>
            </li> */}
          </ul>
        </section>
        {comploadmore}
        {/* section end */}
        {/* add review canvas start */}
        <div className="offcanvas offcanvas-bottom h-auto" tabIndex={-1} id="offcanvasreview">
          <div className="offcanvas-body">
            <h2 className="mb-2">Write Review</h2>
            <div className="d-flex align-items-center">
              <h4 className="content-color me-2">Your rating:</h4>
              <select id="crating" className="form-control" style={{width: '15%'}}>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
              </select>
            </div>
            <h4 className="content-color mt-2 mb-2">Review:</h4>
            <form className="mb-4 section-b-space">
              <textarea id="tcomments" rows={4} className="form-control" />
            </form>
            <div className="cart-bottom row m-0">
              <div>
                <div className="left-content col-5">
                  <a href="#" className="title-color">BACK</a>
                </div>
                <a data-bs-dismiss="offcanvas" onClick={() => this.post_comment()} className="btn btn-solid col-7 text-uppercase">Submit</a>
              </div>
            </div>
          </div>
        </div>
        {/* add review canvas end */}
        {/* add review canvas start */}
        <div className="offcanvas offcanvas-bottom h-auto" tabIndex={-1} id="offcanvasfilter">
          <div className="offcanvas-body">
            <h2 className="mb-2 mt-cls">Filter Jenis Transaksi</h2>
            <form className="mb-2">
              <div className="me-3 d-flex align-items-center mb-2">
                <input defaultChecked className="radio_animated" type="radio" name="filter" id="all" defaultValue="4" />
                <label htmlFor="all" className="content-color"> Semua Transaksi </label>
              </div>
              <div className="me-3 d-flex align-items-center mb-2">
                <input className="radio_animated" type="radio" name="filter" id="open" defaultValue="0" />
                <label htmlFor="open" className="content-color"> Transaksi Berlangsung </label>
              </div>
              <div className="d-flex align-items-center mb-2">
                <input className="radio_animated" type="radio" name="filter" id="return" defaultValue="1" />
                <label htmlFor="return" className="content-color"> Transaksi Berhasil </label>
              </div>
              <div className="d-flex align-items-center mb-2">
                <input className="radio_animated" type="radio" name="filter" id="cancelled" defaultValue="2" />
                <label htmlFor="cancelled" className="content-color"> Transaksi Batal </label>
              </div>
            </form>
            <h2 className="mb-2"> Filter Berdasarkan Waktu </h2>
            <form className="section-b-space mb-3">
              <div className="me-3 d-flex align-items-center mb-2">
                <input defaultChecked className="radio_animated" type="radio" name="time" id="last30" defaultValue="0" />
                <label htmlFor="last30" className="content-color"> 30 Hari Terakhir </label>
              </div>
              <div className="me-3 d-flex align-items-center mb-2">
                <input className="radio_animated" type="radio" name="time" id="last6" defaultValue="1" />
                <label htmlFor="last6" className="content-color"> 6 Bulan Terakhir </label>
              </div>
              <div className="d-flex align-items-center mb-2">
                <input className="radio_animated" type="radio" name="time" id={2021} defaultValue="2" />
                <label htmlFor={2021} className="content-color"> Tahun Sekarang </label>
              </div>
              <div className="d-flex align-items-center mb-2">
                <input className="radio_animated" type="radio" name="time" id={2020} defaultValue="3" />
                <label htmlFor={2020} className="content-color"> Tahun Lalu </label>
              </div>
            </form>
            <div className="cart-bottom row m-0">
              <div>
                <div className="left-content col-5">
                  <a data-bs-dismiss="offcanvas" onClick={() => this.reset_filtering()} className="title-color">Batal Filtering</a>
                </div>
                <a data-bs-dismiss="offcanvas" onClick={() => this.filtering()} className="btn btn-solid col-7 text-uppercase">Terapkan</a>
              </div>
            </div>
          </div>
        </div>
        {/* add review canvas end */}
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Orderhistory;
