import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
// import swal from 'sweetalert';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import API from '../../../services';

class Prodetails extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // this.load_droppoint();
  }

  render() {
    let str;
    if (this.props.dataprops.description !== undefined) {
       str = this.props.dataprops.description.replace('\\"', '"');
    }

    return (
      <>
        <div className="divider" />
        <div className="product-detail-box px-15">
          <div dangerouslySetInnerHTML={{__html: str}} />
        </div>
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Prodetails;
