import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import API from '../../services';
import db from '../../services/db';
// import swal from 'sweetalert';
import { useParams } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import style from '../../../public/assets/css/style.css';
// import ActionType from '../../redux/reducer/globalActionType';
import Meta from '../components/meta';

class Checkout extends Component {

    constructor(props){
      super(props);
      this.state = { data:null, load:false, loggedin:true,success:false,orderid:null,cancelorder:false }
      let timeoutId;
    }

    componentDidMount(){
      this.token_otentikasi();
    }

    token_otentikasi = async() => { 
      const stts = await API.otentikasi();
      if (stts === false){ this.setState({ loggedin:false }) }
      else if(stts === true) {
        // const decode = await API.decode();
        let loadtrans = await this.load_trans;
        this.timeoutId = setInterval( loadtrans, 10000);
        // setInterval(function () {
        //   // alert('Hello');
        //   this.load_trans;
        // }, 5000);
      }
    }

    load_trans = async() => {
      // console.log('ini load trans');
      // console.log(this.props.match.params.transid);
      axios.get(API.url+'pos/get_trans/'+this.props.match.params.transid+'/1',{ headers: {'X-Auth-Token': localStorage.getItem('token')}})
      .then((response) => {
          // console.log(response.data.content.details); 
          let result = response.data.content.details; 
          if (result.redeem === '1'){
            clearInterval(this.timeoutId);
            // console.log('berhasil');
            this.setState({ success:true, orderid:result.code })
          }else{ 
            // console.log('gagal'); 
          }
      })
      .catch((error) => { 
        // this.setState({ page404:true })
        // console.log("Error : ", error.response.data.error);
      }); 
        // axios
    }

    cancel_order = async() => {
      axios.get(API.url+'pos/cancel_trans/'+this.props.match.params.transid,{ headers: {'X-Auth-Token': localStorage.getItem('token')}})
      .then((response) => {
        this.setState({ cancelorder:true })
      })
      .catch((error) => { 
        // this.setState({ page404:true })
        // console.log("Error : ", error.response.data.error);
      }); 
        // axios
    }


  render() {
    // console.log("Hasil Data Redeem : ",this.state.data);

    if (this.state.loggedin === false){ return <Redirect to='/login'></Redirect>}
    if (this.state.success === true){ return <Redirect to={`/orderplaced/${this.state.orderid}`}></Redirect>}
    if (this.state.cancelorder === true){ return <Redirect to={"/orderhistory"}></Redirect>}
    
    return (
      <>
        <Meta/>
        <section className="px-15">

        <div className="iframe-container">
        {/* {`https://checkout-staging.xendit.co/web/${this.props.match.params.transid}`} */}
         <iframe src={`https://checkout-staging.xendit.co/web/${this.props.match.params.transid}`} frameBorder={0} style={{overflow: 'auto', height: '100%', width: '100%'}} height="100%" width="100%" />
        </div>
        
        </section>

        {/* panel space start */}
        <section className="panel-space" />
        {/* panel space end */}
        {/* bottom panel start */}
        <div className="delivery-cart cart-bottom">
          <div>
            {/* <div className="left-content">
              <a href="order-tracking.html" className="title-color">Lacak Pesanan</a>
            </div> */}
            <a onClick={() => this.cancel_order()} className="btn btn-solid"> Batalkan Pesanan </a>
          </div>
        </div>
        {/* bottom panel end */}

      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Checkout;
