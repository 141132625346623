import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import API from '../../services';
import db from '../../services/db';
// import swal from 'sweetalert';
import { useParams } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import style from '../../../public/assets/css/style.css';
import Meta from '../components/meta';

// import ActionType from '../../redux/reducer/globalActionType';

class Emptycart extends Component {

    constructor(props){
      super(props);
      this.state = { 
        data:[], droppoint:[], load:false
      }
    }

    componentDidMount(){
      this.token_otentikasi();
    }

    token_otentikasi = async() => { 
      const stts = await API.otentikasi();
      if (stts === false){ this.setState({ loggedin:false }) }
      else if(stts === true) {
        // const decode = await API.decode();
        // console.log("Hasil decode : ",decode);
      }
   }

  render() {

    return (
      <>
       <Meta/>
         {/* header start */}
         <header>
          <div className="back-links">
            <a href="/shop">
              <i className="iconly-Arrow-Left icli" />
              <div className="content">
                <h2>Shop</h2>
              </div>
            </a>
          </div>
          <div className="header-option">
          </div>
        </header>
        {/* header end */}
        {/* empty cart start */}
        <section className="px-15">
          <div className="empty-cart-section text-center">
            <img src="/assets/images/empty-cart.png" className="img-fluid" alt="" />
            <h2>Ups!! Keranjang anda kosong</h2>
            <p>Sepertinya Anda belum menambahkan apa pun ke keranjang. Anda akan menemukan banyak produk menarik di
              halaman "Toko" kami</p>
            <a href="/shop" className="btn btn-solid w-100">start shopping</a>
          </div>
        </section>
        {/* empty cart end */}
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Emptycart;
