import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
// import swal from 'sweetalert';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import API from '../../../services';
import product from '../product.css';
import Rating from '../../components/rating';
import Wishlist from '../../components/wishlist';

class Relatedproduct extends Component {
  constructor(props) {
    super(props);
    this.state = {load: false, data: [] };
  }

  componentDidMount() {
    this.load_related();
  }

  load_related = async(pid) => {
    this.setState({ load:false });
    let nilai = {limit:'4', offset:0};
    axios.post(API.url+'product/get_random',nilai)
      .then((response) => {
          // console.log('Hasil related : ',response.data.content.result);
          this.setState({ data: response.data.content.result, load:true });
      })
      .catch((error) => { 
          // error.response.status
          // API.post_error_db(this.constructor.name.toLowerCase(),error.response.data.error,error.response.status);
          // swal(error.response.data.error, "", "error");
          // console.log(error);
      }); 
  }

   wishlist = async event =>{
       this.setState({ load:true });
        axios.get(API.url+'product/wishlist/'+event)
        .then((response) => { 
            // this.setState({ data: response.data.content.result,load:false });
            // console.log(response.data.content);
            this.props.load_data();
        })
        .catch((err) => { 
          let mess = null;
          if (err.response.status === 401){mess = 'Silahkan login untuk wishlist menu'}
          toast.error(mess,
           {position: toast.POSITION.BOTTOM_CENTER,
           autoClose: 2000,
           hideProgressBar: false});
          
          // console.log("Error wishlist : ", err.response.statusText+' - '+err.response.status);
          //  API.otentikasi_acl('product'); API.post_error_db(this.constructor.name.toLowerCase(),err.response.statusText,err.response.status);
         }); 
    }

  render() {
    return (
      <>
      <div className="divider" />
       {/* kids corner section start */}
      <section className="pt-0 product-slider-section">
        <div className="title-section px-15">
          <h2> Related Product </h2>
          {/* <h3>Clothing for your Li’l One’s </h3> */}
        </div>
        <div className="product-slider slick-default pl-15 containerbox">

{

  this.state.data.map((dynamicData, index) => (
        <Fragment key={index}>
           <div className="col-xs-6 boxcontent">
            <div className="product-box ratio_square">
              <div className="img-part">
                 <a href={`/product/${dynamicData.sku}`}>
                    <img src={dynamicData.image} alt="" className="img-fluid bg-img" />
                 </a>
                  {/* wishlist */}
                  <Wishlist data={dynamicData.id} status={dynamicData.wishlist} load_data={this.load_related} />
                  {/* wishlist */}
              </div>
              <div className="product-content">
                <Rating data={dynamicData.rating} />
                <a href={`/product/${dynamicData.sku}`}>
                  <h4>{API.capitalize(dynamicData.name)}</h4>
                </a>
                <div className="price">
                   <h4>
                      {API.formatNumber(dynamicData.price)}
                      &nbsp; |
                      <span>{dynamicData.period}</span>
                   </h4>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      ))

}

          {/* <div className="col-xs-6 boxcontent">
            <div className="product-box ratio_square">
              <div className="img-part">
                <a href="product.html"><img src="/assets/images/products/9.jpg" alt="" className="img-fluid bg-img" /></a>
                <div className="wishlist-btn">
                  <i className="iconly-Heart icli" />
                  <i className="iconly-Heart icbo" />
                  <div className="effect-group">
                    <span className="effect" />
                    <span className="effect" />
                    <span className="effect" />
                    <span className="effect" />
                    <span className="effect" />
                  </div>
                </div>
              </div>
              <div className="product-content">
                <ul className="ratings">
                  <li><i className="iconly-Star icbo" /></li>
                  <li><i className="iconly-Star icbo" /></li>
                  <li><i className="iconly-Star icbo" /></li>
                  <li><i className="iconly-Star icbo" /></li>
                  <li><i className="iconly-Star icbo empty" /></li>
                </ul>
                <a href="product.html">
                  <h4>Blue Denim Jacket</h4>
                </a>
                <div className="price">
                  <h4>$32.00 <del>$35.00</del><span>20%</span></h4>
                </div>
              </div>
            </div>
          </div> */}

        </div>
      </section>
      {/* kids corner section end */}
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Relatedproduct;
