import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import API from '../../services';
import db from '../../services/db';
// import swal from 'sweetalert';
import { useParams } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Meta from '../components/meta';
// import style from '../../../public/assets/css/style.css';

// import ActionType from '../../redux/reducer/globalActionType';

class Voucher extends Component {

    constructor(props){
      super(props);
      this.state = { data:[], droppoint:[], bruto:0, load:false, datalength:0, loggedin:true, voucherstts:false, userid:0, voucher:null }
      this.offset = 0;
      this.limit = 100;
      this.outlet;
      localStorage.removeItem('voucher');
      // this.order="desc";
    }

    componentDidMount(){
      this.token_otentikasi();
    }

    token_otentikasi = async() => { 
      const stts = await API.otentikasi();
      if (stts === false){ this.setState({ loggedin:false }) }
      else if(stts === true) {
        const decode = await API.decode();
        // console.log("Hasil decode : ",decode);
        this.setState({ userid:decode.userid},() => { this.load_data_trans(); });
      }
    }

   load_data_trans = async() => {

    // alert('Load data trans');
    let total = 0; let totalqty = 0;
    let result = await db.posts.where('user').equalsIgnoreCase(this.state.userid).toArray();
    
    let droppoint=[];
    result.map((dynamicData,index) => {
      total = parseInt(total+dynamicData.total);
      if (droppoint.includes(dynamicData.outletid) === false){
        droppoint.push(dynamicData.outletid);
      }
    })
    this.setState({droppoint:droppoint.toString(),bruto: total},() => {
       this.load_voucher();
    });
  };

   load_voucher = async() => {
    this.setState({ load:false });
    // console.log(nilai);
    let nilai = {limit: 30, publish: "1"};
    axios.post(API.url+'vdiscount',nilai,{ headers: {'X-Auth-Token': localStorage.getItem('token')}})
      .then((response) => {
          this.setState({ data: response.data.content.result,load:true });
      })
      .catch((error) => { }); 
   }

   apply = async(event) => {
    // console.log("event : ",event);
      let nilai = {id: event, droppoint:this.state.droppoint, amount:this.state.bruto, payment:'CASH'};
      // console.log(nilai);
      axios.post(API.url+'vdiscount/calculate_redeem/',nilai,{ headers: {'X-Auth-Token': localStorage.getItem('token')}})
      .then((response) => { 
          localStorage.setItem('voucher', event);
          this.setState({ voucherstts: true });
          toast.success('Voucher applied',
          {position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 2000,
          hideProgressBar: false});
      })
      .catch((error) => { 
        toast.error(error.response.data.error,
          {position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 2000,
          hideProgressBar: false});
      }); 
   }

  render() {
    // console.log("Hasil Droppoint : ",this.state.droppoint);
    // console.log("Hasil Voucher : ",this.state.data);
    // console.log('Page : ',this.props.match.params.page);
   
    let comp;
    if (this.state.loggedin === false){ return <Redirect to='/login'></Redirect>}
    if (this.state.voucherstts === true){ return <Redirect to='/cart/shop'></Redirect>}

    if (this.state.data !== null){
      comp = this.state.data.map((dynamicData, index) => (
        <Fragment key={index}>

        <li>
            <div className="coupon-box">
              <div className="top-bar">
                <h4>{dynamicData.code}</h4><span>Save {dynamicData.percentage}% </span><a onClick={() => this.apply(dynamicData.id)}>APPLY</a>
              </div>
              <p>Gunakan kode {dynamicData.code} untuk mendapatkan diskon {dynamicData.percentage}% untuk minimum order {API.formatNumber(dynamicData.minimum)}<br/> 
                 Penawaran hanya berlaku untuk {dynamicData.target_audience_label} <br/>
                 Voucher berlaku selama masa periode {dynamicData.period} <br/>
                 Voucher berlaku dengan metode pembayaran {dynamicData.payment_type} <br/>
                 Voucher berlaku untuk outlet {dynamicData.target_drop_point}
              </p>
              <a href={`/voucherdetail/${dynamicData.code}/voucher`} className="text-green">View T&amp;C</a>
            </div>
        </li>

        </Fragment>
      ));
    }

    let back;
    if (this.props.match.params.page === 'home'){back = '/';}else{ back = `/${this.props.match.params.page}/shop`; }
    
    return (
      <>
      <Meta/>
        {/* header start */}
        <header>
          <div className="back-links">
            <a href={back}>
              <i className="iconly-Arrow-Left icli" />
              <div className="content">
                <h2>Coupons</h2>
              </div>
            </a>
          </div>
        </header>
        {/* header end */}
        {/* apply coupons section start */}
        <section className="px-15 top-space pt-0">
          <div className="search-coupons">
            <input className="form-control form-theme" placeholder="Apply Coupons" />
            <i className="iconly-Discount icli" />
          </div>
          <ul className="coupon-listing">

          {comp}

            {/* <li>
              <div className="coupon-box">
                <div className="top-bar">
                  <h4>MULTIKART10</h4><span>Save $20.00</span><a href="#">APPLY</a>
                </div>
                <p>Use code MULTIKART10 to get flat 10% off on minimum order of $200.00. Offer valid for first time
                  users only</p>
                <a href="terms-condition.html" className="text-green">View T&amp;C</a>
              </div>
            </li> */}


          </ul>
        </section>
        {/* apply coupons section end */}
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Voucher;
