import React, { Component,Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import axios from 'axios';
import API from '../../../services';
import Rating from '../../components/rating';
import Wishlist from '../../components/wishlist';

// eslint-disable-next-line react/prefer-stateless-function
export class Tab extends Component {

    constructor(props) {
    super(props);
    // eslint-disable-next-line react/no-unused-state
    this.state = { datalatest: [], databest: [], dataeconomic: [], load: false };
  }

  componentDidMount() {
    // this.token_otentikasi();
    //  console.log(API.url);
    this.load_latest();
    // this.load_best();
    // this.load_economic();
  }

  load_latest = () => {
        this.setState({ load:false });
        axios.post(API.url+'product',{category: "",publish: "", recommend:"", bestseller:"", latest:"1", economic:"", limit:"10", offset:"0"},{ headers: { 'X-Auth-Token': localStorage.getItem('token') } })
          .then((response) => {
              this.setState({ datalatest: response.data.content.result,load:true });
              this.load_best();
              // console.log(response.data.content.result);
          })
          .catch((error) => { 
              // error.response.status
              // API.post_error_db(this.constructor.name.toLowerCase(),error.response.data.error,error.response.status);
              // swal(error.response.data.error, "", "error");
              // console.log(error);
          }); 
  }

  load_best = () => {
        this.setState({ load:false });
        axios.post(API.url+'product',{category: "",publish: "", recommend:"", bestseller:"1", latest:"", economic:"", limit:"10", offset:"0"},{ headers: { 'X-Auth-Token': localStorage.getItem('token') } })
          .then((response) => {
              this.setState({ databest: response.data.content.result,load:true });
              this.load_economic();
              // console.log(response.data.content.result);
          })
          .catch((error) => { 
              // error.response.status
              // API.post_error_db(this.constructor.name.toLowerCase(),error.response.data.error,error.response.status);
              // swal(error.response.data.error, "", "error");
              // console.log(error);
          }); 
  }

    load_economic = () => {
        this.setState({ load:false });
        axios.post(API.url+'product',{category: "",publish: "", recommend:"", bestseller:"", latest:"", economic:"1", limit:"10", offset:"0"},{ headers: { 'X-Auth-Token': localStorage.getItem('token') } })
          .then((response) => {
              this.setState({ dataeconomic: response.data.content.result,load:true });
              // console.log(response.data.content.result);
          })
          .catch((error) => { 
              // error.response.status
              // API.post_error_db(this.constructor.name.toLowerCase(),error.response.data.error,error.response.status);
              // swal(error.response.data.error, "", "error");
              // console.log(error);
          }); 
  }

  render() {
    // console.log(this.state.dataeconomic);
    return (
      <section className="pt-0 tab-section">
        <div className="title-section px-15">
          {/* <h2>Find your Style</h2> */}
          {/* <h3>Super Summer Sale</h3> */}
        </div>
        <div className="tab-section">
          <ul className="nav nav-tabs theme-tab pl-15">
            <li className="nav-item">
              <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#latest" type="button">
                Menu Terbaru
              </button>
            </li>
            <li className="nav-item">
              <button className="nav-link" data-bs-toggle="tab" data-bs-target="#bestseller" type="button">Best Seller</button>
            </li>
            <li className="nav-item">
              <button className="nav-link" data-bs-toggle="tab" data-bs-target="#economic" type="button">
                Paket Hemat
              </button>
            </li>
          </ul>

          {/* latest */}
          <div className="tab-content px-15">
            <div className="tab-pane fade show active" id="latest">
              <div className="row gy-3 gx-3">
{
          this.state.datalatest.map((dynamicData,index) => {
            return (
            <Fragment key={index}>
 {/* awal box container */}
                <div className="col-md-4 col-6">
                  <div className="product-box ratio_square">
                    <div className="img-part">
                      <Link to={`/product/${dynamicData.sku}`}>
                      <img src={dynamicData.image} alt="" className="img-fluid bg-img" />
                      </Link>
                      {/* wishlist */}
                      <Wishlist data={dynamicData.id} status={dynamicData.wishlist} load_data={this.load_latest}/>
                      {/* wishlist */}
                    </div>
                    <div className="product-content">
                      <Rating data={dynamicData.rating} />
                      <Link to={`/product/${dynamicData.sku}`}>
                        <h4>{API.capitalize(dynamicData.name)}</h4>
                      </Link>
                      <div className="price">
                        <h4>
                          {API.formatNumber(dynamicData.price)} /
                          {/* <del>$35.00</del> */}
                          <span>{dynamicData.period}</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                {/* akhir box container */}

            </Fragment>                
            );
        })      
}
{/* end react rendered */}
              </div>
            </div>
            {/* latest */}

            
            {/* bestseller */}
            <div className="tab-pane fade" id="bestseller">
              <div className="row gy-3 gx-3">
{
          this.state.databest.map((dynamicData,index) => {
            return (
            <Fragment key={index}>
               {/* awal box container */}
                <div className="col-6">
                  <div className="product-box ratio_square">
                    <div className="img-part">
                      <Link to={`/product/${dynamicData.sku}`}>
                        <img src={dynamicData.image} alt="" className="img-fluid bg-img" />
                      </Link>
                       {/* wishlist */}
                         <Wishlist data={dynamicData.id} status={dynamicData.wishlist} load_data={this.load_latest}/>
                       {/* wishlist */}
                    </div>
                    <div className="product-content">
                     <Rating data={dynamicData.rating} />
                       <Link to={`/product/${dynamicData.sku}`}>
                        <h4>{API.capitalize(dynamicData.name)}</h4>
                       </Link>
                      <div className="price">
                        <h4>
                          {API.formatNumber(dynamicData.price)} /
                          {/* <del>$35.00</del> */}
                          <span>{dynamicData.period}</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                 {/* end box container */}

            </Fragment>                
            );
        })      
}
              </div>
            </div>
            {/* bestseller */}

             {/* economic */}
            <div className="tab-pane fade" id="economic">
              <div className="row gy-3 gx-3">
{
          this.state.dataeconomic.map((dynamicData,index) => {
            return (
            <Fragment key={index}>
               {/* awal box container */}
                <div className="col-6">
                  <div className="product-box ratio_square">
                    <div className="img-part">
                       <Link to={`/product/${dynamicData.sku}`}>
                        <img src={dynamicData.image} alt="" className="img-fluid bg-img" />
                       </Link>
                       {/* wishlist */}
                         <Wishlist data={dynamicData.id} status={dynamicData.wishlist} load_data={this.load_latest}/>
                       {/* wishlist */}
                    </div>
                    <div className="product-content">
                      <Rating data={dynamicData.rating} />
                       <Link to={`/product/${dynamicData.sku}`}>
                        <h4>{API.capitalize(dynamicData.name)}</h4>
                        </Link>
                      <div className="price">
                        <h4>
                          {API.formatNumber(dynamicData.price)} /
                          {/* <del>$35.00</del> */}
                          <span>{dynamicData.period}</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                 {/* end box container */}

            </Fragment>                
            );
        })      
}
              </div>
            </div>
            {/* economic */}



            {/* <div className="tab-pane fade" id="ship">
              <div className="row gy-3 gx-3">
                <div className="col-6">
                  <div className="product-box ratio_square">
                    <div className="img-part">
                      <a href="product.html"><img src="assets/images/products/4.jpg" alt="" className="img-fluid bg-img" /></a>
                      <div className="wishlist-btn">
                        <i className="iconly-Heart icli" />
                        <i className="iconly-Heart icbo" />
                        <div className="effect-group">
                          <span className="effect" />
                          <span className="effect" />
                          <span className="effect" />
                          <span className="effect" />
                          <span className="effect" />
                        </div>
                      </div>
                    </div>
                    <div className="product-content">
                      <ul className="ratings">
                        <li><i className="iconly-Star icbo" /></li>
                        <li><i className="iconly-Star icbo" /></li>
                        <li><i className="iconly-Star icbo" /></li>
                        <li><i className="iconly-Star icbo" /></li>
                        <li><i className="iconly-Star icbo empty" /></li>
                      </ul>
                      <a href="product.html">
                        <h4>Blue Denim Jacket</h4>
                      </a>
                      <div className="price">
                        <h4>
                          $32.00
                          {' '}
                          <del>$35.00</del>
                          <span>20%</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="product-box ratio_square">
                    <div className="img-part">
                      <a href="product.html"><img src="assets/images/products/6.jpg" alt="" className="img-fluid bg-img" /></a>
                      <div className="wishlist-btn">
                        <i className="iconly-Heart icli" />
                        <i className="iconly-Heart icbo" />
                        <div className="effect-group">
                          <span className="effect" />
                          <span className="effect" />
                          <span className="effect" />
                          <span className="effect" />
                          <span className="effect" />
                        </div>
                      </div>
                    </div>
                    <div className="product-content">
                      <ul className="ratings">
                        <li><i className="iconly-Star icbo" /></li>
                        <li><i className="iconly-Star icbo" /></li>
                        <li><i className="iconly-Star icbo" /></li>
                        <li><i className="iconly-Star icbo" /></li>
                        <li><i className="iconly-Star icbo empty" /></li>
                      </ul>
                      <a href="product.html">
                        <h4>Blue Denim Jacket</h4>
                      </a>
                      <div className="price">
                        <h4>
                          $32.00
                          {' '}
                          <del>$35.00</del>
                          <span>20%</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="product-box ratio_square">
                    <div className="img-part">
                      <a href="product.html"><img src="assets/images/products/7.jpg" alt="" className="img-fluid bg-img" /></a>
                      <div className="wishlist-btn">
                        <i className="iconly-Heart icli" />
                        <i className="iconly-Heart icbo" />
                        <div className="effect-group">
                          <span className="effect" />
                          <span className="effect" />
                          <span className="effect" />
                          <span className="effect" />
                          <span className="effect" />
                        </div>
                      </div>
                    </div>
                    <div className="product-content">
                      <ul className="ratings">
                        <li><i className="iconly-Star icbo" /></li>
                        <li><i className="iconly-Star icbo" /></li>
                        <li><i className="iconly-Star icbo" /></li>
                        <li><i className="iconly-Star icbo" /></li>
                        <li><i className="iconly-Star icbo empty" /></li>
                      </ul>
                      <a href="product.html">
                        <h4>Blue Denim Jacket</h4>
                      </a>
                      <div className="price">
                        <h4>
                          $32.00
                          {' '}
                          <del>$35.00</del>
                          <span>20%</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="product-box ratio_square">
                    <div className="img-part">
                      <a href="product.html"><img src="assets/images/products/5.jpg" alt="" className="img-fluid bg-img" /></a>
                      <div className="wishlist-btn">
                        <i className="iconly-Heart icli" />
                        <i className="iconly-Heart icbo" />
                        <div className="effect-group">
                          <span className="effect" />
                          <span className="effect" />
                          <span className="effect" />
                          <span className="effect" />
                          <span className="effect" />
                        </div>
                      </div>
                    </div>
                    <div className="product-content">
                      <ul className="ratings">
                        <li><i className="iconly-Star icbo" /></li>
                        <li><i className="iconly-Star icbo" /></li>
                        <li><i className="iconly-Star icbo" /></li>
                        <li><i className="iconly-Star icbo" /></li>
                        <li><i className="iconly-Star icbo empty" /></li>
                      </ul>
                      <a href="product.html">
                        <h4>Blue Denim Jacket</h4>
                      </a>
                      <div className="price">
                        <h4>
                          $32.00
                          {' '}
                          <del>$35.00</del>
                          <span>20%</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

          </div>
        </div>
      </section>
    );
  }
}

export default Tab;
