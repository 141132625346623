import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
// import swal from 'sweetalert';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import API from '../../../services';
import db from '../../../services/db';

class Shippingrj extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false, data: [], userid:null, totweight:0, checked:true
    };
  }

  componentDidMount() {
    // Get location of user
      this.load_weight();
  }

  load_weight = async() => {
    this.setState({ load:false });
    let nilai = {droppoint:this.props.droppointdata, addressid:localStorage.getItem('addressid'), weight:this.props.totweight};
    // console.log('Nilai : ',nilai);
    axios.post(API.url+'pos/get_courier_list',nilai,{ headers: {'X-Auth-Token': localStorage.getItem('token')}})
      .then((response) => {
          this.setState({ data: response.data.content,load:true });
          // console.log('Hasil Result : ',response.data.content);
      })
      .catch((error) => { }); 
   }

   set_shipping_cost = async(service,value) => {
    // this.setState({ checked: false });
    if (service === null){
      this.setState({ checked: true });
    }else{ this.setState({ checked: false }); }
    this.props.setshippingcost(service,value);
   }

  render() {
    // console.log('Totweight render : ',this.props.totweight);
    // console.log('Droppoint List : ',this.props.droppointdata);
    // console.log(localStorage.getItem('addressid'));

    // console.log("hasil Data : ",this.state.data);

    let comp;
    if (this.state.data !== null){
      // console.log('ETD : ',this.state.data[0].cost[0].etd);
      // console.log("Cost : ",this.state.data[0].cost[0].value);

      // this.state.data.map((dynamicData, index) => (
      //   console.log(dynamicData.cost[0])
      // ))

      // comp = <div className="me-3 d-flex align-items-center mb-2">
      //         <input className="radio_animated" type="radio" name="name" id="type" defaultValue="kantor" />
      //         <label htmlFor="office">1-3 Hari </label>
      //        </div>;
    }

    return (
     
      <>
        {/* coupon section */}
        <section className="px-15 pt-0">
          <div className="coupon-section">
            <h3 style={{ margin: '0px 10px 10px 0px' }}> Jenis Layanan Pengiriman </h3>
            <form className="d-flex">
              <div className="me-3 d-flex align-items-center mb-2">
                <input onClick={() => this.set_shipping_cost(null,0)} className="radio_animated" checked={this.state.checked} type="radio" name="name" id="type" defaultValue="rumah" />
                <label htmlFor="Home"> 1-3 jam </label>
              </div>

{
    this.state.data.map((dynamicData, index) => (
      <Fragment>
      <div className="me-3 d-flex align-items-center mb-2">
        <input onClick={() => this.set_shipping_cost(dynamicData.service,dynamicData.cost[0].value)} className="radio_animated" type="radio" name="name" id="type" defaultValue={dynamicData.cost[0].value} />
        <label htmlFor="office"> {dynamicData.service} - {dynamicData.cost[0].etd} Hari </label>
        {/* <label htmlFor="office"> - {dynamicData.cost[0].value} </label> */}
      </div>
      </Fragment>
    ))
}

              {/* <div className="me-3 d-flex align-items-center mb-2">
                <input className="radio_animated" type="radio" name="name" id="type" defaultValue="kantor" />
                <label htmlFor="office">1-3 Hari </label>
              </div> */}
            </form>
          </div>
          {' '}
          <br />
        </section>
        <div className="divider" />
        {/* coupon end */}
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Shippingrj;
