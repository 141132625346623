import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
// import swal from 'sweetalert';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
// import db from '../../services/db';
import API from '../../../services';
import 'react-toastify/dist/ReactToastify.css';
// import style from '../../../public/assets/css/style.css';
// import ActionType from '../../redux/reducer/globalActionType';

class Orderdetail extends Component {
  constructor(props) {
    super(props);
    this.state = { data: null, items: null, item: [], load: false };
  }

  componentDidMount() {
    // this.token_otentikasi();
    this.load_trans();
  }

  load_trans = async() => {
    // console.log('Data Props : ',this.props.dataprops.code);
    axios.get(API.url+'pos/get_trans/'+this.props.dataprops.code,{ headers: {'X-Auth-Token': localStorage.getItem('token')}})
    .then((response) => {
        // console.log("Hasil data load trans : ",response.data.content.items); 
        this.setState({
          items:response.data.content.items,
          data:response.data.content.details
        },() => {
            // this.get_payment(result.payment_type);
        });
        
    })
    .catch((error) => { 
      // this.setState({ page404:true })
      // console.log("Error : ", error.response.data.error);
    }); 
      // axios
  }

  render() {
    // console.log('Data Items : ',this.state.items);
    let comp; let status= <span className="status-label">normal</span>;
    if (this.state.items !== null){

      if (this.state.data.pickup === '0' && this.state.data.canceled === null){
        status = <span className="status-label">berlangsung</span>;
      }else if(this.state.data.pickup === '0' && this.state.data.canceled !== null){
        status = <span className="status-label">dibatalkan</span>;
      }else if(this.state.data.pickup === '1' && this.state.data.canceled === null){
        status = <span className="status-label bg-theme text-white">dikirim</span>;
      }

      comp =  this.state.items.map((dynamicData, index) => (
        <Fragment key={index}>
         <div className="d-flex align-items-center">
          <img src={dynamicData.image} className="img-fluid order-img" alt="" />
          <div className="media-body">
            <h4> {API.capitalize(dynamicData.name)} </h4>
            <h5 className="content-color my-1">Qty: {dynamicData.qty}, {dynamicData.attribute}</h5>
            <a className="theme-color" href={`/orderplaced/${this.state.data.code}`}>Lihat Rincian</a>
            <div className="rate-section">
                <ul>
                  <li onClick={() => this.props.set_pid_comment(dynamicData.sku,this.state.data.code)} data-bs-toggle="offcanvas" data-bs-target="#offcanvasreview"><i className="iconly-Star icli" /> Rate &amp; Review Product</li>
                </ul>
              </div>
          </div>
          {status}
        </div> <br/>
        </Fragment>
      ));
    }
    return (
      <>
        {comp}
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Orderdetail;
