import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
// import swal from 'sweetalert';
import API from '../../../services';
import axios from 'axios';
import Select from 'react-select';

// import ActionType from '../../redux/reducer/globalActionType';

class Cityrj extends Component {
  constructor(props) {
    super(props);
    this.state = { datacity: [], datadistrict:null, load: false};
    this.options = [];
  }

  componentDidMount() {
    this.load_kabupaten();
    //  console.log(API.url);
  }

  load_kabupaten = async() => {
    this.setState({ load:false });
    axios.get(API.url+'city/get_city_rj')
      .then((response) => {
          this.setState({ datacity: response.data.content.result, load:true });
      })
      .catch((error) => { 
          // console.log(error);
      }); 
  }

  get_district = async(event) => {
    this.setState({ datadistrict:null });
    // console.log(event.value);
      axios.get(API.url+'city/get_district_rj/'+event.value,{ headers: {'X-Auth-Token': localStorage.getItem('token')}})
      .then((response) => {
          // let result = response.data.content.result;
          this.setState({ datadistrict: response.data.content.result, load:true });
          // console.log(result);
          // this.props.setcity(parseFloat(result.cost),parseInt(result.add_cost),result.cash,uid);
      })
      .catch((error) => { 
      }); 
  }

  set_district = async(event) => {
    // console.log('ID District ; ',event.value);
    axios.get(API.url+'city/get_district_by_id/'+event.value,{ headers: {'X-Auth-Token': localStorage.getItem('token')}})
      .then((response) => {
          let result = response.data.content.result;
          // console.log(result.province_id);
          this.props.setcity(result.province_id,result.city_id,event.value);
      })
      .catch((error) => { 
      }); 
  }

  render() {
    // console.log('Data city : ',this.state.data);
    let comp;
    if (this.state.datadistrict !== null){
      comp = <div className="form-floating mb-4">
               <Select onChange={this.set_district} options={this.state.datadistrict} />  
               <label htmlFor="one">Kecamatan</label>
             </div>;
    }
    return (
      <>
      <div className="form-floating mb-4">
        <Select onChange={this.get_district} options={this.state.datacity} />  
        <label htmlFor="one">Kota/Kabupaten</label>
      </div>
      {comp}
       
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Cityrj;
