import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import API from '../../services';
// import swal from 'sweetalert';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Bottomtab from '../components/bottomtab';
import Meta from '../components/meta';
import waicon from '../../assets/images/whatsapp.png';
import helpicon from '../../assets/images/question.png';
import abouticon from '../../assets/images/about.png';

// import ActionType from '../../redux/reducer/globalActionType';

class Profile extends Component {

    constructor(props){
      super(props);
      this.state = {data:[], property:null, loggedin:true, load:false }
    }

    componentDidMount(){
       this.token_otentikasi();
      //  console.log(API.url);
    }

    token_otentikasi = async() => { 
       const stts = await API.otentikasi();
       if (stts === false){ this.setState({ loggedin:false }) }else{
         this.get_property();
       }
    }

    get_property = async event =>{
      //  console.log("token : ",localStorage.getItem('token'));
         this.setState({ load:true });
          axios.get(API.url+'configuration/', { headers: {'X-Auth-Token': localStorage.getItem('token')}})
          .then((response) => { 
              this.setState({ property: response.data.content });
              this.load_user();
              // console.log("Hasil response : ",response.data.content);
          })
          .catch((err) => { 
           }); 
      }

    load_user = async() => {
      axios.get(API.url+'customer/get/',{ headers: { 'X-Auth-Token': localStorage.getItem('token') } })
      .then((response) => {
        // console.log("Data load user : ",response.data.content);
          this.setState({ data: response.data.content});
      })
      .catch((error) => { 
          // error.response.status
          // API.post_error_db(this.constructor.name.toLowerCase(),error.response.data.error,error.response.status);
          // swal(error.response.data.error, "", "error");
          // console.log(error);
      }); 
    }

    logout = async() =>{
       axios.get(API.url+'customer/logout/',{ headers: { 'X-Auth-Token': localStorage.getItem('token') } })
      .then((response) => {
        localStorage.removeItem('token'); localStorage.removeItem('os-user');
        this.setState({loggedin:false});
      })
      .catch((error) => { 
      }); 
    }

  render() {
    let targetURL;
     if (this.state.property !== null){
       targetURL = `whatsapp://send?text=Hello,${this.state.property.name}. Terima kasih dan mohon ditunggu&phone=+${this.state.property.phone1}`;
     }
    //  console.log('Hasil property : ',name+""+phone);
     if (this.state.loggedin === false){ return <Redirect to='/login'></Redirect>}

     let back;
     if (this.props.match.params.page === 'home'){back = '/';}else{ back = `/${this.props.match.params.page}`; }

    return (
      <>
       <Meta/>
     {/* header start */}
        <header>
          <div className="back-links">
            <a href={back}>
              <i className="iconly-Arrow-Left icli" />
              <div className="content">
                <h2>Profile</h2>
              </div>
            </a>
          </div>
        </header>
        {/* header end */}
        {/* profile section start */}
        <section className="top-space pt-0">
          <div className="profile-detail">
            <div className="media">
              <img src="/assets/images/user/1.png" className="img-fluid" alt="" />
              <div className="media-body">
                <h2>{API.capitalize(this.state.data.fname)}</h2>
                <h6>{this.state.data.email} <br/> {this.state.data.phone1}</h6>
                <a href="/profilesetting" className="edit-btn">Edit</a>
              </div>
            </div>
          </div>
        </section>
        {/* profile section end */}
        {/* link section start */}
        <div className="sidebar-content">
          <ul className="link-section">
            {/* <li>
              <div>
                <i className="iconly-Setting icli" />
                <div className="content toggle-sec w-100">
                  <div>
                    <h4>Dark Mode</h4>
                  </div>
                  <div className="button toggle-btn ms-auto">
                    <input id="darkButton" type="checkbox" className="checkbox" />
                    <div className="knobs">
                      <span />
                    </div>
                    <div className="layer" />
                  </div>
                </div>
              </div>
            </li> */}
            {/* <li>
              <div>
                <i className="iconly-Setting icli" />
                <div className="content toggle-sec w-100">
                  <div>
                    <h4>RTL</h4>
                  </div>
                  <div className="button toggle-btn ms-auto">
                    <input id="rtlButton" type="checkbox" className="checkbox" />
                    <div className="knobs">
                      <span />
                    </div>
                    <div className="layer" />
                  </div>
                </div>
              </div>
            </li> */}
            {/* <li>
              <a href="pages.html">
                <i className="iconly-Paper icli" />
                <div className="content">
                  <h4>Pages</h4>
                  <h6>Ongoing Orders, Recent Orders..</h6>
                </div>
              </a>
            </li> */}
            <li>
              <a href="/orderhistory">
                <i className="iconly-Document icli" />
                <div className="content">
                  <h4>Orders</h4>
                  <h6>Ongoing Orders, Recent Orders..</h6>
                </div>
              </a>
            </li>
            <li>
              <a href="/wishlist/profile">
                <i className="iconly-Heart icli" />
                <div className="content">
                  <h4>Your Wishlist</h4>
                  <h6>Your Save Products</h6>
                </div>
              </a>
            </li>
            {/* <li>
              <a href="saved-cards.html">
                <i className="iconly-Wallet icli" />
                <div className="content">
                  <h4>Payment</h4>
                  <h6>Saved Cards, Wallets</h6>
                </div>
              </a>
            </li> */}
            <li>
              <a href="/caddress/profile">
                <i className="iconly-Location icli" />
                <div className="content">
                  <h4>Saved Address</h4>
                  <h6>Home, office.. </h6>
                </div>
              </a>
            </li>
            {/* <li>
              <a href="#">
                <img src="assets/images/flag.png" className="img-fluid" alt="" />
                <div className="content">
                  <h4>Langauge</h4>
                  <h6>Select your Language here..</h6>
                </div>
              </a>
            </li> */}
            <li>
              <a href="/notification/profile">
                <i className="iconly-Notification icli" />
                <div className="content">
                  <h4>Notification</h4>
                  <h6>Offers, Order tracking messages..</h6>
                </div>
              </a>
            </li>
            {/* <li>
              <a href="settings.html">
                <i className="iconly-Setting icli" />
                <div className="content">
                  <h4>Settings</h4>
                  <h6>Dark mode, RTL, Notification</h6>
                </div>
              </a>
            </li> */}
            <li>
              <a href="/profilesetting">
                <i className="iconly-Password icli" />
                <div className="content">
                  <h4>Profile setting</h4>
                  <h6>Full Name, Password..</h6>
                </div>
              </a>
            </li>
          </ul>
          <div className="divider" />
          <ul className="link-section">
            <li>
              <a href="/tnc">
                <i className="iconly-Info-Square icli" />
                <div className="content">
                  <h4>Terms &amp; Conditions</h4>
                  <h6>T&amp;C for use of Platform</h6>
                </div>
              </a>
            </li>

            <li>
              <a href="/help">
                {/* <i className="iconly-Call icli" /> */}
                <img src={helpicon} className="img-fluid" alt="Help" />
                <div className="content">
                  <h4>Help</h4>
                  <h6>FAQs</h6>
                </div>
              </a>
            </li>

            <li>
              <a href={targetURL}>
              {/* <a href="help.html"> */}
                {/* <i className="iconly-Call icli" /> */}
                <img src={waicon} className="img-fluid" alt="Whatsapp" />
                <div className="content">
                  <h4>Live Chat</h4>
                  <h6>Customer Support, FAQs</h6>
                </div>
              </a>
            </li>
            <li>
              <a href="/feedback">
                <i className="iconly-Call icli" />
                <div className="content">
                  <h4>Customer Care</h4>
                  <h6>Customer Support</h6>
                </div>
              </a>
            </li>
            <li>
              <a href="/about">
                {/* <i className="iconly-Call icli" /> */}
                <img src={abouticon} className="img-fluid" alt="About" />
                <div className="content">
                  <h4>About</h4>
                  {/* <h6>FAQs</h6> */}
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div className="px-15">
          <a onClick={this.logout} className="btn btn-outline w-100 content-color">LOG OUT</a>
        </div>
        {/* link section end */}
        {/* panel space start */}
        <section className="panel-space" />
        {/* panel space end */}
        {/* bottom panel start */}
        <Bottomtab active="profile" />
        {/* bottom panel end */}
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Profile;
