import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
// import swal from 'sweetalert';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import API from '../../../services';

class Orderdetails extends Component {
  constructor(props) {
    super(props);
    this.state = { type: 0, load: false};
    this.drop = [];
  }

  componentDidMount() {
  }

  render() {
    return (
      <>
       {/* order details start */}
       <section id="order-details" className="px-15 pt-0">
          <h2 className="title">Order Details:</h2>
          <div className="order-details">
            <ul>
              <li>
                <h4>Total Keranjang <span>{API.formatNumber(this.props.bruto)}</span></h4>
              </li>
              <li>
                <h4>Discount <span className="text-green">-{API.formatNumber(this.props.discount)}</span></h4>
              </li>
              {/* <li>
                <h4>Shipping <a href="/shipping" className="theme-color">Shipping</a></h4>
              </li> */}
              <li>
                <h4>Pengiriman <span>{API.formatNumber(this.props.shipping)}</span></h4>
              </li>
              <li>
                <h4>Biaya Layanan <span>{API.formatNumber(this.props.cost)}</span></h4>
              </li>
              
            </ul>
            <div className="total-amount">
              <h4>Total <span>{API.formatNumber(this.props.netto)}</span></h4>
            </div>
            {/* <div className="delivery-info">
              <img src="assets/images/truck.gif" className="img-fluid" alt="" />
              <h4>No Delivery Charges applied on this order </h4>
            </div> */}
          </div>
        </section>
        <div className="divider" />
        {/* order details end */}
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Orderdetails;
