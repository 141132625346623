import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import API from '../../services';
// import swal from 'sweetalert';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import qs from 'qs';
import Meta from '../components/meta';

// import ActionType from '../../redux/reducer/globalActionType';

class Profilesetting extends Component {

    constructor(props){
      super(props);
      this.state = {data:[], datakab:[], datadistrict:[], verify:false, loggedin:true, load:false, city:"", district:"" }
    }

    componentDidMount(){
       this.token_otentikasi();
       this.load_kabupaten();
      //  console.log(API.url);
    }

    token_otentikasi = async() => { 
       const stts = await API.otentikasi();
       if (stts === false){ this.setState({ loggedin:false }) }else{
         this.load_user();
       }
    }

    load_user = async() => {
      axios.get(API.url+'customer/get/',{ headers: { 'X-Auth-Token': localStorage.getItem('token') } })
      .then((response) => {
        // console.log("Data load user : ",response.data.content);
          this.setState({ data: response.data.content, city:response.data.content.city, district:response.data.content.district});
          this.load_district();
      })
      .catch((error) => { 
          // error.response.status
          // API.post_error_db(this.constructor.name.toLowerCase(),error.response.data.error,error.response.status);
          // swal(error.response.data.error, "", "error");
          // console.log(error);
      }); 
    }

    load_kabupaten = async() => {
      this.setState({ load:false });
      axios.get(API.url+'city')
        .then((response) => {
            this.setState({ datakab: response.data.content.result, datadistrict:[], load:true });
        })
        .catch((error) => { 
            // console.log(error);
        }); 
    }

    load_district = async() => {
      this.setState({ load:false });
      axios.get(API.url+'city/get_district/'+this.state.data.city)
        .then((response) => {
            this.setState({ datadistrict: response.data.content.result, load:true });
        })
        .catch((error) => { 
            // console.log(error);
        }); 
    }

    handleChange = (selectedOption) => {
      // console.log(`Option selected:`, selectedOption.value)
       this.setState({ load:false, datadistrict:[] });
       axios.get(API.url+'city/get_district/'+selectedOption.value)
        .then((response) => {
            this.setState({ datadistrict: response.data.content.result, city:selectedOption.value, load:true });
        })
        .catch((error) => { 
            // console.log(error);
        }); 
    };

    handleDistrict = (selectedOption) => {
      // console.log(`Option selected:`, selectedOption.value)
       this.setState({ district:selectedOption.value });
    };

    update = async() =>{
        
      let name = document.getElementById("name").value;
      let email = document.getElementById("email").value;
      let phone = document.getElementById("phone").value;
      let city = this.state.city;
      let district = this.state.district;
      let address = document.getElementById("address").value;
      let zip = document.getElementById("zip").value;

      let requestBody = {tname: name,taddress: address, tzip:zip, tphone1: phone, temail: email, ccity:city, cdistrict:district, twebsite:"", tnpwp:"", tprofession:"", torganization:"", tinstagram:"" }
      // console.log(requestBody);

      this.setState({ load:false })
      axios.post(API.url+'customer/update',qs.stringify(requestBody),{ headers: { 'X-Auth-Token': localStorage.getItem('token') } })
        .then((response) => {
            // console.log(response.data.content); 
            toast.success("Perubahan Data Profile Berhasil",
                {position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 2000,
                hideProgressBar: false});

            setTimeout(
              () => this.setState({ verify: true }), 
              4000
            );
            
            // localStorage.setItem('token', response.data.content.token);
            // this.setState({ loggedin:true })
            // alert('login berhasil');
        })
        .catch((error) => { 
          // console.log("Error : ", error.response.data.error);
          toast.error(error.response.data.error,
                {position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 2000,
                hideProgressBar: false});
        }); 
          // axios

    }

  render() {
     const { items } = this.state;
     if (this.state.loggedin === false){ return <Redirect to='/login'></Redirect>}
     if (this.state.verify === true){ return <Redirect to={`/verify/${this.state.data.id}`}></Redirect>}
    return (
      <>
      <Meta/>
       {/* header start */}
       <header>
          <div className="back-links">
            <a href="/profile/home">
              <i className="iconly-Arrow-Left icli" />
              <div className="content">
                <h2>Profile Setting</h2>
              </div>
            </a>
          </div>
        </header>
        {/* header end */}
        {/* user avtar section */}
        <section className="user-avtar-section top-space pt-0 px-15">
          <img src="/assets/images/user/1.png" className="img-fluid" alt="" />
          {/* <span className="edit-icon"><i className="iconly-Edit-Square icli" /></span> */}
        </section>
        {/* user avtar end */}
        {/* detail form start */}
        <section className="detail-form-section px-15">
          <h2 className="page-title mb-4">Personal Details</h2>
          <form>
            <div className="form-floating mb-4">
              <input type="text" className="form-control" id="name" placeholder="First Name" defaultValue={this.state.data.fname} />
              <label htmlFor="name">Name</label>
            </div>
            <div className="form-floating mb-4">
              <input type="email" className="form-control" id="email" placeholder="Email" defaultValue={this.state.data.email} />
              <label htmlFor="email">Email</label>
            </div>
            <div className="form-floating mb-4">
              <Select
                value={this.state.datakab.filter(({value}) => value === this.state.city)}
                placeholder="..."
                onChange={this.handleChange}
                options={this.state.datakab}
              />
              <label htmlFor="ckab">Kota/Kabupaten</label>
            </div>
             <div className="form-floating mb-4">
              <Select
                value={this.state.datadistrict.filter(({value}) => value === this.state.district)}
                placeholder="..."
                onChange={this.handleDistrict}
                options={this.state.datadistrict}
              />
              <label htmlFor="cdistrict">Kecamatan/Wilayah</label>
            </div>
            <div className="form-floating mb-4">
              <textarea className="form-control" style={{height: '100px'}} id="address" placeholder="Address" defaultValue={this.state.data.address}></textarea>
              <label htmlFor="address">Address</label>
            </div>
             <div className="form-floating mb-4">
              <input type="number" className="form-control" id="zip" placeholder="Zip Code" defaultValue={this.state.data.zip} />
              <label htmlFor="zip">Zip Code</label>
            </div>
          </form>
        </section>
        <div className="divider" />
        <section className="detail-form-section pt-0 px-15">
          <form className="pt-cls">
            <div className="form-floating mb-4">
              <input type="number" className="form-control" id="phone" defaultValue={this.state.data.phone1} placeholder="Mobile Number" />
              <label htmlFor="phone">Mobile Number</label>
              {/* <a className="change-btn" href="#">change</a> */}
            </div>
          </form>
        </section>
        {/* detail form end */}
        {/* panel space start */}
        <section className="panel-space" />
        {/* panel space end */}
        {/* bottom panel start */}
        <div className="cart-bottom row m-0">
          <div>
            <div className="left-content col-5">
              <a href="/profile" className="title-color">CANCEL</a>
            </div>
            <a onClick={this.update} className="btn btn-solid col-7 text-uppercase">Save Details</a>
          </div>
        </div>
        {/* bottom panel end */}
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Profilesetting;
