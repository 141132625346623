import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
// import API from '../../services';
// import swal from 'sweetalert';
import axios from 'axios';
import Header from './comp/header';
import Category from './comp/category';
import Slider from './comp/slider';
import Deals from './comp/deals';
import Tab from './comp/tab';
import Timebanner from './comp/timebanner';
import Brand from './comp/brand';
import Bottomtab from '../components/bottomtab';
import Meta from '../components/meta';
import API from './../../services';
import OneSignal from 'react-onesignal';

// import ActionType from '../../redux/reducer/globalActionType';

class Home extends Component {

    constructor(props){
      super(props);
      this.state = { data:[], category :[],load:false }
      localStorage.removeItem('coordinate');
      // OneSignal.init({
      //   appId: "d9db449d-db37-47ce-bccc-92eed5fd9f14"
      // });
      // OneSignal.on('subscriptionChange', function(isSubscribed) {
      //   console.log("The user's subscription state is now:", isSubscribed);
      // });
    }

    componentDidMount(){
       this.token_otentikasi();

       const success = position => {
        // const latitude = position.coords.latitude;
        // const longitude = position.coords.longitude;
        // console.log(latitude, longitude);
       };
    
      const error = () => {
        console.log("Unable to retrieve your location");
      };
    
      navigator.geolocation.getCurrentPosition(success, error);
      //  console.log(API.url);
    }

    token_otentikasi = async() => { 
      // let onesignal = await OneSignal.init({ appId: 'd9db449d-db37-47ce-bccc-92eed5fd9f14' });
      // // OneSignal.init({
      // //   appId: "YOUR-APP-ID-HERE"
      // // });
      // OneSignal.on('subscriptionChange', function(isSubscribed) {
      //   console.log("The user's subscription state is now:", isSubscribed);
      // });
       localStorage.removeItem('voucher');
       const stts = await API.otentikasi();
       if (stts === true){ console.log('Login Successfull'); }
       else if(stts === false){ 
         console.log("Login Failed");
        }
    }

  render() {
    return (
      <>
        <Meta/>
        <Header />
        <Category />
        <Slider />
        <Deals />
        <Tab />
        {/* <Timebanner /> */}
        {/* <Brand /> */}
        <Bottomtab active="home"/>
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Home;
