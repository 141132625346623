import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import API from '../../services';
// import swal from 'sweetalert';
import { useParams } from "react-router-dom";
import axios from 'axios';
import Meta from '../components/meta';

class Help extends Component {

    constructor(props){
      super(props);
      this.state = { data:[], load:false }
    }

    componentDidMount(){
      this.token_otentikasi();
    }

    token_otentikasi = async() => { 
      const stts = await API.otentikasi();
      if (stts === false){ this.setState({ loggedin:false }) }
      else if(stts === true) {
        // const decode = await API.decode();
       this.load_article();
      }
   }

   load_article = async() => {
    this.setState({ load:false });
    axios.get(API.url+'slider/get_article/help',{ headers: {'X-Auth-Token': localStorage.getItem('token')}})
      .then((response) => {
          this.setState({ data: response.data.content,load:true });
          // console.log(response.data.content.text);
      })
      .catch((error) => { }); 
   }


  render() {
    if (this.state.loggedin === false){ return <Redirect to='/login'></Redirect>}
    let str;
    if (this.state.data.text !== undefined) {
       str = this.state.data.text.replace('\\"', '"');
    }

    return (
      <>
      <Meta/>
       {/* header start */}
       <header>
          <div className="back-links">
            <a href="/profile/home">
              <i className="iconly-Arrow-Left icli" />
              <div className="content">
                <h2> Question Help</h2>
              </div>
            </a>
          </div>
        </header>
        {/* header end */}

        {/* content start */}
        <section>
        <div dangerouslySetInnerHTML={{__html: str}} />
        </section>
        {/* content end */}
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Help;
