import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
// import swal from 'sweetalert';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import API from '../../../services';
import Rating from '../../components/rating';

class Proreview extends Component {
  constructor(props) {
    super(props);
    this.state = { load: false };
  }

  componentDidMount() {}

  render() {
    let comp;
    // console.log('Isi props : ', this.props.dataprops);
    if (this.props.dataprops !== null) {
      comp = this.props.dataprops.map((dynamicData, index) => (
        <Fragment key={index}>
          <li>
            <div className="media">
              <img src="/assets/images/user/2.png" className="img-fluid" alt="" />
              <div className="media-body">
                <h4>
                  {dynamicData.cust} | {dynamicData.created}
                </h4>
                <Rating data={dynamicData.rating} />
              </div>
            </div>
            <h4 className="content-color">
              {' '}
              {dynamicData.comments}
              {' '}
            </h4>
          </li>

        </Fragment>
      ));
    }

    return (
      <>
        <div className="divider" />
        <div className="product-detail-box px-15">
          <h4 className="page-title">
            Customer Reviews
            {/* <a href="#">All Reviews</a> */}
          </h4>
          <div className="review-section">
            <ul>

              {comp}

              {/* <li>
                <div className="media">
                  <img src="/assets/images/user/2.png" className="img-fluid" alt="" />
                  <div className="media-body">
                    <h4>Mark Jacob | 20 Aug, 2021</h4>
                    <ul className="ratings">
                      <li><i className="iconly-Star icbo" /></li>
                      <li><i className="iconly-Star icbo" /></li>
                      <li><i className="iconly-Star icbo" /></li>
                      <li><i className="iconly-Star icbo" /></li>
                      <li><i className="iconly-Star icbo empty" /></li>
                    </ul>
                  </div>
                </div>
                <h4 className="content-color">It's a really cute skirt! I didn't expect to feel so good in a
                  polyester material. The print is slightly less bright than what is shown in the product
                  description.</h4>
                <div className="review-bottom">
                  <h6>Size bought: <span className="content-color">S</span></h6>
                  <div className="liking-sec">
                    <span className="content-color"><img src="assets/svg/thumbs-up.svg" className="img-fluid" alt="" />20</span>
                    <span className="content-color"><img src="assets/svg/thumbs-down.svg" className="img-fluid" alt="" />2</span>
                  </div>
                </div>
              </li> */}

              {/* <li>
                <div className="media">
                  <img src="assets/images/user/3.png" className="img-fluid" alt="" />
                  <div className="media-body">
                    <h4>Mark Jacob | 20 Aug, 2021</h4>
                    <ul className="ratings">
                      <li><i className="iconly-Star icbo" /></li>
                      <li><i className="iconly-Star icbo" /></li>
                      <li><i className="iconly-Star icbo" /></li>
                      <li><i className="iconly-Star icbo" /></li>
                      <li><i className="iconly-Star icbo empty" /></li>
                    </ul>
                  </div>
                </div>
                <h4 className="content-color">It's a really cute skirt! I didn't expect to feel so good in a
                  polyester material. The print is slightly less bright than what is shown in the product
                  description.</h4>
                <div className="review-bottom">
                  <h6>Size bought: <span className="content-color">S</span></h6>
                  <div className="liking-sec">
                    <span className="content-color"><img src="assets/svg/thumbs-up.svg" className="img-fluid" alt="" />20</span>
                    <span className="content-color"><img src="assets/svg/thumbs-down.svg" className="img-fluid" alt="" />2</span>
                  </div>
                </div>
              </li> */}

            </ul>
          </div>
        </div>
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Proreview;
