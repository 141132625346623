import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import API from '../../../services';
// import swal from 'sweetalert';
import { useParams } from "react-router-dom";
import axios from 'axios';

class Provoucher extends Component {

    constructor(props){
      super(props);
      this.state = { type:0,load:false,droppoint:[] }
      this.drop = [];
    }

    componentDidMount(){
      this.load_droppoint();
    }

    load_droppoint = async() => {
      this.setState({ load:false });
      let nilai = {category: "",publish: "1", recommend:"", bestseller:"", latest:"", economic:"", limit:this.limit, offset:this.offset, orderby:this.state.orderby, order:this.state.order};
      axios.get(API.url+'droppoint')
        .then((response) => {
            this.setState({ droppoint: response.data.content.result });
            // this.drop = response.data.content.result;
            response.data.content.result.map((dynamicData) => {this.drop.push(parseInt(dynamicData.id));})
        })
        .catch((error) => { 
            // error.response.status
            // API.post_error_db(this.constructor.name.toLowerCase(),error.response.data.error,error.response.status);
            // swal(error.response.data.error, "", "error");
            // console.log(error);
        }); 
    }

    handleChange = event => {
        this.setState({type:event.target.value });
        // console.log("Selected : ",event.target.value);
    };

    cekcekbox=e=>{
      // console.log(e.target.value);
      const checked = e.target.checked;
        if (checked) {
          if (this.drop.includes(parseInt(e.target.value)) !== true){ this.drop.push(parseInt(e.target.value)); }
        //checked
        
        }else {
          //unchecked
          // alert('not checked');

          const index = this.drop.indexOf(parseInt(e.target.value));
          if (index > -1) {
            this.drop.splice(index, 1); // 2nd parameter means remove one item only
          }
        }
        this.drop = this.drop.sort();
        // console.log("Isi array : ",this.drop);
    }

  render() {
    return (
      <>
               <div className="divider" />
        <div className="product-detail-box px-15">
          <h4 className="page-title">Offers for You</h4>
          <h5>Use code MULTIKART10 to get flat 10%</h5>
          <h6 className="content-color">Use code MULTIKART10 to get flat 10% off on minimum order of $200.00. Offer valid
            for first time users only</h6>
          <div className="offer-code">
            <div className="code">
              <h6>MULTIKART10</h6>
              <img src="assets/svg/scissor.svg" className="img-fluid" alt="" />
            </div>
            <h6 className="content-color">Tap to copy</h6>
          </div>
        </div>
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Provoucher;
