/* eslint-disable no-useless-constructor */
import React, { Component, Fragment } from 'react';
// import swal from 'sweetalert';
import { Carousel } from 'react-bootstrap';
import API from '../../../services';

class Slider extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <div className="home-slider slick-default theme-dots ratio_asos overflow-hidden">
          <Carousel controls={false} fade={true}>

            {
        this.props.data.map((dynamicData, index) => (
          <Carousel.Item key={index}>
             <div>
              <div className="home-img">
                <img src={dynamicData} className="imgcontent bg-img" alt="" />
              </div>
            </div>
          </Carousel.Item>
        ))
           }

            {/* <div>
              <div className="home-img">
                <img src="https://picsum.photos/250/250" className="img-fluid bg-img" alt="" />
              </div>
            </div> */}

          </Carousel>

          {/* <div>
            <div className="home-img">
              <img src="https://picsum.photos/500/300" className="img-fluid bg-img" alt="" />
            </div>
          </div> */}

        </div>

      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Slider;
