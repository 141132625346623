import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Login from "./container/login";
import Forgot from "./container/forgot";
import Register from "./container/register";
import Verify from "./container/verify";
import Home from './container/home';
import Shop from './container/shop';
import Category from './container/category';
import Product from "./container/product";
import Profile from "./container/profile";
import Profilesetting from "./container/profilesetting";
import Wishlist from "./container/wishlist";
import Cart from "./container/cart";
import Emptycart from "./container/emptycart";
import Voucher from "./container/voucher";
import Caddress from "./container/caddress";
import Feedback from "./container/feedback";
import Newaddress from "./container/newaddress";
import Orderplaced from "./container/orderplaced";
import Orderhistory from "./container/orderhistory";
import Notification from "./container/notification";
import Notifdetail from "./container/notifdetail";
import Checkout from "./container/checkout";
import Tracking from "./container/tracking";
import Notfound from "./container/notfound";
import Voucherdetail from "./container/voucherdetail";
import Tnc from "./container/tnc";
import Help from "./container/help";
import About from "./container/about";
import { Helmet } from "react-helmet";
// import Otp from "./container/otp"
// import Contact from "./container/contact"
// import Contactadd from "./container/contact/contact_add"

// import Sales from "./container/sales"
// import Translist from "./container/translist"
// import Log from "./container/log"
// import Report from "./container/report"
// import Configuration from "./container/configuration"

// eslint-disable-next-line react/prefer-stateless-function
class Routes extends Component {
  render() {
    return (
      // eslint-disable-next-line react/jsx-filename-extension
      <BrowserRouter>
      <Helmet>
        <title> Ngunyah: Situs Pesan Antar Katering dan Restoran Medan | Ngunyah </title>
        <meta 
          name="ngunyah"
          content="Pilihan banyak menu dari restoran dan katering pilihan siap diantar ke tempatmu tanpa tambahan ongkir. Fokus kerja, urusan makan biar Ngunyah aja."
        />
      </Helmet>
        <Switch>
          {/* <Route path="/login" render={props => <Login {...props}/>}> <Login/> </Route> */}
          {/* <Route path="/" render={props => <Home {...props}/>}> <Home/> </Route> */}

          <Route path="/" exact> <Home /> </Route>
          <Route path="/shop" exact> <Shop /> </Route>
          <Route path="/login" exact> <Login /> </Route>
          <Route path="/forgot" exact> <Forgot /> </Route>
          <Route path="/register" exact> <Register /> </Route>
          <Route path="/profilesetting" exact> <Profilesetting /> </Route>
          <Route path="/emptycart" exact> <Emptycart/> </Route>
          
          {/* <Route path="/caddress" exact> <Caddress/> </Route> */}
          <Route path="/feedback" exact> <Feedback/> </Route>
          <Route path="/new-address" exact> <Newaddress/> </Route>
          <Route path="/orderhistory" exact> <Orderhistory/> </Route>
          <Route path="/tnc" exact> <Tnc/> </Route>
          <Route path="/help" exact> <Help/> </Route>
          <Route path="/about" exact> <About/> </Route>

          <Route path="/notification/:page" component={Notification} />
          <Route path="/caddress/:page" component={Caddress} />
          <Route path="/profile/:page" component={Profile} />
          <Route path="/cart/:page" component={Cart} />
          <Route path="/voucher/:page" component={Voucher} />
          <Route path="/product/:sku" component={Product} />
          <Route path="/category/:permalink" component={Category} />
          <Route path="/verify/:custid" component={Verify} />
          <Route path="/orderplaced/:orderid" component={Orderplaced} />
          <Route path="/checkout/:transid" component={Checkout} />
          <Route path="/notifdetail/:id" component={Notifdetail} />
          <Route path="/tracking/:orderid" component={Tracking} />
          <Route path="/wishlist/:page" component={Wishlist} />
          
          <Route path="/voucherdetail/:code/:page" exact component={Voucherdetail} />

          <Route component={Notfound} />

          {/* <Route path="/" component={Home} />
          <Route path="/shop" component={Shop} /> */}

        </Switch>
      </BrowserRouter>
    );
  }
}
export default Routes;
