import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
// import swal from 'sweetalert';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import API from '../../../services';

class Services extends Component {
  constructor(props) {
    super(props);
    this.state = { type: 0, load: false, droppoint: [] };
    this.drop = [];
  }

  componentDidMount() {

  }

  render() {
    return (
      <>
{/* service section start */}
<section className="service-wrapper px-15 pt-0">
          <div className="row">
            <div className="col-4">
              <div className="service-wrap">
                <div className="icon-box">
                  <img src="assets/svg/returning.svg" className="img-fluid" alt="" />
                </div>
                <span>7 Day Return</span>
              </div>
            </div>
            <div className="col-4">
              <div className="service-wrap">
                <div className="icon-box">
                  <img src="assets/svg/24-hours.svg" className="img-fluid" alt="" />
                </div>
                <span>24/7 Support</span>
              </div>
            </div>
            <div className="col-4">
              <div className="service-wrap">
                <div className="icon-box">
                  <img src="assets/svg/wallet.svg" className="img-fluid" alt="" />
                </div>
                <span>Secure Payment</span>
              </div>
            </div>
          </div>
        </section>
        {/* service section end */}
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Services;
