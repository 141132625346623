import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
// import swal from 'sweetalert';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import bell_on from '../../../assets/images/bellon.png';
import bell_off from '../../../assets/images/belloff.png';

class Compimage extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    
  }

  render() {
    // console.log("isi props : ", this.props.dataprops);
    let data = this.props.dataprops;
    let img;
    if (data.reading === '0'){img = <img src={bell_on} className="img-fluid notificon" alt="" />;}
    else{ img = <img src={bell_off} className="img-fluid notificon" alt="" />;}

    return (
      <>
        {img}
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Compimage;
