import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import API from '../../services';
// import swal from 'sweetalert';
import { useParams } from "react-router-dom";
import axios from 'axios';
import Shopheader from './comp/shopheader';
import Search from './comp//search';
import Prolist from './comp/prolist';
import Filter from './comp/filter';
import style from '../../assets/css/customcategory.css';
import Meta from '../components/meta';

// import bootstrap from '../../assets/css/vendors/bootstrap.css';
// import style from '../../assets/css/style.css';


// import ActionType from '../../redux/reducer/globalActionType';

class Category extends Component {

    constructor(props){
      super(props);
      this.state = { data:[], category :"",load:false, datalength:0, loadmore:false, filter:false, orderby:"recommended", order:"desc", outlet:"" }
      this.offset = 0;
      this.limit = 8;
      this.outlet;
      // this.order="desc";
    }

    componentDidMount(){
      // console.log(this.props.match.params.permalink);
      this.setState({ category: this.props.match.params.permalink }, function() {
         this.load_product();
      });
    }


    token_otentikasi = async() => { 
        // const stts = await API.otentikasi();
        // if (stts === true){ this.load_product(); this.props.set_true(); }else if(stts === false){ this.props.set_false(); }
    }

    set_filter = (param,outlet) =>{
      this.offset = 0;
      let orderby = this.state.orderby; let order = this.state.order;
      if (param === 0){ orderby = "recommended"; }
      else if (param === '1'){ orderby = "latest"; }
      else if (param === '2'){ orderby = "best_seller"; }
      else if (param === '3'){ orderby = "price"; }
      else if (param === '4'){ orderby = "price"; order = "asc"; }
      else if (param === '5'){ orderby = "rating"; order = "desc"; }
      
      this.setState({ orderby: orderby, order:order, filter:false, outlet:outlet }, function() {
         this.load_product();
      });
    }

    load_hide_button = () =>{
       if (this.state.data.length < this.state.datalength){
        this.setState({ loadmore: true }); 
      }else if (this.state.data.length === this.state.datalength){ this.setState({ loadmore: false });  }
    }

    load_product = async() => {
      this.setState({ load:false });
      let nilai = {category:this.state.category, limit:this.limit, offset:this.offset, orderby:this.state.orderby, order:this.state.order, droppoint:this.state.outlet};
      // console.log(nilai);
      axios.post(API.url+'product',nilai,{ headers: { 'X-Auth-Token': localStorage.getItem('token') } })
        .then((response) => {
            this.setState({ data: response.data.content.result, datalength:response.data.content.record, load:true });
            // console.log("Isi data content : ",response.data.content.result);
            this.load_hide_button();
        })
        .catch((error) => { 
            // error.response.status
            // API.post_error_db(this.constructor.name.toLowerCase(),error.response.data.error,error.response.status);
            // swal(error.response.data.error, "", "error");
            // console.log(error);
        }); 
    }

  loadMoreData = async () => {
    //On click of Load More button We will call the web API again
    this.setState({ load:false });
    this.offset = this.offset + this.limit;
    let nilai = {category: this.state.category,limit:this.limit, offset:this.offset, orderby:this.state.orderby, order:this.state.order, droppoint:this.state.outlet};
    axios
      axios.post(API.url+'product',nilai,{ headers: { 'X-Auth-Token': localStorage.getItem('token') } })
      .then(response => {
        this.setState({
          data: [...this.state.data, ...response.data.content.result],
          isLoading: false,
        });
        this.load_hide_button();
      })
      .catch(error => {
        console.log('ini error : ', error);
        // Toast.show('Error fetching data..!');
      });
  };

  togglefilter = () => {
    let stts;
    if (this.state.filter === true){ stts = false; }else{ stts = true; }
    this.setState({ filter: stts }); 
  }

    texthandle = event => {

        this.setState({load:false, loadmore:false});
        let param = {filter:event.target.value};
        // console.log(param);
      
        if (event.target.value){
            // console.log(event.target.value);
            axios.post(API.url+'product/search',param)
            .then((response) => { 
                if (response.data.content.result != null){
                    // console.log(response.data.content.result);
                    this.setState({ data: response.data.content.result,load:true }); 
                    // $('#myTable').DataTable(); 
                }
            }).catch((err) => { 
              console.log("Error texthandle : ", err); 
              // API.post_error_db(this.constructor.name.toLowerCase(),err.response.statusText,err.response.status); API.otentikasi_acl('product search'); 
              }); 
        }else{ this.load_product(); }
    }

  render() {   
      // console.log('datalength', this.state.datalength);
      // if (this.offset < this.state.datalength){
      //   // this.loadMoreData();
      //   this.setState({ loadmore: true }); 
      // }else if (this.offset === this.state.datalength){ this.setState({ loadmore: false });  }

    // console.log("data length :",this.state.datalength );
    let compfilter; let prolist;
    if (this.state.data !== null){ prolist = <Prolist data={this.state.data} loadmore={this.state.loadmore} actionlodemore={this.loadMoreData} load_data={this.load_product} /> }
    if (this.state.filter === true){ compfilter = <Filter action={this.set_filter}></Filter>; }
    return (
      <>
        <Meta/>
        <Shopheader title={'Kategori Produk - '+this.state.category} count={this.state.datalength} link={'/'} />
        <Search action={this.texthandle} toogle={this.togglefilter} />
        {compfilter}
        {prolist}
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Category;
