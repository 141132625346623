import React, { Component,Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import axios from 'axios';
import API from '../../../services';
import Subcategory from './subcategory';
import submenuicon from '../../../assets/images/menu.png';
// import swal from 'sweetalert';

// eslint-disable-next-line react/prefer-stateless-function
export class Category extends Component {
  constructor(props) {
    super(props);
    // eslint-disable-next-line react/no-unused-state
    this.state = { data: [], load: false, submenu:0, submenuvisible:false, submenuresult:[] };
  }

  componentDidMount() {
    // this.token_otentikasi();
    //  console.log(API.url);
    this.load_category();
  }

   load_category = () => {
        this.setState({ load:false });
        axios.get(API.url+'category')
        .then((response) => { 
            this.setState({ data: response.data.content.result, submenuresult:response.data.content.submenuresult, submenu:response.data.content.submenu, load:true });
            // console.log('Category : ',response.data.content.submenuresult);
        })
        .catch((err) => { console.log("Error load category : ", err.response.statusText+' - '+err.response.status);
          //  API.otentikasi_acl('product'); API.post_error_db(this.constructor.name.toLowerCase(),err.response.statusText,err.response.status);
         });
    }

    change_visible = () =>{
      if (this.state.submenuvisible === true){this.setState({ submenuvisible: false});}
      else{ this.setState({ submenuvisible: true}); }
    }

  render() {
    let comp; let submenu; let visiblemenu;

    if (this.state.submenuvisible === true){
      visiblemenu = <Subcategory data={this.state.submenuresult}/>;
    }

      if (this.state.submenu > 0){
       submenu =  <li style={{margin: '5px', padding: '5px'}}>
          <a onClick={() => this.change_visible()} className="category-box">
            <img src={submenuicon} className="img-fluid" alt="All Categories" />
            <h4> All </h4>
            </a>
          </li>;
      }

    if (this.state.data !== null){
      comp =  this.state.data.map((dynamicData,index) => {
                return (
                <Fragment key={index}>
                  <li>
                  {/* <a href="inner-category.html" className="category-box"> */}
                  <Link className="category-box" to={"category/"+dynamicData.permalink}> 
                    <img src={dynamicData.image} className="img-fluid" alt={dynamicData.permalink} />
                    <h4>{dynamicData.name}</h4>
                  </Link>
                  {/* </a> */}
                  </li>
                </Fragment>                
                );
            });
      
    }
    // console.log("Data State : ",this.state.data);
    return (
      <div>
        <section className="category-section top-space">
          <ul className="category-slide">
          {submenu}
{comp}
           
            {/* <li>
              <a href="inner-category.html" className="category-box">
                <img src="assets/images/top-category/men.png" className="img-fluid" alt="" />
                <h4>men</h4>
              </a>
            </li> */}
          </ul>
        </section>
       
        {visiblemenu}
        <div className="divider t-12 b-20" />
      </div>
    );
  }
}

export default Category;
