import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import API from '../../services';
import db from '../../services/db';
// import swal from 'sweetalert';
import { useParams } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Searching from './comp/searching';
import qs from 'qs';
import Meta from '../components/meta';
import Prooutlet from './comp/prooutlet';

class Feedback extends Component {

    constructor(props){
      super(props);
      this.state = { data:[], load:false, back:false, loggedin:true, address:null }
    }

    componentDidMount(){
      this.token_otentikasi();
    }

    token_otentikasi = async() => { 
      const stts = await API.otentikasi();
      if (stts === false){ this.setState({ loggedin:false }) }
      else if(stts === true) {
        // const decode = await API.decode();
      //  this.load_address();
      }
   }

   setoutlet = event => {
      this.setState({address:event});
      // alert(event);
   }

   add = async() =>{
        
    let error = null;
    let subject = document.getElementById("csubject").value;
    let outlet = document.getElementById("coutlet").value;
    let email = document.getElementById("tmail").value;
    let massage = document.getElementById("tmessage").value;

    if (subject === ""){ error = "Silahkan pilih subject anda"; }
    if (email === ""){ error = "Silahkan isi email anda"; }
    if (massage === ""){ error = "Silahkan isi pesan anda"; }

    if (error === null){
      let requestBody = {subject: subject,outlet: outlet, email:email, message: massage}
      this.setState({ load:false })
      axios.post(API.url+'customer/feedback',requestBody,{ headers: {'X-Auth-Token': localStorage.getItem('token')}})
        .then((response) => {
            // console.log(response.data.content); 
            toast.success("Pesan anda berhasil dikirim",
                {position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 2000,
                hideProgressBar: false});
                this.setState({back:true});
        })
        .catch((error) => { 
          // console.log("Error : ", error.response.data.error);
          toast.error(error.response.data.error,
                {position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 2000,
                hideProgressBar: false});
        }); 
          // axios
    }else{
      toast.error(error,
                  {position: toast.POSITION.BOTTOM_CENTER,
                  autoClose: 2000,
                  hideProgressBar: false});
    }

  }

  render() {
    if (this.state.loggedin === false){ return <Redirect to='/login'></Redirect>}
    if (this.state.back === true){ return <Redirect to='/profile/home'></Redirect>}
    
    return (
      <>
      <Meta/>
       {/* header start */}
       <header>
        <div className="back-links">
          <a href="/profile/home">
            <i className="iconly-Arrow-Left icli" />
            <div className="content">
              <h2>Kritik,Saran & Feedback</h2>
            </div>
          </a>
        </div>
      </header>
      {/* header end */}
      
       <div className="divider"></div>
       {/* address details start */}
       <section className="top-space pt-2">
          <div className="address-form-section px-15">

            <div className="form-floating mb-4">
            <select id="csubject" className="form-control">
              <option value=""> Pilih Subjek </option>
              <option value="complaint"> Complaint </option>
              <option value="question"> Question </option>
              <option value="delivery"> Delivery </option>
              <option value="partnership"> Partnership </option>
            </select>
              <label htmlFor="one">Subjek</label>
            </div>

            <div className="form-floating mb-4">
              <Prooutlet setoutlet={this.setoutlet}/>
              <h4 style={{padding: '10px'}}> {this.state.address} </h4>
            </div>
            

            <div className="form-floating mb-4">
              <input type="text" className="form-control" id="tmail" placeholder="Email" />
              <label htmlFor="one">Email</label>
            </div>

            <div className="form-floating mb-4">
              <textarea className="form-control" id="tmessage" style={{height: '100px'}} defaultValue={""} />
              <label htmlFor="one">Message</label>
            </div>

          </div> 

        </section>


        {/* address details end */}
        {/* panel space start */}
        <section className="panel-space" />
        {/* panel space end */}
        {/* bottom panel start */}
        <div className="cart-bottom row m-0">
          <div>
            <div className="left-content col-5">
              <a href="/profile" className="title-color">CANCEL</a>
            </div>
            <a onClick={this.add} className="btn btn-solid col-7 text-uppercase">Kirim</a>
          </div>
        </div>
        {/* bottom panel end */}


      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Feedback;
