import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import API from '../../services';
// import swal from 'sweetalert';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import qs from 'qs';
// import City from './comp/city'
import Select from 'react-select';
import Meta from '../components/meta';

// import ActionType from '../../redux/reducer/globalActionType';

class Register extends Component {

    constructor(props){
      super(props);
      this.state = { datakab:[], datadistrict:[], load:false, kabid:0, district:0, verify:false,custid:0 }
      localStorage.removeItem('token');
    }

    componentDidMount(){
       this.load_kabupaten();
      //  console.log(API.url);
    }

    load_kabupaten = async() => {
      this.setState({ load:false });
      axios.get(API.url+'city')
        .then((response) => {
            this.setState({ datakab: response.data.content.result, datadistrict:[], load:true });
        })
        .catch((error) => { 
            // console.log(error);
        }); 
    }

    handleChange = (selectedOption) => {
      // console.log(`Option selected:`, selectedOption.value)
       this.setState({ load:false, datadistrict:[] });
       axios.get(API.url+'city/get_district/'+selectedOption.value)
        .then((response) => {
            this.setState({ datadistrict: response.data.content.result, kabid:selectedOption.value, load:true });
        })
        .catch((error) => { 
            // console.log(error);
        }); 
    };

     handleDistrict = (selectedOption) => {
      // console.log(`Option selected:`, selectedOption.value)
       this.setState({ district:selectedOption.value });
    };

    handleInput=(e)=>{
      if (e.key === 'Enter'){ this.login(); }
    }

    login = async() =>{
        
      let name = document.getElementById("name").value;
      let email = document.getElementById("email").value;
      let phone = document.getElementById("phone").value;
      let city = this.state.kabid;
      let district = this.state.district;
      let address = document.getElementById("address").value;
      let zip = document.getElementById("zip").value;
      let password = document.getElementById("password").value;

      let requestBody = {tname: name,taddress: address, tzip:zip, tphone1: phone, temail: email, ccity:city, cdistrict:district, tpassword:password}
      // console.log(requestBody);
      

      this.setState({ load:false })
      axios.post(API.url+'customer/add',qs.stringify(requestBody))
        .then((response) => {
            // console.log(response.data.content); 
            toast.success("Registrasi Berhasil",
                {position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 2000,
                hideProgressBar: false});

            setTimeout(
              () => this.setState({ verify: true, custid:response.data.content.id }), 
              4000
            );
            
            // localStorage.setItem('token', response.data.content.token);
            // this.setState({ loggedin:true })
            // alert('login berhasil');
        })
        .catch((error) => { 
          // console.log("Error : ", error.response.data.error);
          toast.error(error.response.data.error,
                {position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 2000,
                hideProgressBar: false});
        }); 
          // axios

    }

    vivible_pass = () => {
        var x = document.getElementById("password");
        if (x.type === "password") {x.type = "text";} else {x.type = "password";}
    }

  render() {

     if (this.state.verify === true){ return <Redirect to={`/verify/${this.state.custid}`}></Redirect>}

    return (
      <>
      <Meta/>
{/* top design start */}
        <img src="assets/images/design.svg" className="img-fluid design-top" alt="" />
        {/* top design end */}
        {/* top bar start */}
        <div className="topbar-section">
          <a href="index.html"><img src="assets/images/logo.png" className="img-fluid main-logo" alt="" />
            <img src="assets/images/logo-white.png" className="img-fluid white-logo" alt="" /></a>
          <a className="skip-cls" href="/">SKIP</a>
        </div>
        {/* top bar end */}
        {/* login section start */}
        <section className="form-section px-15 top-space section-b-space">
          <h1>Hey, <br />Sign Up</h1>
          <form>
            <div className="form-floating mb-4">
              <input type="text" className="form-control" id="name" placeholder="Name" />
              <label htmlFor="name">Name</label>
            </div>
            <div className="form-floating mb-4">
              <input type="email" className="form-control" id="email" placeholder="Email" />
              <label htmlFor="email">Email</label>
            </div>
            <div className="form-floating mb-4">
              <input type="number" className="form-control" id="phone" placeholder="Phone" />
              <label htmlFor="phone">Phone</label>
            </div>
            <div className="form-floating mb-4">
              <Select
                id='ckab'
                placeholder="..."
                onChange={this.handleChange}
                options={this.state.datakab}
              />
              <label htmlFor="ckab">Kota/Kabupaten</label>
            </div>
             <div className="form-floating mb-4">
              <Select
                id='cdistrict'
                placeholder="..."
                onChange={this.handleDistrict}
                options={this.state.datadistrict}
              />
              <label htmlFor="cdistrict">Kecamatan/Wilayah</label>
            </div>
            <div className="form-floating mb-4">
              <textarea className="form-control" style={{height: '100px'}} id="address" placeholder="Address"></textarea>
              <label htmlFor="address">Address</label>
            </div>
             <div className="form-floating mb-4">
              <input type="number" className="form-control" id="zip" placeholder="Zip Code" />
              <label htmlFor="zip">Zip Code</label>
            </div>
            <div className="form-floating mb-4">
              <input type="password" id="password" className="form-control" placeholder="password" />
              <label>Password</label>
              <div id="btnToggle" className="password-hs" onClick={this.vivible_pass}>
                <i id="eyeIcon" className="iconly-Hide icli hide" />
              </div>
            </div>
            <a onClick={this.login} className="btn btn-solid w-100">Sign UP</a>
          </form>
          {/* <div className="or-divider">
            <span>Or sign in with</span>
          </div>
          <div className="social-auth">
            <ul>
              <li>
                <a href="#"><img src="assets/images/social/google.png" className="img-fluid" alt="" /></a>
              </li>
              <li>
                <a href="#"><img src="assets/images/social/fb.png" className="img-fluid" alt="" /></a>
              </li>
              <li className="apple-icon">
                <a href="#"><img src="assets/images/social/apple.png" className="img-fluid" alt="" /></a>
              </li>
            </ul>
          </div> */}
          <div className="bottom-detail text-center mt-3">
            <h4 className="content-color">Already have an Account? <a className="title-color text-decoration-underline" href="/login">Sign In</a></h4>
          </div>
        </section>
        {/* login section end */}
        {/* panel space start */}
        <section className="panel-space" />
        {/* panel space end */}
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Register;
