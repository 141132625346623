import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
// import swal from 'sweetalert';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import API from '../../../services';

class Shipping extends Component {
  constructor(props) {
    super(props);
    this.state = { load: false, lat:0, lng:0, address:null, integration:'0' };
    const add='kosong';
    let coor=null;
  }

  // componentDidMount() {
  //   if ("geolocation" in navigator) {
  //      this.set_adress();
  //   }else{
  //     console.log("corrdinate not found");
  //   }
  // }

  componentDidMount() {
    // Get location of user
    const success = position => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      // console.log(latitude, longitude);
      this.setState({
        lat: latitude,
        lng: longitude
      });
      this.setState({lat:latitude, long: longitude}, () => {this.get_address()});
    };
  
    const error = () => {
      console.log("Unable to retrieve your location");
    };
  
    navigator.geolocation.getCurrentPosition(success, error);
  }

  get_address = async() => { 
    const property = await API.property();
    if (property.shipping_integration === '1'){
      this.setState({ integration: '1' }, function() {
        this.get_address_default();
      });   
      
    }else{
      this.get_address_coor();
    }
    // console.log('hasil property : ',property.shipping_integration);
  }

  get_address_coor = async() => { 
    
    // let coor;
    if (localStorage.getItem('coordinate') !== null){
      this.coor = localStorage.getItem('coordinate');
    }else{ this.coor = this.state.lat+","+this.state.lng; }

    axios.get("https://maps.googleapis.com/maps/api/geocode/json?latlng="+this.coor+"&key=AIzaSyBqKPUcKxKOgOfSby8WB1lTsahkRjt0Qek")
    .then((response) => { 
        // console.log(response.data.results[0].formatted_address);      
        this.setState({ address: response.data.results[0].formatted_address});
        this.props.setadd(this.state.address,this.coor);
    })
    .catch((err) => { 
      console.log("Error map : ", err);
      //  API.otentikasi_acl('product'); API.post_error_db(this.constructor.name.toLowerCase(),err.response.statusText,err.response.status);
     }); 
  }

  get_address_default = async() => { 

    if (localStorage.getItem('coordinate') !== null){
      this.get_address_coor();
    }else{ 
         axios.get(API.url+'customer/get_address/1',{ headers: {'X-Auth-Token': localStorage.getItem('token')}})
          .then((response) => { 
              let result = response.data.content.result[0];
              // console.log('Result Address : ',result);  
              localStorage.setItem('addressid', result.id);
              this.coor = result.coordinate;
              this.setState({ address: result.address }, function() {
                // console.log(this.coor);
                this.props.setadd(this.state.address,this.coor);
              });   
              // this.setState({ address: response.data.results[0].formatted_address});
              // this.props.setadd(this.state.address,this.coor);
          })
          .catch((err) => { 
            console.log("Error map : ", err);
            //  API.otentikasi_acl('product'); API.post_error_db(this.constructor.name.toLowerCase(),err.response.statusText,err.response.status);
          }); 
    }
    
  }

  cekpickup = (e) => {
    const checked = e.target.checked;
    if (checked) {this.props.setpickup(1);} else {this.props.setpickup(0);}
  }

  render() {
    // console.log("State address : ",this.state.address);
    // console.log("State coordinate : ",this.coor);
    let pickup;
    if (this.state.integration === '0'){
      pickup = <> <div className="checkbox_animated">
                <div className="d-flex align-items-center mb-2">
                  <input type="checkbox" name="cpickup" id="ten" value="1" onClick={(e) => {this.cekpickup(e);}} />
                 <label htmlFor="ten"> Ambil Sendiri </label>
                 </div>
               </div>  <div className="divider" /> </>;
    }
    return (
      <>
        {/* coupon section */}
        <section className="px-15 pt-0">
          <h2 className="title">Shipping:</h2>
          <div className="coupon-section">
            {/* <i className="iconly-Discount icli icon-discount" /> */}
            <h4> {this.state.address} </h4> <br/>
            {/* <textarea className="form-control" placeholder="Address" style={{height: '90px'}} value={this.state.address}></textarea> */}
            {/* <input className="form-control form-theme" placeholder="Apply Coupons" /> */}
            <a href="/caddress"> <i className="iconly-Arrow-Right-2 icli icon-right" /> </a>
          </div>
          <h4> <a className="theme-color" href="/caddress/cart"> Gunakan Alamat Lain </a> </h4> <br/>

          {pickup}
          {/* <div className="checkbox_animated">
          <div className="d-flex align-items-center mb-2">
            <input type="checkbox" name="cpickup" id="ten" value="1" onClick={(e) => {this.cekpickup(e);}} />
            <label htmlFor="ten"> Ambil Sendiri </label>
          </div>
          </div> */}

        </section>
        {/* <div className="divider" /> */}
        {/* coupon end */}
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Shipping;
